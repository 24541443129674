import React, {useEffect, useState} from "react";
import {Col, DatePicker, Form, Input, InputNumber, Row} from "antd";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import {StaffLoanRow} from "@services/dto/staffLoan";
import {SelectProject} from "@components/SelectProject";
import {SelectPersonCharge} from "@components/SelectPersonCharge";
import {SelectCooperativeUnit} from "@components/SelectCooperativeUnit";
import dayjs from "dayjs";
import {allStores} from "@/stores";
import {ProjectRow} from "@services/dto/project";
import {SelectBankAccount} from "@components/SelectBankAccount";
import {Any} from "@common/types";
import {BankAccountRow} from "@services/dto/bankAccount";
import {SelectFiling} from "@components/SelectFiling";
import {SelectFundApproval} from "@components/SelectFundApproval";
import {FundApprovalRow} from "@services/dto/fundApproval";
import {BLACK_LIST, FORM_DISABLED_STATE} from "@/config";
import {SelectProcessStatus} from "@components/SelectProcessStatus";
import {SelectCostApplication} from "@components/SelectCostApplication";

export interface PropsType {
  row: StaffLoanRow
  isView?: boolean
  isBatchUpdate?: boolean
}

export const BasicInfo = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, ref) => {
  const [form] = Form.useForm()
  const {filingStore} = allStores
  const [responsibilityProject, setResponsibilityProject] = useState<string>()
  const [cooperationUnit, setCooperationUnit] = useState<string | string[]>()

  useEffect(() => {
    form.resetFields()
    const staffLoanRow = {...props.row}
    filingStore.setFilingFaCode(staffLoanRow.elReqCode)
    if (typeof staffLoanRow.elDate === 'string' && staffLoanRow.elDate !== '') {
      staffLoanRow.elDate = dayjs(staffLoanRow.elDate);
    }
    if (typeof staffLoanRow.elPlannedReturnDate === 'string' && staffLoanRow.elPlannedReturnDate !== '') {
      staffLoanRow.elPlannedReturnDate = dayjs(staffLoanRow.elPlannedReturnDate);
    }
    if (typeof staffLoanRow.elActualReturnDate === 'string' && staffLoanRow.elActualReturnDate !== '') {
      staffLoanRow.elActualReturnDate = dayjs(staffLoanRow.elActualReturnDate);
    }
    if (staffLoanRow.fundingApprovals) {
      staffLoanRow.fundingApprovalIds = (staffLoanRow.fundingApprovals as FundApprovalRow[])?.map(o => o.id)
    }
    if (typeof staffLoanRow.cooperationUnit === 'string') {
      staffLoanRow.cooperationUnit = staffLoanRow.cooperationUnit !== '' ? staffLoanRow.cooperationUnit.split(',') : []
    }
    setResponsibilityProject(staffLoanRow.responsibilityProject)
    setCooperationUnit(staffLoanRow.cooperationUnit)
    form.setFieldsValue(staffLoanRow);
  }, [props.row.id])

  /**
   * @desc 责任部门修改 code拿到并赋值
   * @param value
   * @param options
   */
  const handleProjectChange = (value: any, options: ProjectRow[]) => {
    setResponsibilityProject(value)
    const row = options.find(o => value === o.name)
    form.setFieldsValue({code: row?.code})
  }

  /**
   * @desc 账户组件修改默认填充简称字段
   * @param value
   * @param options
   */
  const handleElPaymentNumberChange = (value: Any | null, options: BankAccountRow[]) => {
    const row = options.find(o => value === o.id)
    form.setFieldsValue({baNick1: row?.baNick || ""})
    form.setFieldsValue({baName1: row?.baName || ""})
    form.setFieldsValue({baAccount1: row?.baAccount || ""})
    form.setFieldsValue({baBlank1: row?.baBlank || ""})
  }

  const handleElReceiptNumberChange = (value: Any | null, options: BankAccountRow[]) => {
    const row = options.find(o => value === o.id)
    form.setFieldsValue({baNick2: row?.baNick || ""})
    form.setFieldsValue({baName2: row?.baName || ""})
    form.setFieldsValue({baAccount2: row?.baAccount || ""})
    form.setFieldsValue({baBlank2: row?.baBlank || ""})
  }

  return (
    <Form
      name="basic"
      ref={ref}
      form={form}
      labelCol={{span: 6}}
      wrapperCol={{span: 16}}
      disabled={props.isView || FORM_DISABLED_STATE.includes(props.row.elStatus)}
      initialValues={{
        cooperationUnit: ['N/A'],
      }}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Form.Item hidden name="id">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="code">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="personResponsibleName">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="baNick1">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="baNick2">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="employeeFeeApplicationCode">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="organizersUserName">
          <Input/>
        </Form.Item>
        <Col span={24}>
          <Form.Item
            label="制单日期"
            name="elDate"
            rules={[{required: false, message: '请输入制单日期!'}]}>
            <DatePicker
              disabled
              allowClear
              placeholder={"请输入制单日期"}
              className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="制单编号"
            name="elReqCode"
            rules={[{required: false, message: '请输入制单编号!'}]}>
            <Input placeholder="请输入制单编号" disabled/>
          </Form.Item>
        </Col>
        { /*<Col span={24}>
          <Form.Item
            label="借款类别"
            name="elType"
            rules={[{required: false, message: '请输入借款类别!'}]}>
            <SelectDict
              allowClear
              className={'width-100-percentage'}
              code={'el_type'}
              placeholder={'请输入借款类别'}/>
          </Form.Item>
        </Col>*/ }
        <Col span={24}>
          <Form.Item
            label="借款说明"
            name="elDesc"
            rules={[{required: false, message: '请输入借款说明!'}]}>
            <Input.TextArea placeholder="请输入借款说明" autoSize={{minRows: 3, maxRows: 9}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="申请金额"
            name="elAmount"
            rules={[{required: false, message: '请输入申请金额!'}]}>
            <InputNumber addonAfter={'元'} min={0} precision={2} placeholder="请输入申请金额" className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="付款账户"
            name="elReceiptNumber"
            rules={[{required: false, message: '请输入付款账户!'}]}>
            <SelectBankAccount
              onChange={handleElReceiptNumberChange}
              allowClear
              className={'width-100-percentage'}
              isShowAction
              bankAccountTypeOptions={[{id: '员工公务账户', value: '员工公务账户'}, {id: '员工工资账户', value: '员工工资账户'}]}
              placeholder={'请输入付款账户'}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="付款账户名称"
            name="baName2"
            rules={[{required: false, message: '请输入付款账户名称!'}]}>
            <Input placeholder="请输入付款账户名称" disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="付款账户账号"
            name="baAccount2"
            rules={[{required: false, message: '请输入付款账户账号!'}]}>
            <Input placeholder="请输入付款账户账号" disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="付款账户银行"
            name="baBlank2"
            rules={[{required: false, message: '请输入付款账户银行!'}]}>
            <Input placeholder="请输入付款账户银行" disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="收款账户"
            name="elPaymentNumber"
            rules={[{required: false, message: '请输入收款账户!'}]}>
            <SelectBankAccount
              onChange={handleElPaymentNumberChange}
              allowClear
              className={'width-100-percentage'}
              isShowAction
              bankAccountTypeOptions={[{id: '员工公务账户', value: '员工公务账户'}, {id: '员工工资账户', value: '员工工资账户'}]}
              placeholder={'请输入收款账户'}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="收款账户名称"
            name="baName1"
            rules={[{required: false, message: '请输入收款账户名称!'}]}>
            <Input placeholder="请输入收款账户名称" disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="收款账户账号"
            name="baAccount1"
            rules={[{required: false, message: '请输入收款账户账号!'}]}>
            <Input placeholder="请输入收款账户账号" disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="收款账户银行"
            name="baBlank1"
            rules={[{required: false, message: '请输入收款账户银行!'}]}>
            <Input placeholder="请输入收款账户银行" disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="关联费用申请"
            name="employeeFeeApplicationId"
            rules={[{required: false, message: '请选择关联费用申请!'}]}>
            <SelectCostApplication
              onChange={(v, options) => form.setFieldsValue({employeeFeeApplicationCode: options.find(o => o.id === v && !BLACK_LIST.includes(v))?.efaApplyCode})}
              placeholder={'请选择关联费用申请'}
              className={'width-100-percentage'}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="关联资金审批"
            name="fundingApprovalIds"
            rules={[{required: false, message: '请输入资金审批!'}]}>
            <SelectFundApproval
              disabled
              mode={'multiple'}
              className={'width-100-percentage'}
              placeholder={"请选择资金审批"}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="借款日期"
            name="elPlannedReturnDate"
            rules={[{required: false, message: '请选择借款日期!'}]}>
            <DatePicker disabled placeholder={"请选择借款日期"} className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="借款金额"
            name="borrowAmount"
            rules={[{required: false, message: '请输入借款金额!'}]}>
            <InputNumber disabled min={0} precision={2} placeholder="请输入借款金额"
              className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="还款日期"
            name="elActualReturnDate"
            rules={[{required: false, message: '请选择还款日期!'}]}>
            <DatePicker disabled placeholder={"请选择还款日期"} className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="还款金额"
            name="repaymentAmount"
            rules={[{required: false, message: '请输入还款金额!'}]}>
            <InputNumber disabled min={0} precision={2} placeholder="请输入还款金额"
              className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="余额"
            name="balance"
            rules={[{required: false, message: '请输入余额!'}]}>
            <InputNumber disabled min={0} precision={2} placeholder="请输入余额" className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="责任部门"
            name="responsibilityProject"
            rules={[{required: !props.isBatchUpdate, message: '请选择责任部门!'}]}>
            <SelectProject
              placeholder={"请选择责任部门"}
              onChange={handleProjectChange}
              allowClear
              fieldNames={{value: 'name', label: 'name'}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="协作单位"
            name="cooperationUnit"
            rules={[{required: false, message: '请输入协作单位!'}]}>
            <SelectCooperativeUnit
              allowClear
              mode={'multiple'}
              disabled
              fieldNames={{value: 'cuName', label: 'cuName'}}/>
          </Form.Item>
        </Col>
        {
          props.row.id && <Col span={24}>
            <Form.Item
              label="存档批号"
              name="elFile"
              rules={[{required: false, message: '请选择存档批号!'}]}>
              <SelectFiling
                responsibilityProject={responsibilityProject}
                isHideClose
                isHidePlus
                cooperationUnit={cooperationUnit}
                filingMode={"multiple"}
                inputDisabled
                placeholder={"请选择存档批号"}
                allowClear/>
            </Form.Item>
          </Col>
        }
        <Col span={24}>
          <Form.Item
            label="办理人员"
            name="personResponsible"
            rules={[{required: false, message: '请选择办理人员!'}]}>
            <SelectPersonCharge
              disabled
              className={"width-100-percentage"}
              placeholder={"请选择办理人员"}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="主办人员"
            name="organizersUserId"
            rules={[{required: false, message: '请选择主办人员!'}]}>
            <SelectPersonCharge
              className={"width-100-percentage"}
              placeholder={"请选择主办人员"}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="办理进度"
            name="elStatus"
            rules={[{required: false, message: '请选择办理进度!'}]}>
            <SelectProcessStatus
              allowClear
              placeholder={"请选择办理进度"}
              disabled/>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}))

export default BasicInfo
