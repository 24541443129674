import React, {useEffect, useState} from "react";
import {Col, DatePicker, Form, Input, InputNumber, message, Row, Select} from "antd";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import dayjs from "dayjs";
import {SelectDict} from "@components/SelectDict";
import {SelectPersonCharge} from "@components/SelectPersonCharge";
import {SelectProject} from "@components/SelectProject";
import {SelectCooperativeUnit} from "@components/SelectCooperativeUnit";
import {ProjectRow} from "@services/dto/project";
import {SelectFiling} from "@components/SelectFiling";
import {WorkScheduleRow} from "@services/dto/workSchedule";
import {SelectProcessStatus} from "@components/SelectProcessStatus";
import {BLACK_LIST, DATE_FMT, FORM_DISABLED_STATE} from "@/config";
import {SelectTaskSupervision} from "@components/SelectTaskSupervision";
import {TaskSupervisionRow} from "@services/dto/taskSupervision";
import {logger} from "@common/utils";

export interface PropsType {
  row: WorkScheduleRow
  isView?: boolean
}

export const BasicInfo = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, ref) => {
  const [form] = Form.useForm()
  const [SelectCooperativeUnitMode] = useState<'multiple' | 'tags'>()
  const [responsibilityProject, setResponsibilityProject] = useState<string>()
  const [cooperationUnit, setCooperationUnit] = useState<string | string[]>()
  const [firstWsDay, setFirstWsDay] = useState<string>()

  useEffect(() => {
    form.resetFields()
    const taskSupervisionRow = {...props.row}
    if (taskSupervisionRow.id) {
      if (typeof taskSupervisionRow.wsDate === 'string' && taskSupervisionRow.wsDate !== '') {
        taskSupervisionRow.wsDate = dayjs(taskSupervisionRow.wsDate)
      }
      if (typeof taskSupervisionRow.wsWorkPlanCompletionDate === 'string' && taskSupervisionRow.wsWorkPlanCompletionDate !== '' && typeof taskSupervisionRow.wsWorkPlanCompletionDate === 'string' && taskSupervisionRow.wsWorkPlanCompletionDate !== '') {
        taskSupervisionRow.wsWorkPlanStartDate = dayjs(taskSupervisionRow.wsWorkPlanStartDate)
        taskSupervisionRow.wsWorkPlanCompletionDate = dayjs(taskSupervisionRow.wsWorkPlanCompletionDate)
        taskSupervisionRow.wsWorkPlanDuration = String(taskSupervisionRow.wsWorkPlanCompletionDate.diff(taskSupervisionRow.wsWorkPlanStartDate, 'day') + 1)
      } else if (typeof taskSupervisionRow.wsWorkPlanCompletionDate === 'string' && taskSupervisionRow.wsWorkPlanCompletionDate !== '') {
        taskSupervisionRow.wsWorkPlanCompletionDate = dayjs(taskSupervisionRow.wsWorkPlanCompletionDate)
      } else if (typeof taskSupervisionRow.wsWorkPlanCompletionDate === 'string' && taskSupervisionRow.wsWorkPlanCompletionDate !== '') {
        taskSupervisionRow.wsWorkPlanCompletionDate = dayjs(taskSupervisionRow.wsWorkPlanCompletionDate)
      }
      setResponsibilityProject(taskSupervisionRow.responsibilityProject)
      setCooperationUnit(taskSupervisionRow.cooperationUnit)
    }
    form.setFieldsValue(taskSupervisionRow);
    calculateAndSetCompletionDate()
    calculateAndSetFirstWsDay()
  }, [props.row])

  /**
   * @desc 责任部门修改 code拿到并赋值
   * @param value
   * @param options
   */
  const handleProjectChange = (value: any, options: ProjectRow[]) => {
    setResponsibilityProject(value)
    const row = options.find(o => value === o.name)
    form.setFieldsValue({code: row?.code})
  }

  /**
   * 计算并设置完成日期
   */
  const calculateAndSetCompletionDate = () => {
    const wsWorkPlanStartDate = form.getFieldValue("wsWorkPlanStartDate");
    const wsWorkPlanDuration = form.getFieldValue("wsWorkPlanDuration");
    if (typeof wsWorkPlanStartDate === 'object' && wsWorkPlanStartDate && wsWorkPlanDuration) {
      const completionDate = wsWorkPlanStartDate.add(wsWorkPlanDuration - 1, 'day');
      form.setFieldsValue({
        wsWorkPlanCompletionDate: completionDate,
      });
    }
  };

  /**
   * 计算并设置首次通知日期
   */
  const calculateAndSetFirstWsDay = () => {
    const wsWorkPlanStartDate = form.getFieldValue("wsWorkPlanStartDate");
    const wsDay = form.getFieldValue("wsDay");
    if (typeof wsWorkPlanStartDate === 'object' && wsWorkPlanStartDate && (wsDay || Number(wsDay) === 0)) {
      if (props.row.wsWorkPlanStartDate || wsWorkPlanStartDate.subtract(wsDay - 1, 'day') >= dayjs()) {
        setFirstWsDay(wsWorkPlanStartDate.subtract(wsDay, 'day').format(DATE_FMT));
      } else {
        message.warning("通知日期不得早于当前日期");
        setFirstWsDay("")
      }
    }
  };

  /**
   * 关联任务改变
   */
  const handleWsSecondaryTasksIdChange = (value: string, options: TaskSupervisionRow[]) => {
    logger.log(value, options)
    const row = options.find(o => value === o.id && !BLACK_LIST.includes(value))
    if (row) {
      logger.log('选中的数据', row)
      form.setFieldsValue({
        responsibilityProject: row.responsibilityProject,
        cooperationUnit: row.cooperationUnit,
        wsFile: row.tsFile,
        wsFileCode: row.tsFileCode,
      })
    } else {
      form.setFieldsValue({
        responsibilityProject: undefined,
        cooperationUnit: undefined,
        wsFile: undefined,
        wsFileCode: undefined,
      })
    }
  }

  return (
    <Form
      name="basic"
      ref={ref}
      form={form}
      labelCol={{span: 6}}
      wrapperCol={{span: 16}}
      disabled={props.isView || FORM_DISABLED_STATE.includes(props.row.status)}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Form.Item hidden name="id">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="code">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="handlingRecordsName">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="personResponsibleName">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="organizersUserName">
          <Input/>
        </Form.Item>
        <Col span={24}>
          <Form.Item
            label="制单日期"
            name="wsDate"
            rules={[{required: false, message: '请输入制单日期!'}]}>
            <DatePicker
              disabled
              placeholder={"请选择制单日期"}
              className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="制单编号"
            name="wsCode"
            rules={[{required: false, message: '请输入制单编号!'}]}>
            <Input placeholder="请输入制单编号" disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="工作摘要"
            name="wsSummary"
            rules={[{required: true, message: '请输入工作摘要!'}]}>
            <Input.TextArea
              placeholder="请输入工作摘要"
              autoSize={{minRows: 3, maxRows: 9}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="计划工期"
            name="wsWorkPlanDuration"
            rules={[{required: true, message: '请输入计划工期!'}]}>
            <InputNumber
              onChange={() => calculateAndSetCompletionDate()}
              precision={0}
              placeholder="请输入计划工期"
              className={'width-100-percentage'}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="开始日期"
            name="wsWorkPlanStartDate"
            rules={[{required: true, message: '请选择开始日期!'}]}>
            <DatePicker
              placeholder={"请选择开始日期"}
              onChange={() => {
                calculateAndSetCompletionDate()
                calculateAndSetFirstWsDay()
              }}
              className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="完成日期"
            name="wsWorkPlanCompletionDate"
            rules={[{required: false, message: '请选择完成日期!'}]}>
            <DatePicker
              disabled
              placeholder={"请选择完成日期"}
              className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="通知日期"
            name="wsDay"
            rules={[{required: true, message: '请输入通知日期!'}]}>
            <Select
              allowClear
              labelRender={(selectProps) => <>{firstWsDay || 'yyyy-mm-dd'}（{selectProps.label}）</>}
              onChange={() => calculateAndSetFirstWsDay()}
              placeholder={'请选择通知日期'}
              options={[
                {label: '开始日期当天', value: 0},
                {label: '开始日期前1天', value: 1},
                {label: '开始日期前3天', value: 3},
                {label: '开始日期前7天', value: 7},
                {label: '开始日期前15天', value: 15},
                {label: '开始日期前30天', value: 30},
              ]}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="循环周期"
            name="wsCyclePeriod"
            rules={[{required: true, message: '请选择循环周期!'}]}>
            <SelectDict
              defaultItems={[]}
              code={'work_schedule_cycle'}
              placeholder={'请选择循环周期'}/>
          </Form.Item>
        </Col>
        { /*<Col span={24}>
          <Form.Item
            label="责任人员"
            name="personResponsible"
            rules={[{required: true, message: '请选择责任人员!'}]}>
            <SelectPersonCharge
              className={"width-100-percentage"}
              placeholder={"请选择责任人员"}
              allowClear/>
          </Form.Item>
        </Col>*/}
        <Col span={24}>
          <Form.Item
            label="关联任务"
            name="wsSecondaryTasksId"
            initialValue={''}
            rules={[{required: false, message: '请选择关联任务!'}]}>
            <SelectTaskSupervision
              defaultItems={[{tsCode: 'N/A', id: ''}]}
              tsLevel={'2'}
              onChange={handleWsSecondaryTasksIdChange}
              placeholder={"请选择关联任务"}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="责任部门"
            name="responsibilityProject"
            rules={[{required: false, message: '请选择责任部门!'}]}>
            <SelectProject
              placeholder={"请选择责任部门"}
              onChange={handleProjectChange}
              allowClear
              disabled
              fieldNames={{value: 'name', label: 'name'}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="协作单位"
            name="cooperationUnit"
            rules={[{required: false, message: '请输入协作单位!'}]}>
            <SelectCooperativeUnit
              allowClear
              disabled
              mode={SelectCooperativeUnitMode}
              onChange={(value) => setCooperationUnit(value)}
              fieldNames={{value: 'cuName', label: 'cuName'}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="存档批号"
            name="wsFile"
            rules={[{required: false, message: '请选择存档批号!'}]}>
            <SelectFiling
              responsibilityProject={responsibilityProject}
              isHideClose
              isHidePlus
              cooperationUnit={cooperationUnit}
              filingMode={"multiple"}
              inputDisabled
              placeholder={"请选择存档批号"}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="主办人员"
            name="organizersUserId"
            rules={[{required: false, message: '请选择主办人员!'}]}>
            <SelectPersonCharge
              className={"width-100-percentage"}
              placeholder={"请选择主办人员"}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="办理人员"
            name="handlingRecords"
            rules={[{required: false, message: '请选择办理人员!'}]}>
            <SelectPersonCharge
              className={"width-100-percentage"}
              disabled
              placeholder={"请选择办理人员"}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="参与人员"
            name="wnUserNames"
            rules={[{required: false, message: '请输入参与人员!'}]}>
            <Input placeholder="请输入参与人员" disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="办理进度"
            name="status"
            rules={[{required: false, message: '请输入办理进度!'}]}>
            <SelectProcessStatus
              allowClear
              placeholder={"请选择办理进度"}
              disabled/>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}))
