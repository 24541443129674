import {observer} from "mobx-react";
import React, {useEffect, useLayoutEffect, useState} from "react";
import {FormInstance, message, Modal, Space, Table} from "antd";
import {allStores} from "@/stores";
import {ColumnsType} from "antd/es/table";
import {accAdd, compareObjects, logger, parseURLParams, safeCompute, showErr} from "@common/utils";
import {CostExpendService} from "@services/CostExpendService";
import {CostExpendReq, CostExpendRow, CostExpendSummaryTotal} from "@services/dto/costExpend";
import {BasicInfo} from "@modules/CostExpend/Components/BasicInfo";
import {headerSearchStore} from "@/layout/Stores/HeaderSearchStore";
import {LabelValue, ResponseEntity} from "@services/dto/common";
import {TableEllipsisText} from "@components/TableEllipsisText";
import {TableMoneyText} from "@components/TableMoneyText";
import {BasicInfo as ProcessRecordsBasicInfo} from "@modules/ProcessRecords/Components/BasicInfo";
import {useLocation, useNavigate} from "react-router-dom";
import {HeaderActionCom} from "@modules/CostExpend/Components/HeaderActionCom";
import {
  CloudDownloadOutlined,
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  FieldTimeOutlined,
  PlusOutlined,
  PrinterOutlined
} from "@ant-design/icons";
import {WindowPrint} from "@modules/WindowPrint";
import {TableCostApplication} from "@components/TableCostApplication";
import {CostApplicationRow} from "@services/dto/costApplication";
import {CustomDraggableModal} from "@components/CustomDraggableModal";
import {CostApplication} from "@modules/CostApplication";
import {TableCostReimbursement} from "@components/TableCostReimbursement";
import {CostReimbursement} from "@modules/CostReimbursement";
import {CostReimbursementRow} from "@services/dto/costReimbursement";
import {
  APPROVE_FORM_TRANS_STATE,
  BASE_API_PATH,
  BLACK_LIST,
  DATE_FMT,
  DEFAULT_TABLE_SCROLL_HEIGHT,
  FORM_ALTERNATE_TEXT,
  FORM_CLOSE_TEXT,
  FORM_COMMIT_TEXT,
  FORM_DISABLED_STATE,
  FORM_HANDOVER_TEXT,
  FORM_PERSON_RESPONSIBLE_WHITELIST,
  FORM_RETURN_TEXT,
  FORM_REVOCATION_TEXT,
  LARGE_MODAL_WIDTH,
  LARGE_MODAL_WIDTH_OTHER,
  LARGER_MODAL_WIDTH,
  ONFILE,
  READY,
  TABLE_CELL_WIDTH_1_5x,
  TABLE_CELL_WIDTH_1x,
  TABLE_CELL_WIDTH_2x
} from "@/config";
import {ProjectService} from "@services/ProjectService";
import dayjs from "dayjs";
import {ProcessRecordsRow} from "@services/dto/processRecords";
import {TableAuthOptions} from "@components/CustomAuthOptions";
import {ProcessRecordsService} from "@services/ProcessRecordsService";
import {CustomForm} from "@components/CustomForm";
import {ProcessRecords} from "@modules/ProcessRecords";
import {CustomImport} from "@components/CustomImport/CustomImport";
import {TableFilingText} from "@components/TableFilingText";

const wideShowColumn: string[] = [
  "efpPayTime",
  "efaApplyCode",
  "efpPayType",
  "efpPayDesc",
  "efpAmount",
  'baNick1',
  'baNick2',
  "efpStatus",
  "action",
]

const narrowShowColumn: string[] = [
  "efpPayTime",
  "efaApplyCode",
  "efpPayType",
  "efpPayDesc",
  "efpAmount",
  // 'baNick1',
  // 'baNick2',
  "efpStatus",
  "action",
]

export interface PropsType {
  isModalSelect?: boolean // 拟态宽
  locationPathname?: string // 路由
  isHiddenOptions?: boolean
  dataSource?: CostExpendRow[] // 数据源
}

export const TableName = 'employeeFeePay'

export const CostExpend = observer(React.forwardRef<FormInstance, PropsType>((props, _) => {
  const location = useLocation()
  const navigate = useNavigate()
  const {
    processStore,
    securityStore,
    costExpendStore,
    costReimbursementStore,
    screenResolutionStore,
    tableColumnsStore
  } = allStores
  const basicInfo = React.createRef<FormInstance>();
  const processRecordsBasicInfo = React.createRef<FormInstance>();
  const [dataSource, setDataSource] = useState<CostExpendRow[]>([])
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [total, setTotal] = useState<number>(0)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<CostExpendRow[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isBatchUpdate, setIsBatchUpdate] = useState(false);
  const [costExpendRow, setCostExpendRow] = useState<CostExpendRow>()
  const [updateCount, setUpdateCount] = useState<number>(0)
  const [isView, setIsView] = useState<boolean>(false)
  const [scrollHeight, setScrollHeight] = useState<number>(DEFAULT_TABLE_SCROLL_HEIGHT)
  const [renderColumns, setRenderColumns] = useState<ColumnsType<CostExpendRow>>([])
  const [showColumn, setShowColumn] = useState<string[]>(wideShowColumn)
  const [printTitle, setPrintTitle] = useState<string>('')
  const [isPrintTable, setIsPrintTable] = useState<boolean>(false)
  const [printDataSource, setPrintDataSource] = useState<CostExpendRow[]>([])
  const [contentList, setContentList] = useState<LabelValue[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [costApplicationOpen, setCostApplicationOpen] = useState<boolean>(false)
  const [costApplicationDataSource, setCostApplicationDataSource] = useState<CostApplicationRow[]>([])
  const [costReimbursementOpen, setCostReimbursementOpen] = useState<boolean>(false)
  const [costReimbursementDataSource, setCostReimbursementDataSource] = useState<CostReimbursementRow[]>([])
  const [processRecordsIsOpen, setProcessRecordsIsOpen] = useState<boolean>(false)
  const [processRecordsRow, setProcessRecordsRow] = useState<ProcessRecordsRow>()
  const [processRecordsOpen, setProcessRecordsOpen] = useState<boolean>(false)
  const [processRecordsDataSource, setProcessRecordsDataSource] = useState<ProcessRecordsRow[]>([])
  const [submitRow, setSubmitRow] = useState<CostExpendRow>()
  const [submitterId, setSubmitterId] = useState<string>()
  const [latestProcess, setLatestProcess] = useState<string>()
  const [latestProcessBatch, setLatestProcessBatch] = useState<string[]>()
  const [summaryTotalObj, setSummaryTotalObj] = useState<CostExpendSummaryTotal>({
    efpAmount: 0,
    efpApprovedAmount: 0,
  })
  const [searchReq, setSearchReq] = useState<CostExpendReq>({
    pageNum: 1,
    perPage: 10,
    cooperationUnit: headerSearchStore.headerSearchReq.cooperationUnit,
    responsibilityProject: headerSearchStore.headerSearchReq.responsibilityProject,
    ...costExpendStore.costExpendSearchReq
  })

  const columns: ColumnsType<CostExpendRow> = [
    {
      title: '制单日期',
      dataIndex: 'efpPayTime',
      key: 'efpPayTime',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '制单编号',
      dataIndex: 'efaApplyCode',
      key: 'efaApplyCode',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '费用类别',
      dataIndex: 'efpPayType',
      key: 'efpPayType',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '费用摘要',
      dataIndex: 'efpPayDesc',
      key: 'efpPayDesc',
      width: TABLE_CELL_WIDTH_2x,
      // align: 'center',
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '支出金额(元)',
      dataIndex: 'efpAmount',
      key: 'efpAmount-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '核定金额(元)',
      dataIndex: 'efpApprovedAmount',
      key: 'efpApprovedAmount-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '付款日期',
      dataIndex: 'efpPayDate',
      key: 'efpPayDate',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '付款方',
      dataIndex: 'baNick1',
      key: 'baNick1',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '收款方',
      dataIndex: 'baNick2',
      key: 'baNick2',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '关联费用申请',
      dataIndex: 'efpEmployeeFeeApplicationCode',
      key: 'efpEmployeeFeeApplicationCode',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (_, record) => (
        <Space>
          <TableCostApplication
            isId
            textId={record.efpEmployeeFeeApplicationId}
            code={record.efpEmployeeFeeApplicationCode}
            setDataSource={setCostApplicationDataSource}
            onClick={() => setCostApplicationOpen(true)}/>
        </Space>
      )
    },
    {
      title: '关联发票登记',
      dataIndex: 'efaInvoiceRegistrationCode',
      key: 'efaInvoiceRegistrationCode',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '关联发票金额(元)',
      dataIndex: 'efaInvoiceRegistrationMoney',
      key: 'efaInvoiceRegistrationMoney',
      align: 'right',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}/>
        </Space>
      )
    },
    {
      title: '关联物资入库',
      dataIndex: 'efaMaterialWarehousing',
      key: 'efaMaterialWarehousing',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '关联费用报销',
      dataIndex: 'efaReimbursementCode',
      key: 'efaReimbursementCode',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (_, record) => (
        <Space>
          <TableCostReimbursement
            isId
            textId={record.efaEmployeeReimbursementIds.join(',')}
            code={record.efaReimbursementCode}
            setDataSource={setCostReimbursementDataSource}
            onClick={() => setCostReimbursementOpen(true)}></TableCostReimbursement>
        </Space>
      )
    },
    {
      title: '存档批号',
      dataIndex: 'efpFileArchiveCode',
      key: 'efpFileArchiveCode',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (_, record) => (
        <Space>
          <TableFilingText id={record.efpFileArchive} value={record.efpFileArchiveCode}></TableFilingText>
        </Space>
      )
    },
    {
      title: '责任部门',
      dataIndex: 'responsibilityProject',
      key: 'responsibilityProject',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '协作单位',
      dataIndex: 'cooperationUnit',
      key: 'cooperationUnit',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '审核意见',
      dataIndex: 'efpAuditOpinion',
      key: 'efpAuditOpinion',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '办理人员',
      dataIndex: 'personResponsibleName',
      key: 'personResponsibleName',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '主办人员',
      dataIndex: 'organizersUserName',
      key: 'organizersUserName',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '办理进度',
      dataIndex: 'efpStatus',
      key: 'efpStatus',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (_, record) => (
        <TableAuthOptions
          mainBtn={['handleEdit', 'handleView']}
          items={[
            {
              key: 'handleView',
              icon: <EllipsisOutlined/>,
              onClick: () => handleView(record)
            },
            {
              key: 'handleEdit',
              icon: <EditOutlined/>,
              disabled: FORM_DISABLED_STATE.includes(record.efpStatus),
              onClick: async () => await handleEdit(record)
            },
            {
              key: 'handlePrint',
              icon: <PrinterOutlined/>,
              onClick: () => handlePrint(record)
            },
            {
              key: 'handleDeleteBatch',
              icon: <DeleteOutlined/>,
              disabled: FORM_DISABLED_STATE.includes(record.efpStatus),
              onClick: () => handleDeleteBatch(record)
            },
          ]}></TableAuthOptions>
      )
    }
  ];

  useEffect(() => {
    // 读取存储
    let showColumn: string[] = tableColumnsStore.getItem(props.locationPathname || location.pathname) || []
    if (showColumn.length) {
      // 检验存储中的列表是否符合规范
      showColumn = showColumn.filter(o => columns.some(column => column.key === o))
      // 无论有无存储都触发change，保存当前显示的字段
      setShowColumn(showColumn)
    } else {
      // 窄屏判断
      if (screenResolutionStore.screenResolution.height < 700 || screenResolutionStore.screenResolution.width < 1400) {
        setShowColumn(narrowShowColumn)
      }
    }

    // 当作为组件在其他页面使用且传了数据时 不请求数据
    if (!props.dataSource) {
      refreshData(searchReq)
    }
  }, [])

  useEffect(() => {
    const req = {...searchReq}
    if (req.cooperationUnit !== headerSearchStore.headerSearchReq.cooperationUnit || req.responsibilityProject !== headerSearchStore.headerSearchReq.responsibilityProject) {
      req.cooperationUnit = headerSearchStore.headerSearchReq.cooperationUnit
      req.responsibilityProject = headerSearchStore.headerSearchReq.responsibilityProject
      setSearchReq(req)
      refreshData(req)
    }
  }, [headerSearchStore.headerSearchReq])

  useEffect(() => {
    if (costExpendStore.costExpendDatasource.data) {
      setDataSource(costExpendStore.costExpendDatasource.data?.items || [])
      setTotal(costExpendStore.costExpendDatasource.data?.total || 0)
    }
  }, [costExpendStore.costExpendDatasource.data])

  /**
   * @description 表单数据变化
   */
  useEffect(() => {
    if (costExpendStore.costExpendRow?.costExpendId) {
      handleAdd(costExpendStore.costExpendRow)
      costExpendStore.updateCostExpendRow({
        ...costExpendStore.costExpendRow,
        costExpendId: undefined,
      })
    }
  }, [costExpendStore.costExpendRow])

  /**
   * @description 页面操作变化
   */
  useEffect(() => {
    if (costExpendStore.costExpendOption) {
      const [option, paramsStr] = costExpendStore.costExpendOption.split("?")
      if (option === 'add') {
        handleAdd()
      } else if (paramsStr) {
        const params = parseURLParams(paramsStr)
        if (option === 'edit') {
          CostExpendService.getOne(params.id).then(rsp => {
            if (rsp.code === 200) {
              handleEdit(rsp.data).then()
            }
          })
        }
      }
      costExpendStore.updateCostExpendOption(undefined)
    }
  }, [costExpendStore.costExpendOption])

  useEffect(() => {
    const objInitValue = {
      efpAmount: 0,
      efpApprovedAmount: 0,
    }
    let obj = summaryTotalObj
    const datasource = props.dataSource ? props.dataSource : costExpendStore.costExpendDatasource.data?.items
    const arr = datasource?.filter(o => selectedRowKeys.includes(o.id))
    if (arr?.length) {
      obj = arr.reduce((acc, cur) => ({
        efpAmount: accAdd(acc.efpAmount, cur.efpAmount),
        efpApprovedAmount: accAdd(acc.efpApprovedAmount, cur.efpApprovedAmount),
      }), objInitValue);
      setSummaryTotalObj(obj)
    } else {
      setSummaryTotalObj(objInitValue)
    }
  }, [costExpendStore.costExpendDatasource, selectedRowKeys])

  /**
   * 动态修改表格高度
   */
  useLayoutEffect(() => {
    const othersHeight = 10 + 5 + 5
    const parent = document.querySelector(".tabs-content")
    const headAction = document.querySelector(".header-action-row")
    const thead = document.querySelector(".ant-table-thead")
    const summary = document.querySelector('.ant-table-summary')
    const pagination = document.querySelector('.ant-pagination')
    const paginationHeight = pagination ? pagination.clientHeight + 16 * 2 : 0
    setScrollHeight(safeCompute(parent?.clientHeight) - safeCompute(headAction?.clientHeight) - safeCompute(thead?.clientHeight) - paginationHeight - safeCompute(summary?.clientHeight) - othersHeight)
  }, [screenResolutionStore.screenResolution.height, costExpendStore.collapsed, searchReq.pageNum, searchReq.perPage])

  useEffect(() => {
    if (props.isModalSelect) {
      setRenderColumns(columns.filter(o => o.key !== 'action').filter(o => showColumn.includes(String(o.key))));
    } else {
      setRenderColumns(columns.filter(o => showColumn.includes(String(o.key))));
    }
  }, [showColumn])

  const handlePageChange = (page: number, pageSize: number) => {
    let req = {...searchReq};
    req.perPage = pageSize
    req.pageNum = page
    setSearchReq(req)
    refreshData(req)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const refreshData = (req: CostExpendReq) => {
    setSearchReq(req)
    costExpendStore.getCostExpend(req)
  }

  const batchUpdate = (row: CostExpendRow) => {
    let flag = false
    for (let selectedRow of selectedRows) {
      if (selectedRow.efpStatus === ONFILE) {
        flag = true
        continue
      }
      if (row.efpPayTime && typeof row.efpPayTime === 'object') {
        selectedRow.efpPayTime = row.efpPayTime.format(DATE_FMT);
      }
      if (row.efpPayType) {
        selectedRow.efpPayType = row.efpPayType;
      }
      if (row.efpPayDesc) {
        selectedRow.efpPayDesc = row.efpPayDesc;
      }
      if (row.efpAmount || row.efpAmount === 0) {
        selectedRow.efpAmount = row.efpAmount;
      }
      if (row.efpPayDate && typeof row.efpPayDate === 'object') {
        selectedRow.efpPayDate = row.efpPayDate.format(DATE_FMT);
      }
      if (row.efpPaymentAccount) {
        selectedRow.efpPaymentAccount = row.efpPaymentAccount;
        selectedRow.baNick1 = row.baNick1;
      }
      if (row.baNick2) {
        selectedRow.baNick2 = row.baNick2;
      }
      if (row.efpEmployeeFeeApplicationId) {
        selectedRow.efpEmployeeFeeApplicationId = row.efpEmployeeFeeApplicationId;
        selectedRow.code = row.code;
        selectedRow.cooperationUnit = row.cooperationUnit;
        selectedRow.responsibilityProject = row.responsibilityProject;
      }
      if (row.efaInvoiceRegistration) {
        selectedRow.efaInvoiceRegistration = row.efaInvoiceRegistration;
      }
      if (row.efaMaterialWarehousing) {
        selectedRow.efaMaterialWarehousing = row.efaMaterialWarehousing;
      }
      if (row.personResponsible) {
        selectedRow.personResponsible = row.personResponsible
        selectedRow.personResponsibleName = row.personResponsibleName
      }
      if (row.responsibilityProject) {
        selectedRow.code = row.code
        selectedRow.responsibilityProject = row.responsibilityProject
      }
      if (row.cooperationUnit && typeof row.cooperationUnit === 'object') {
        selectedRow.cooperationUnit = row.cooperationUnit.join(',')
      }
      if (row.efpAuditOpinion) {
        selectedRow.efpAuditOpinion = row.efpAuditOpinion
      }
      if (row.efpApprovedAmount) {
        selectedRow.efpApprovedAmount = row.efpApprovedAmount
      }
      if (row.efpStatus) {
        selectedRow.efpStatus = row.efpStatus
      }
    }
    if (flag) {
      message.warning(`办理进度为${ONFILE}的数据不可编辑！`).then()
    }
    CostExpendService.batchUpdateCostExpend(selectedRows).then((rsp) => {
      actionThen(rsp);
      setUpdateCount(updateCount + 1)
    }).catch(showErr).finally(() => setConfirmLoading(false));
  }

  /**
   * @description 打印表格
   */
  const handlePrintTable = () => {
    // 数据处理
    setPrintDataSource(costExpendStore.costExpendDatasource.data?.items.filter(o => selectedRowKeys.includes(o.id)))
    setIsPrintTable(true)
    setPrintTitle('费用支出表格')
    setIsModalOpen(true)
  }

  const handleBatchCostReimbursementClick = () => {

    const efpEmployeeFeeApplicationId = costExpendStore.costExpendSearchReq.efpEmployeeFeeApplicationId
    if (!efpEmployeeFeeApplicationId || BLACK_LIST.includes(efpEmployeeFeeApplicationId)) {
      message.warning("请选择费用申请！").then()
      return
    }
    if (!selectedRowKeys.length) {
      message.warning("请选择数据！").then()
      return
    }

    const rows = costExpendStore.costExpendDatasource.data?.items
      .filter(o => selectedRowKeys.includes(o.id))

    const notReimbursements: string[] = []
    rows.forEach(row => {
      if (BLACK_LIST.includes(row.efpAuditOpinion)) {
        notReimbursements.push(row.efaApplyCode);
      }
    })
    if (notReimbursements.length > 0) {
      message.warning(`制单编号为：${notReimbursements.join(",")} 的数据审核意见为空不允许报销`).then()
      return
    }

    Modal.confirm({
      title: "是否一键报销？",
      okText: "确定",
      cancelText: "取消",
      onOk: async () => {

        const demo = rows[0]

        const verification = rows
          .every(item => compareObjects(
            (item.cooperationUnit as string).split(','),
            (demo.cooperationUnit as string).split(',')
          ))

        if (!verification) {
          // 全部一起提示
          Modal.info({
            title: `温馨提示-一键报销`,
            content: '协作单位不一致的费用支出不能同一批报销',
            okText: "确定",
            cancelText: "取消",
            onOk() {
              setSelectedRowKeys([])
              refreshData(costExpendStore.costExpendSearchReq)
            },
          })
          return
        }

        // 注意责任部门 code
        if (BLACK_LIST.includes(rows[0].responsibilityProject)) {
          message.warning("请检查责任部门！").then()
          return
        }
        const [responsibilityProject] = await ProjectService.getBatchByName([rows[0].responsibilityProject])
        if (!responsibilityProject) {
          message.warning("查无责任部门数据！").then()
          return
        }

        costReimbursementStore.updateCostReimbursementRow({
          efpAccount2: undefined,
          efpBlank2: undefined,
          efpType: "",
          efpName2: undefined,
          efpPaymentAccount: undefined,
          efpPaymentAccountName: undefined,
          code: responsibilityProject?.code,
          cooperationUnit: ['N/A'],
          costReimbursementId: rows[0].id,
          efaFileArchive: undefined,
          efpAccount1: undefined,
          efpAccountingObject: undefined,
          efpAccountingObjectName: undefined,
          efpBlank1: undefined,
          efpEmployeeFeeApplicationIds: [],
          efpName1: undefined,
          employeeFeePayIds: rows.map(o => o.id),
          erApprovalCode: undefined,
          erDate: undefined,
          erDateOfPreparation: dayjs(new Date()), // 制单日期
          erEstatus: undefined,
          erExpenseSummary: rows.map(o => o.efpPayDesc).join('；'),
          erReimbursementAmount: rows.reduce((sum, cur) => accAdd(sum, cur.efpAmount), 0),
          erReimbursementBalance: 0,
          erReimbursementPay: 0,
          fundApprovalId: undefined,
          id: undefined,
          isTaApplicationForm: undefined,
          personResponsible: rows[0].personResponsible,
          personResponsibleName: rows[0].personResponsibleName,
          organizersUserId: undefined,
          organizersUserName: undefined,
          responsibilityProject: rows[0].responsibilityProject
        })
        setSelectedRowKeys([])
        navigate('/costReimbursement')

      },
    });
  }

  /**
   * 打印单条数据
   * @param record
   */
  const handlePrint = async (record: CostExpendRow) => {
    const list = await costExpendStore.getPrintList(record)
    if (list) {
      setContentList(list)
      setPrintTitle('费用支出单')
      setIsPrintTable(false)
      setIsModalOpen(true)
    } else {
      message.warning('请检查')
    }
  }

  const handleTableColumnsChange = (value: string[]) => {
    setShowColumn(value)
    tableColumnsStore.setItem(props.locationPathname || location.pathname, value)
  }

  const handleCollapsedChange = (collapsed: boolean) => {
    costExpendStore.setCollapsed(collapsed)
  }

  const handleShowColumnReset = () => {
    // 窄屏判断
    if (screenResolutionStore.screenResolution.height < 700 || screenResolutionStore.screenResolution.width < 1400) {
      setShowColumn(narrowShowColumn)
    } else {
      setShowColumn(wideShowColumn)
    }
  }

  const handleDownloadTemp = () => {
    window.open(BASE_API_PATH + "/filepath/file/employee_fee_pay.xlsx")
  }

  // 新增
  // 费用申请发起
  const handleAdd = (row?: CostExpendRow) => {
    const user = securityStore.getLoggedUser()
    const initRow = {
      efpStatus: READY,
      personResponsible: user._id,
      personResponsibleName: user.name,
      organizersUserId: user._id,
      organizersUserName: user.name,
      efpPayTime: dayjs(),
    } as CostExpendRow
    Object.assign(row || {}, initRow)
    setCostExpendRow(row || initRow)
    setIsView(false)
    setIsBatchUpdate(false)
    setIsModalVisible(true)
  }

  const handleView = (record: CostExpendRow) => {
    setCostExpendRow(record)
    setIsView(true)
    setIsBatchUpdate(false)
    setIsModalVisible(true);
  }

  const handleEdit = async (record: CostExpendRow) => {

    try {
      const rsp = await ProcessRecordsService.getProcessRecords({
        businessId: record.id,
        pageNum: 1,
        perPage: 999,
        type: TableName,
      })
      const process = rsp.items.length ? rsp.items[0].process : ''
      const userId = rsp.items.find(o => o.process === process && o.status === FORM_COMMIT_TEXT)?.userId
      const con = rsp.items.some(o => o.process === process && o.status === FORM_REVOCATION_TEXT)
      // 如果本轮有撤回 （id为空） 否则 （发起最新一轮的提交的人的id）
      setSubmitterId(con ? undefined : userId)
      setLatestProcess(process) // 发起最新一轮的标志

      setCostExpendRow(record)
      setIsView(false)
      setIsBatchUpdate(false)
      setIsModalVisible(true);
    } catch (err) {
      message.warning("办理记录查询失败！").then()
      logger.log(err)
    }

  }

  const handleEditBatch = async () => {

    try {

      if (selectedRowKeys.length === 0) {
        message.warning("请选择要操作的数据！").then()
        return
      }

      // 要操作的数据
      const rows = costExpendStore.costExpendDatasource.data?.items.filter(o => selectedRowKeys.includes(o.id))

      // 办理流程辅助字段：办理进度
      let efpStatus: string

      // 判断是否符合批量操作条件
      const con = rows.every((o, i, arr) => {
        const firstRow = arr[0]
        efpStatus = firstRow.efpStatus
        if (o.personResponsible !== firstRow.personResponsible) {
          message.warning("办理人员不一致！").then()
          return false
        } else if (o.efpStatus !== firstRow.efpStatus) {
          message.warning("办理进度不一致！").then()
          return false
        } else return true
      })

      if (con) {

        let latestProcessList: string[] = []
        let submitterIdList: string[] = []
        for (let i = 0; i < rows.length; i++) {
          const item = rows[i]
          const rsp = await ProcessRecordsService.getProcessRecords({
            businessId: item.id,
            pageNum: 1,
            perPage: 999,
            type: TableName,
          })
          const process = rsp.items.length ? rsp.items[0].process : ''
          const userId = rsp.items.find(o => o.process === process && o.status === FORM_COMMIT_TEXT)?.userId
          const userIdCon = rsp.items.some(o => o.process === process && o.status === FORM_REVOCATION_TEXT)
          latestProcessList.push(process)
          submitterIdList.push(userIdCon ? undefined : userId)
        }

        // 如果提交人全部一致才赋值给参数:【提交人】
        setSubmitterId(new Set(submitterIdList).size === 1 ? submitterIdList[0] : undefined)
        setLatestProcessBatch(latestProcessList)
        setIsView(false)
        setIsBatchUpdate(true)
        setCostExpendRow({efpStatus} as CostExpendRow)
        setIsModalVisible(true)

      }

    } catch (err) {
      message.warning("办理记录查询失败！").then()
      logger.log(err)
    }

  }

  const handleDeleteBatch = (record?: CostExpendRow) => {
    const keys = [...selectedRowKeys]
    if (!record && keys.length === 0) {
      message.warning("请选择要删除的数据！").then()
      return
    }
    let ids: string[] = []
    if (record) {
      ids.push(record.id)
    } else {
      ids = keys.map(item => String(item))
    }
    Modal.confirm({
      title: "是否删除？",
      okText: "确定",
      cancelText: "取消",
      onOk() {
        setConfirmLoading(true)
        CostExpendService.deleteCostExpend(ids).then(() => {
          message.success("删除成功！").then()
          setConfirmLoading(false)
          refreshData(costExpendStore.costExpendSearchReq)
        }).catch(showErr).finally(() => setConfirmLoading(false))
      },
    })
  }

  const insertOrUpdate = async (row: CostExpendRow) => {
    if (row.efpPayTime && typeof row.efpPayTime === 'object') {
      row.efpPayTime = row.efpPayTime.format(DATE_FMT);
    }
    if (row.efpPayDate && typeof row.efpPayDate === 'object') {
      row.efpPayDate = row.efpPayDate.format(DATE_FMT);
    }
    if (typeof row.cooperationUnit === 'object') {
      row.cooperationUnit = row.cooperationUnit
        .filter((v, i, self) => self.indexOf(v) === i) // 去重
        .filter(value => !(BLACK_LIST.includes(value))) // 去黑名单
        .join(',');
    }
    if (row.id) {
      const rsp = await CostExpendService.updateCostExpend(row)
      setUpdateCount(updateCount + 1)
      setConfirmLoading(false)
      actionThen(rsp)
      return rsp
    } else {
      const rsp = await CostExpendService.addCostExpend(row)
      setUpdateCount(updateCount + 1)
      setConfirmLoading(false)
      actionThen(rsp)
      return rsp
    }
  }

  const actionThen = (rsp: ResponseEntity<any>) => {
    if (rsp.code === 200) {
      message.success("操作成功！").then()
    } else {
      message.warning(rsp.message).then()
    }
    setIsModalVisible(false)
    setIsBatchUpdate(false)
    refreshData(costExpendStore.costExpendSearchReq)
    setSelectedRowKeys([])
  }

  const handleOk = (open: boolean) => {
    const row = {...costExpendRow!}
    basicInfo.current!.validateFields().then(async (values) => {
      Object.assign(row, values)

      if (open) {

        // 将row和按钮先存起来
        setSubmitRow(row)

        // 弹窗选择处理人
        setProcessRecordsIsOpen(true)
        setProcessRecordsRow(undefined)

      } else {

        const user = securityStore.getLoggedUser()

        if (isBatchUpdate) {
          batchUpdate(row)
        } else {
          // 默认处理人员是自己
          if (!row.id) {
            row.personResponsible = user._id
            row.personResponsibleName = user.name
          }

          try {
            await insertOrUpdate(row)
          } catch (error) {
            setProcessRecordsIsOpen(false)
            setProcessRecordsRow(undefined)
            setConfirmLoading(false)
            setIsModalVisible(false);
            message.warning(error.message)
          }
        }

      }

    }).catch(logger.warn)
  }

  const handleSubmit = () => {
    const row = {...processRecordsRow!}
    processRecordsBasicInfo.current!.validateFields().then(async (values) => {
      Object.assign(row, values)

      const user = securityStore.getLoggedUser()
      const btnText = row.status

      // 当类型是撤回时 默认办理人员为提交人(自己)
      if (btnText === FORM_REVOCATION_TEXT) {
        row.personResponsible = user._id || submitterId
        row.personResponsibleName = user.name
      }

      // 主表
      const subRow = {...submitRow}
      subRow.personResponsible = row.personResponsible
      subRow.personResponsibleName = row.personResponsibleName
      subRow.efpStatus = APPROVE_FORM_TRANS_STATE[btnText] || subRow.efpStatus
      // 主表在撤回、退回、变更时->更新局部数据
      if ([FORM_REVOCATION_TEXT, FORM_RETURN_TEXT, FORM_ALTERNATE_TEXT].includes(btnText)) {
        subRow.organizersUserId = subRow.personResponsible
        subRow.organizersUserName = subRow.personResponsibleName
      }
      // 主表在转交、提交时->更新局部数据
      if ([FORM_HANDOVER_TEXT, FORM_COMMIT_TEXT].includes(btnText)) {
        subRow.organizersUserId = subRow.personResponsible
        subRow.organizersUserName = subRow.personResponsibleName
      }
      // 主表在提交时->更新局部数据
      if (btnText === FORM_COMMIT_TEXT) {
        subRow.efpPayTime = new Date().toLocaleDateString().replace(/\//g, '-')
      }
      // 主表在结办时->更新局部数据
      if (btnText === FORM_CLOSE_TEXT) {

      }

      // 办理记录表
      row.type = TableName
      row.businessId = subRow.id // 有可能会没值
      row.status = btnText
      row.userId = user._id
      row.userName = user.name
      row.personResponsible = undefined
      row.personResponsibleName = undefined
      if (btnText === FORM_COMMIT_TEXT) {
        const date = new Date()
        row.process = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
      } else {
        row.process = latestProcess
      }
      if (FORM_PERSON_RESPONSIBLE_WHITELIST.includes(btnText)) {
        row.conca = `${user.name}${btnText}给${subRow.personResponsibleName}。`
      } else {
        row.conca = `${user.name}${btnText}了。`
      }

      // 判断是否 先新增主表 后新增记录和通知表
      if (subRow.id) {

        let firstOperationSucceeded = false;
        try {
          await insertOrUpdate(subRow);
          firstOperationSucceeded = true;
        } catch (error) {
          setProcessRecordsIsOpen(false)
          setProcessRecordsRow(undefined)
          setConfirmLoading(false)
          setIsModalVisible(false);
          message.warning(error.message)
        }
        if (!firstOperationSucceeded) return
        await ProcessRecordsService.addProcessRecords(row)
        const rsp = await ProcessRecordsService.getProcessRecords({
          businessId: subRow.id,
          pageNum: 1,
          perPage: 999,
          type: TableName,
        })
        const notificationScopeUserId = rsp.items
          .map(o => o.userId)
          .concat(row.person)
          .filter((v, i, self) => self.indexOf(v) === i) // 去重
          .filter(id => id !== user._id) // 过滤出不是自己的数据
          .join(',') || ''
        const notificationScopeUserName = rsp.items
          .map(o => o.userName)
          .concat((row.personName || '').split(','))
          .filter((v, i, self) => self.indexOf(v) === i) // 去重
          .filter(name => name !== user.name) // 过滤出不是自己的数据
          .join(',') || ''
        await processStore.newAssignmentNotice({
          businessId: subRow.id,
          businessCode: subRow.efaApplyCode,
          cooperationUnit: subRow.cooperationUnit[0] || 'N/A',
          notificationScopeUserId,
          notificationScopeUserName,
          personResponsible: subRow.personResponsible,
          personResponsibleName: subRow.personResponsibleName,
          responsibilityProject: subRow.responsibilityProject,
          type: "费用支出",
          wnDesc: `您参与办理的费用支出（${subRow.efaApplyCode}），${row.conca} 请知悉！`,
          wnStatus: "未读"
        })

      } else {

        let subRsp: ResponseEntity<CostExpendRow>
        try {
          subRsp = await insertOrUpdate(subRow)
        } catch (error) {
          setProcessRecordsIsOpen(false)
          setProcessRecordsRow(undefined)
          setConfirmLoading(false)
          setIsModalVisible(false);
          message.warning(error.message)
        }

        if (subRsp.data) {
          // 重新赋值再提交
          row.businessId = subRsp.data.id
          await ProcessRecordsService.addProcessRecords(row)
          const rsp = await ProcessRecordsService.getProcessRecords({
            businessId: subRsp.data.id,
            pageNum: 1,
            perPage: 999,
            type: TableName,
          })
          const notificationScopeUserId = rsp.items
            .map(o => o.userId)
            .concat(row.person)
            .filter((v, i, self) => self.indexOf(v) === i) // 去重
            .filter(id => id !== user._id) // 过滤出不是自己的数据
            .join(',') || ''
          const notificationScopeUserName = rsp.items
            .map(o => o.userName)
            .concat((row.personName || '').split(','))
            .filter((v, i, self) => self.indexOf(v) === i) // 去重
            .filter(name => name !== user.name) // 过滤出不是自己的数据
            .join(',') || ''
          await processStore.newAssignmentNotice({
            businessId: subRsp.data.id,
            businessCode: subRsp.data.efaApplyCode,
            cooperationUnit: (subRsp.data.cooperationUnit as string).split(',')[0] || 'N/A',
            notificationScopeUserId,
            notificationScopeUserName,
            personResponsible: subRsp.data.personResponsible,
            personResponsibleName: subRsp.data.personResponsibleName,
            responsibilityProject: subRsp.data.responsibilityProject,
            type: "费用支出",
            wnDesc: `您参与办理的费用支出（${subRsp.data.efaApplyCode}），${row.conca} 请知悉！`,
            wnStatus: "未读"
          })
        } else {
          message.warning('主表保存成功，办理记录表和任务通知表提交失败')
        }

      }
      setProcessRecordsIsOpen(false)
      setProcessRecordsRow(undefined)
    }).catch(logger.warn)
  }

  const handleSubmitBatch = () => {
    const row = {...processRecordsRow!}
    processRecordsBasicInfo.current!.validateFields().then(async (values) => {

      const rows = costExpendStore.costExpendDatasource.data?.items.filter(o => selectedRowKeys.includes(o.id))
      for (let i = 0; i < rows.length; i++) {

        Object.assign(row, values)

        const user = securityStore.getLoggedUser()
        const btnText = row.status

        // 当类型是撤回时 默认办理人员为提交人(自己)
        if (btnText === FORM_REVOCATION_TEXT) {
          row.personResponsible = user._id || submitterId
          row.personResponsibleName = user.name
        }

        // 主表
        const subRow = {...rows[i]}
        subRow.personResponsible = row.personResponsible
        subRow.personResponsibleName = row.personResponsibleName
        subRow.efpStatus = APPROVE_FORM_TRANS_STATE[btnText] || subRow.efpStatus
        // 主表在撤回、退回、变更时->更新局部数据
        if ([FORM_REVOCATION_TEXT, FORM_RETURN_TEXT, FORM_ALTERNATE_TEXT].includes(btnText)) {
          subRow.organizersUserId = subRow.personResponsible
          subRow.organizersUserName = subRow.personResponsibleName
        }
        // 主表在转交、提交时->更新局部数据
        if ([FORM_HANDOVER_TEXT, FORM_COMMIT_TEXT].includes(btnText)) {
          subRow.organizersUserId = subRow.personResponsible
          subRow.organizersUserName = subRow.personResponsibleName
        }
        // 主表在提交时->更新局部数据
        if (btnText === FORM_COMMIT_TEXT) {
          subRow.efpPayTime = new Date().toLocaleDateString().replace(/\//g, '-')
        }
        // 主表在结办时->更新局部数据
        if (btnText === FORM_CLOSE_TEXT) {

        }

        // 办理记录表
        row.type = TableName
        row.businessId = subRow.id // 有可能会没值
        row.status = btnText
        row.userId = user._id
        row.userName = user.name
        row.personResponsible = undefined
        row.personResponsibleName = undefined
        if (btnText === FORM_COMMIT_TEXT) {
          const date = new Date()
          row.process = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
        } else {
          row.process = latestProcessBatch[i]
        }
        if (FORM_PERSON_RESPONSIBLE_WHITELIST.includes(btnText)) {
          row.conca = `${user.name}${btnText}给${subRow.personResponsibleName}。`
        } else {
          row.conca = `${user.name}${btnText}了。`
        }

        let firstOperationSucceeded = false;
        try {
          await insertOrUpdate(subRow);
          firstOperationSucceeded = true;
        } catch (error) {
          setProcessRecordsIsOpen(false)
          setProcessRecordsRow(undefined)
          setConfirmLoading(false)
          setIsModalVisible(false);
          message.warning(error.message)
        }
        if (!firstOperationSucceeded) return
        await ProcessRecordsService.addProcessRecords(row)
        const rsp = await ProcessRecordsService.getProcessRecords({
          businessId: subRow.id,
          pageNum: 1,
          perPage: 999,
          type: TableName,
        })
        const notificationScopeUserId = rsp.items
          .map(o => o.userId)
          .concat(row.person)
          .filter((v, i, self) => self.indexOf(v) === i) // 去重
          .filter(id => id !== user._id) // 过滤出不是自己的数据
          .join(',') || ''
        const notificationScopeUserName = rsp.items
          .map(o => o.userName)
          .concat((row.personName || '').split(','))
          .filter((v, i, self) => self.indexOf(v) === i) // 去重
          .filter(name => name !== user.name) // 过滤出不是自己的数据
          .join(',') || ''
        await processStore.newAssignmentNotice({
          businessId: subRow.id,
          businessCode: subRow.efaApplyCode,
          cooperationUnit: subRow.cooperationUnit[0] || 'N/A',
          notificationScopeUserId,
          notificationScopeUserName,
          personResponsible: subRow.personResponsible,
          personResponsibleName: subRow.personResponsibleName,
          responsibilityProject: subRow.responsibilityProject,
          type: "费用支出",
          wnDesc: `您参与办理的费用支出（${subRow.efaApplyCode}），${row.conca} 请知悉！`,
          wnStatus: "未读"
        })

      }

      setProcessRecordsIsOpen(false)
      setProcessRecordsRow(undefined)
    }).catch(logger.warn)
  }

  /**
   * 操作记录按钮
   */
  const handleProcessRecordsClick = async (record: CostExpendRow) => {
    const rsp = await ProcessRecordsService.getProcessRecords({
      businessId: record.id,
      pageNum: 1,
      perPage: 999,
      type: TableName
    })
    if (!rsp) {
      message.warning('查无数据')
      return
    }
    setProcessRecordsDataSource(rsp.items)
    setProcessRecordsOpen(true)
  }

  const handleCopyClick = (record: CostExpendRow) => {
    const user = securityStore.getLoggedUser()
    const initRow = {
      ...record,
      id: '',
      efpFileArchive: '',
      efaEmployeeReimbursementIds: [],
      efpStatus: READY,
      personResponsible: user._id,
      personResponsibleName: user.name,
      organizersUserId: user._id,
      organizersUserName: user.name,
      efpPayTime: dayjs(),
    }
    setCostExpendRow(initRow)
    setIsView(false)
    setIsBatchUpdate(false)
    setIsModalVisible(true)
  }

  return (
    <div className={"institution"}>
      <HeaderActionCom
        size={props.isModalSelect ? 6 : undefined}
        locationPathname={props.locationPathname}
        updateCount={updateCount}
        setCollapsed={handleCollapsedChange}
        searchReq={searchReq}
        handleShowColumnReset={handleShowColumnReset}
        handleTableColumnsChange={handleTableColumnsChange}
        showColumn={showColumn}
        tableColumns={props.isModalSelect ? columns.filter(o => o.key !== 'action') : columns}
        refreshData={props.dataSource ? undefined : (req) => refreshData({...searchReq, ...req})}
        collapsed={costExpendStore.collapsed}
        items={[
          {
            key: 'handleAdd',
            icon: <PlusOutlined/>,
            onClick: () => handleAdd()
          },
          {
            key: 'handleDeleteBatch',
            icon: <DeleteOutlined/>,
            onClick: () => handleDeleteBatch()
          },
          {
            key: 'handleEditBatch',
            icon: <EditOutlined/>,
            onClick: () => handleEditBatch()
          },
          {
            key: 'handlePrintTable',
            icon: <PrinterOutlined/>,
            onClick: () => handlePrintTable()
          },
          {
            key: 'handleDownloadTemp',
            icon: <CloudDownloadOutlined/>,
            onClick: () => handleDownloadTemp()
          },
          {
            key: 'handleImport',
            node: <CustomImport
              url={'/v1/employeeFeePay/import'}
              callback={() => refreshData(costExpendStore.costExpendSearchReq)}></CustomImport>,
          },
          {
            key: 'handleBatchCostReimbursementClick',
            icon: <EditOutlined/>,
            onClick: () => handleBatchCostReimbursementClick()
          },
        ]}></HeaderActionCom>

      <Table
        className={'margin-top-xss'}
        rowKey={"id"}
        dataSource={props.dataSource || dataSource}
        columns={renderColumns}
        bordered
        size={"middle"}
        loading={props.dataSource ? false : costExpendStore.costExpendDatasource.loading || confirmLoading}
        pagination={!props.dataSource && {
          total: total,
          position: ['bottomCenter'],
          pageSize: searchReq.perPage,
          current: searchReq.pageNum,
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: total => `共${total}条`,
          onChange: handlePageChange
        }}
        scroll={{y: scrollHeight, scrollToFirstRowOnChange: true}}
        rowSelection={{
          selectedRowKeys,
          onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows)
            setSelectedRowKeys(selectedRowKeys)
          },
          columnWidth: 50,
        }}
        summary={() => (
          <Table.Summary fixed={"bottom"}>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} key={0} colSpan={1} className='text-align-center'>
                { selectedRowKeys.length }
              </Table.Summary.Cell>
              {
                (showColumn as string[]).map((key, index) =>
                  key.includes('-summation') ? <Table.Summary.Cell
                    index={index} key={index + 1} colSpan={1} className='text-align-right'>
                    合计：<TableMoneyText value={summaryTotalObj[key.slice(0, key.indexOf('-'))]}></TableMoneyText> 元
                  </Table.Summary.Cell> : <Table.Summary.Cell key={index + 1} index={index} colSpan={1}/>)
              }
            </Table.Summary.Row>
          </Table.Summary>
        )}/>

      { /*关联费用申请*/ }
      <CustomDraggableModal
        title={<div className="text-align-center">关联费用申请</div>}
        open={costApplicationOpen}
        width={LARGER_MODAL_WIDTH}
        onCancel={() => setCostApplicationOpen(false)}
        destroyOnClose
        footer={null}>
        <CostApplication
          dataSource={costApplicationDataSource}
          isModalSelect
          locationPathname={'/costApplication'}/>
      </CustomDraggableModal>

      { /*关联费用报销*/ }
      <CustomDraggableModal
        title={<div className="text-align-center">关联费用报销</div>}
        open={costReimbursementOpen}
        width={LARGER_MODAL_WIDTH}
        onCancel={() => setCostReimbursementOpen(false)}
        destroyOnClose
        footer={null}>
        <CostReimbursement
          dataSource={costReimbursementDataSource}
          isModalSelect
          locationPathname={'/costReimbursement'}/>
      </CustomDraggableModal>

      { /*操作记录表*/ }
      <CustomDraggableModal
        title={<div className="text-align-center">办理记录</div>}
        open={processRecordsOpen}
        width={LARGE_MODAL_WIDTH_OTHER}
        onCancel={() => setProcessRecordsOpen(false)}
        destroyOnClose
        footer={null}>
        <ProcessRecords
          isHiddenOptions
          dataSource={processRecordsDataSource}
          isModalSelect
          locationPathname={'/processRecords'}/>
      </CustomDraggableModal>

      { /*操作记录表单*/ }
      <CustomDraggableModal
        title={<div className="text-align-center">办理记录</div>}
        open={processRecordsIsOpen}
        width={LARGE_MODAL_WIDTH}
        destroyOnClose
        onCancel={() => {
          setProcessRecordsIsOpen(false)
          setConfirmLoading(false)
        }}
        onOk={isBatchUpdate ? handleSubmitBatch : handleSubmit}>
        <ProcessRecordsBasicInfo
          tableName={TableName}
          businessId={costExpendRow?.id}
          submitterId={submitterId}
          userId={costExpendRow?.personResponsible}
          status={costExpendRow?.efpStatus}
          row={processRecordsRow}
          ref={processRecordsBasicInfo}/>
      </CustomDraggableModal>

      <CustomForm
        title={'费用支出'}
        handleCancel={handleCancel}
        handleOk={handleOk}
        isModalVisible={isModalVisible}
        submitterId={submitterId}
        confirmLoading={confirmLoading}
        userId={costExpendRow?.personResponsible}
        status={costExpendRow?.efpStatus}
        isView={isView}
        extraDisabled={!costExpendRow?.id}
        extraOnClick={() => handlePrint(costExpendRow)}
        item={[
          // {
          //   key: 'handleFundApprovalClick',
          //   icon: <RetweetOutlined/>,
          //   hidden: costExpendRow?.efpStatus !== CLOSE,
          //   onClick: () => handleFundApprovalClick(costExpendRow)
          // },
          {
            key: 'handleCopyClick',
            icon: <FieldTimeOutlined/>,
            disabled: !costExpendRow?.id,
            onClick: () => handleCopyClick(costExpendRow)
          },
          {
            key: 'handleProcessRecordsClick',
            icon: <FieldTimeOutlined/>,
            disabled: !costExpendRow?.id,
            onClick: () => handleProcessRecordsClick(costExpendRow)
          },
        ]}>
        <BasicInfo row={{...costExpendRow!}} ref={basicInfo} isBatchUpdate={isBatchUpdate} isView={isView}/>
      </CustomForm>

      <WindowPrint
        isHiddenTime
        isSummary
        title={printTitle}
        contentList={contentList}
        isModalOpen={isModalOpen}
        isPrintTable={isPrintTable}
        tableColumns={columns}
        dataSource={printDataSource}
        narrowShowColumn={narrowShowColumn}
        handleCancel={() => {
          setIsModalOpen(false)
          setSelectedRowKeys([])
        }}/>
    </div>
  )
}))
