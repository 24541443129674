import React, {useEffect, useState} from "react";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import {HeaderAction, PropsType as ParentPropsType} from "@components/HeaderAction";
import {ColumnsType} from "antd/es/table";
import {DatePicker} from "antd";
import dayjs from "dayjs";
import {SelectPersonCharge} from "@components/SelectPersonCharge";
import {SelectDict} from "@components/SelectDict";
import {SelectProject} from "@components/SelectProject";
import {SelectCooperativeUnit} from "@components/SelectCooperativeUnit";
import {Any} from "@common/types";
import {SelectFiling} from "@components/SelectFiling";
import {WorkScheduleReq} from "@services/dto/workSchedule";
import {SelectProcessStatus} from "@components/SelectProcessStatus";
import {SelectTaskSupervision} from "@components/SelectTaskSupervision";

export interface PropsType extends ParentPropsType {
  selectedRowKeys?: React.Key[], // 所选行
  handleDeleteBatch?: () => void,
  handleAdd?: () => void,
  handleKeywordSearch?: () => void,
  handleKeywordChange?: (value: string) => void,
  handleTableColumnsChange?: (value: string[]) => void,
  showColumn?: string[],
  tableColumns?: ColumnsType,
  refreshData?: (req: WorkScheduleReq) => void,
  collapsed?: boolean, // 是否展开
  setCollapsed?: (v: boolean) => void, // 展开事件
  searchReq: WorkScheduleReq
}

export const HeaderActionCom = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, _) => {

  const [searchReq, setSearchReq] = useState<WorkScheduleReq>({
    pageNum: 1,
    perPage: 10,
  })

  useEffect(() => {
    if (props.searchReq) {
      setSearchReq(props.searchReq);
    }
  }, [props.searchReq])

  const refreshData = (req: WorkScheduleReq) => {
    if (props.refreshData) {
      props.refreshData(req)
    }
  }

  const handleWsDateChange = (_: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null, dateStrings: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.wsDate1 = dateStrings[0]
    req.wsDate2 = dateStrings[1]
    setSearchReq(req)
    refreshData(req)
  }

  const handleWsWorkPlanCompletionDateChange = (_: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null, dateStrings: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.wsWorkPlanCompletionDate1 = dateStrings[0]
    req.wsWorkPlanCompletionDate2 = dateStrings[1]
    setSearchReq(req)
    refreshData(req)
  }

  const handleWsWorkPlanStartDateChange = (_: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null, dateStrings: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.wsWorkPlanStartDate1 = dateStrings[0]
    req.wsWorkPlanStartDate2 = dateStrings[1]
    setSearchReq(req)
    refreshData(req)
  }

  const handleHandlingRecordsNameChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.handlingRecords = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleWsSecondaryTasksIdChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.wsSecondaryTasksId = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleWsCyclePeriodChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.wsCyclePeriod = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleResponsibilityProjectChange = (value: Any | null) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.responsibilityProject = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleCooperationUnitChange = (value: Any | null) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.cooperationUnit = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleStatusChange = (value: Any | null) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.status = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleKeywordClear = () => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = undefined
    setSearchReq(req)
    refreshData(req)
  }

  const handleKeywordSearch = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleWsFileChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.wsFile = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleOrganizersUserIdChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.organizersUserId = value
    setSearchReq(req)
    refreshData(req)
  }

  /**
   * 重置请求参数
   */
  const handleSearchReqReset = () => {
    const req = {...searchReq}
    req.pageNum = 1
    req.perPage = 10
    req.keyword = undefined
    req.cooperationUnit = undefined
    req.responsibilityProject = undefined

    req.wsDate1 = undefined
    req.wsDate2 = undefined
    req.wsCyclePeriod = undefined
    req.wsWorkPlanStartDate1 = undefined
    req.wsWorkPlanStartDate2 = undefined
    req.wsWorkPlanCompletionDate1 = undefined
    req.wsWorkPlanCompletionDate2 = undefined
    req.wsFile = undefined
    req.personResponsible = undefined
    req.personResponsibleName = undefined
    req.handlingRecords = undefined
    req.handlingRecordsName = undefined
    req.status = undefined
    req.organizersUserId = undefined // 主办人员
    req.organizersUserName = undefined // 主办人员名

    setSearchReq(req)
    refreshData(req)
  }

  return (
    <>
      <HeaderAction
        size={props.size}
        isHiddenOptions={props.isHiddenOptions}
        collapsed={props.collapsed}
        setCollapsed={props.setCollapsed}
        searchReq={props.searchReq}
        handleShowColumnReset={props.handleShowColumnReset}
        handleSearchReqReset={props.refreshData ? handleSearchReqReset : undefined}
        selectedRowKeys={props.selectedRowKeys}
        handleDeleteBatch={props.handleDeleteBatch}
        handleAdd={props.handleAdd}
        handleKeywordSearch={handleKeywordSearch}
        handleKeywordClear={handleKeywordClear}
        handleTableColumnsChange={props.handleTableColumnsChange}
        showColumn={props.showColumn}
        tableColumns={props.tableColumns}
        locationPathname={props.locationPathname}
        isShowKeyword={!props.refreshData}
        items={props.items}
        collapsedChildren={props.refreshData ? [
          <DatePicker.RangePicker
            allowClear
            value={searchReq?.wsDate1 ? [dayjs(searchReq?.wsDate1), dayjs(searchReq?.wsDate2)] : [undefined, undefined]}
            onChange={handleWsDateChange}
            placeholder={['制单日期', '制单日期']}
            className={"width-100-percentage"}/>,
          <SelectDict
            onChange={handleWsCyclePeriodChange}
            className={"width-100-percentage"}
            placeholder={"请选择循环周期"}
            code={'work_schedule_cycle'}
            value={searchReq.wsCyclePeriod}
            allowClear/>,
          <DatePicker.RangePicker
            allowClear
            onChange={handleWsWorkPlanStartDateChange}
            value={searchReq?.wsWorkPlanStartDate1 ? [dayjs(searchReq?.wsWorkPlanStartDate1), dayjs(searchReq?.wsWorkPlanStartDate2)] : [undefined, undefined]}
            placeholder={['计划开始日期', '计划开始日期']}
            className={"width-100-percentage"}/>,
          <DatePicker.RangePicker
            allowClear
            onChange={handleWsWorkPlanCompletionDateChange}
            value={searchReq?.wsWorkPlanCompletionDate1 ? [dayjs(searchReq?.wsWorkPlanCompletionDate1), dayjs(searchReq?.wsWorkPlanCompletionDate2)] : [undefined, undefined]}
            placeholder={['计划完成日期', '计划完成日期']}
            className={"width-100-percentage"}/>,
          <SelectTaskSupervision
            tsLevel={'2'}
            className={"width-100-percentage"}
            onChange={handleWsSecondaryTasksIdChange}
            value={searchReq?.wsSecondaryTasksId}
            placeholder={"请选择关联任务"}
            allowClear/>,
          <SelectPersonCharge
            onChange={handleHandlingRecordsNameChange}
            value={searchReq?.handlingRecords}
            className={"width-100-percentage"}
            placeholder={"请选择办理人员"}
            allowClear/>,
          <SelectPersonCharge
            onChange={handleOrganizersUserIdChange}
            className={"width-100-percentage"}
            placeholder={"请选择主办人员"}
            value={searchReq?.organizersUserId}
            allowClear/>,
          <SelectProject
            updateCount={props.updateCount}
            placeholder={"请选择责任部门"}
            value={searchReq?.responsibilityProject}
            onChange={handleResponsibilityProjectChange}
            allowClear
            fieldNames={{value: 'name', label: 'name'}}/>,
          <SelectCooperativeUnit
            onChange={handleCooperationUnitChange}
            value={searchReq?.cooperationUnit}
            allowClear
            fieldNames={{value: 'cuName', label: 'cuName'}}/>,
          <SelectFiling
            placeholder={"请选择存档批号"}
            allowClear
            onChange={handleWsFileChange}
            value={searchReq?.wsFile}
            className={'width-100-percentage'}
            isHiddenOptions/>,
          <SelectProcessStatus
            onChange={handleStatusChange}
            allowClear
            value={searchReq?.status}
            placeholder={'请选择办理进度'}
            className={"width-100-percentage"}/>,
        ] : []}/>
    </>
  )
}))
