import {observer} from "mobx-react";
import './index.less'
import React, {useEffect, useLayoutEffect, useState} from "react";
import {FormInstance, message, Modal, Space, Table} from "antd";
import {allStores} from "@/stores";
import {ColumnsType} from "antd/es/table";
import {logger, safeCompute, showErr} from "@common/utils";
import {
  APPROVE_FORM_TRANS_STATE,
  BASE_API_PATH,
  BLACK_LIST,
  CLOSE,
  DATE_FMT, DATE_SECONDS_FMT,
  DEFAULT_MODAL_WIDTH,
  DEFAULT_TABLE_SCROLL_HEIGHT,
  FORM_ALTERNATE_TEXT,
  FORM_CLOSE_TEXT,
  FORM_COMMIT_TEXT,
  FORM_HANDOVER_TEXT,
  FORM_PERSON_RESPONSIBLE_WHITELIST,
  FORM_RETURN_TEXT,
  FORM_REVOCATION_TEXT,
  LARGE_MODAL_WIDTH,
  LARGE_MODAL_WIDTH_OTHER,
  READY,
  TABLE_CELL_WIDTH_1_5x,
  TABLE_CELL_WIDTH_1x,
  TABLE_CELL_WIDTH_2x
} from "@/config";
import {BasicInfo} from "@modules/TaskSupervision/Components/BasicInfo";
import {HeaderActionCom} from "@modules/TaskSupervision/Components/HeaderActionCom";
import {TaskSupervisionReq, TaskSupervisionRow} from "@services/dto/taskSupervision";
import {BasicInfo as ProcessRecordsBasicInfo} from "@modules/ProcessRecords/Components/BasicInfo";
import {TaskSupervisionService} from "@services/TaskSupervisionService";
import {headerSearchStore} from "@/layout/Stores/HeaderSearchStore";
import {TableEllipsisText} from "@components/TableEllipsisText";
import {useLocation} from "react-router-dom";
import {
  CloudDownloadOutlined,
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  FieldTimeOutlined,
  PlusOutlined,
  RotateLeftOutlined,
  RotateRightOutlined
} from "@ant-design/icons";
import {TableFilingText} from "@components/TableFilingText";
import {CustomImport} from "@components/CustomImport/CustomImport";
import {ProcessRecordsRow} from "@services/dto/processRecords";
import {ProcessRecordsService} from "@services/ProcessRecordsService";
import {TableAuthOptions} from "src/components/CustomAuthOptions";
import {LabelValue, ResponseEntity} from "@services/dto/common";
import {CustomForm} from "@components/CustomForm";
import {CustomDraggableModal} from "@components/CustomDraggableModal";
import {ProcessRecords} from "@modules/ProcessRecords";
import {WindowPrint} from "@modules/WindowPrint";
import dayjs from "dayjs";

const blackColumnList = [
  'action',
]

const wideShowColumn: string[] = [
  'expanded',
  'tsDate',
  'tsCode',
  'tsDesc',
  'tsTaskPidCode',
  'tsTaskCode',
  // 'tsStartComp',
  // 'tsPlanComp',
  // 'tsPlanProject',
  // 'tsStartDate',
  // 'tsEndDate',
  'tsLevel',
  // 'tsPracticalProject',
  // 'personResponsibleName',
  // 'checkPersonResponsibleName',
  // 'responsibilityProject',
  'cooperationUnit',
  'tsStatus',
  'tsFile',
  'action',
]

const narrowShowColumn: string[] = [
  'expanded',
  'tsCode',
  // 'tsDesc',
  'tsTaskPidCode',
  'tsTaskCode',
  // 'tsStartComp',
  // 'tsPlanComp',
  // 'tsPlanProject',
  // 'tsStartDate',
  'tsLevel',
  // 'tsEndDate',
  // 'tsPracticalProject',
  // 'personResponsibleName',
  // 'checkPersonResponsibleName',
  // 'responsibilityProject',
  // 'cooperationUnit',
  'tsStatus',
  // 'tsFile',
  'action',
]

export interface PropsType {
  isModalSelect?: boolean // 拟态宽
  locationPathname?: string // 路由
  isHiddenOptions?: boolean
  dataSource?: TaskSupervisionRow[] // 数据源
}

export const TableName = 'taskSupervision'

export const TaskSupervision = observer(React.forwardRef<FormInstance, PropsType>((props, _) => {
  const {
    processStore,
    taskSupervisionStore,
    screenResolutionStore,
    tableColumnsStore,
    securityStore
  } = allStores
  const basicInfo = React.createRef<FormInstance>();
  const childrenBasicInfo = React.createRef<FormInstance>();
  const processRecordsBasicInfo = React.createRef<FormInstance>();
  const [dataSource, setDataSource] = useState<TaskSupervisionRow[]>([])
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [total, setTotal] = useState<number>(0)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalChildrenVisible, setIsModalChildrenVisible] = useState(false);
  const [taskSupervisionRow, setTaskSupervisionRow] = useState<TaskSupervisionRow>()
  const [taskSupervisionChildRow, setTaskSupervisionChildRow] = useState<TaskSupervisionRow>()
  const [updateCount, setUpdateCount] = useState<number>(0)
  const [isView, setIsView] = useState<boolean>(false)
  const [scrollHeight, setScrollHeight] = useState<number>(DEFAULT_TABLE_SCROLL_HEIGHT)
  const [renderColumns, setRenderColumns] = useState<ColumnsType<TaskSupervisionRow>>([])
  const location = useLocation()
  const [showColumn, setShowColumn] = useState<string[]>(wideShowColumn)
  const [processRecordsIsOpen, setProcessRecordsIsOpen] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [contentList, setContentList] = useState<LabelValue[]>([]);
  const [processRecordsRow, setProcessRecordsRow] = useState<ProcessRecordsRow>()
  const [processRecordsOpen, setProcessRecordsOpen] = useState<boolean>(false)
  const [processRecordsDataSource, setProcessRecordsDataSource] = useState<ProcessRecordsRow[]>([])
  const [submitRow, setSubmitRow] = useState<TaskSupervisionRow>()
  const [submitterId, setSubmitterId] = useState<string>()
  const [latestProcess, setLatestProcess] = useState<string>()
  const [isPredecessor, setIsPredecessor] = useState<boolean>(false)
  const [searchReq, setSearchReq] = useState<TaskSupervisionReq>({
    pageNum: 1,
    perPage: 10,
    personResponsible: securityStore.getLoggedUser()._id,
    cooperationUnit: headerSearchStore.headerSearchReq.cooperationUnit,
    responsibilityProject: headerSearchStore.headerSearchReq.responsibilityProject,
    ...taskSupervisionStore.taskSupervisionReq
  })
  const [latestProcessBatch, setLatestProcessBatch] = useState<string[]>()
  const [isBatchUpdate, setIsBatchUpdate] = useState(false);

  const columns: ColumnsType<TaskSupervisionRow> = [
    {
      title: <></>,
      dataIndex: 'expanded',
      key: 'expanded',
      align: 'center',
      width: 50
    },
    {
      title: '任务级别',
      dataIndex: 'tsLevel',
      key: 'tsLevel',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space className={'ts-level'}>
          <TableEllipsisText
            value={safeCompute(text) === 1 ? '一级' : (safeCompute(text) === 2 ? '二级' : (safeCompute(text) === 3) ? '三级' : '/')}/>
        </Space>
      )
    },
    {
      title: '制单日期',
      dataIndex: 'tsDate',
      key: 'tsDate',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '制单编号',
      dataIndex: 'tsCode',
      key: 'tsCode',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '工作摘要',
      dataIndex: 'tsDesc',
      key: 'tsDesc',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '关联上级任务',
      dataIndex: 'tsTaskPidCode',
      key: 'tsTaskPidCode',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '关联前置任务',
      dataIndex: 'tsTaskCode',
      key: 'tsTaskCode',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '计划开始日期',
      dataIndex: 'tsStartComp',
      key: 'tsStartComp',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      sorter: (a, b) => dayjs(a.tsStartComp || dayjs()).diff(dayjs(b.tsStartComp || dayjs()), 'day'),
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '计划完成日期',
      dataIndex: 'tsPlanComp',
      key: 'tsPlanComp',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '计划工期',
      dataIndex: 'tsPlanProject',
      key: 'tsPlanProject',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '实际开始日期',
      dataIndex: 'tsStartDate',
      key: 'tsStartDate',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '实际完成日期',
      dataIndex: 'tsEndDate',
      key: 'tsEndDate',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      sorter: (a, b) => dayjs(a.tsEndDate || dayjs()).diff(dayjs(b.tsEndDate || dayjs()), 'day'),
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '实际工期',
      dataIndex: 'tsPracticalProject',
      key: 'tsPracticalProject',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '任务状态',
      dataIndex: 'statusNotice',
      key: 'statusNotice',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '责任部门',
      dataIndex: 'responsibilityProject',
      key: 'responsibilityProject',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '协作单位',
      dataIndex: 'cooperationUnit',
      key: 'cooperationUnit',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '存档批号',
      dataIndex: 'tsFile',
      key: 'tsFile',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (_, record) => (
        <Space>
          <TableFilingText id={record.tsFile} value={record.tsFileCode}></TableFilingText>
        </Space>
      )
    },
    {
      title: '办理人员',
      dataIndex: 'personResponsibleName',
      key: 'personResponsibleName',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '主办人员',
      dataIndex: 'organizersUserName',
      key: 'organizersUserName',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '参与人员',
      dataIndex: 'wnUserNames',
      key: 'wnUserNames',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '办理进度',
      dataIndex: 'tsStatus',
      key: 'tsStatus',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (_, record) => (
        <TableAuthOptions
          mainBtn={['handleEdit', 'handleView']}
          items={[
            {
              key: 'handleView',
              icon: <EllipsisOutlined/>,
              onClick: () => handleView(record)
            },
            {
              key: 'handleEdit',
              icon: <EditOutlined/>,
              disabled: record.tsStatus === CLOSE,
              onClick: async () => await handleEdit(record)
            },
            {
              key: 'handleDeleteBatch',
              icon: <DeleteOutlined/>,
              disabled: record.tsStatus === CLOSE,
              onClick: () => handleDeleteBatch(record)
            },
          ]}/>
      )
    }
  ];

  useEffect(() => {
    // 读取存储
    let showColumn: string[] = tableColumnsStore.getItem(props.locationPathname || location.pathname) || []
    if (showColumn.length) {
      // 检验存储中的列表是否符合规范
      showColumn = showColumn.filter(o => columns.some(column => column.key === o))
      // 无论有无存储都触发change，保存当前显示的字段
      setShowColumn(showColumn)
    } else {
      // 窄屏判断
      if (screenResolutionStore.screenResolution.height < 700 || screenResolutionStore.screenResolution.width < 1400) {
        setShowColumn(narrowShowColumn)
      }
    }

    // 当作为组件在其他页面使用且传了数据时 不请求数据
    if (!props.dataSource) {
      refreshData(searchReq)
    }
  }, [])

  useEffect(() => {
    if (taskSupervisionStore.taskSupervisionDatasource.data) {
      setDataSource(transFormData(taskSupervisionStore.taskSupervisionDatasource.data.items || []));
      setTotal(taskSupervisionStore.taskSupervisionDatasource.data.total || 0)
    }
  }, [taskSupervisionStore.taskSupervisionDatasource.data])

  useEffect(() => {
    const req = {...searchReq}
    if (req.cooperationUnit !== headerSearchStore.headerSearchReq.cooperationUnit || req.responsibilityProject !== headerSearchStore.headerSearchReq.responsibilityProject) {
      req.cooperationUnit = headerSearchStore.headerSearchReq.cooperationUnit
      req.responsibilityProject = headerSearchStore.headerSearchReq.responsibilityProject
      setSearchReq(req)
      refreshData(req)
    }
  }, [headerSearchStore.headerSearchReq])

  useEffect(() => {
    if (props.isModalSelect) {
      setRenderColumns(columns.filter(o => !blackColumnList.includes(o.key as string)).filter(o => showColumn.includes(String(o.key))));
    } else {
      setRenderColumns(columns.filter(o => showColumn.includes(String(o.key))));
    }
  }, [showColumn])

  /**
   * 动态修改表格高度
   */
  useLayoutEffect(() => {
    const othersHeight = 10 + 5 + 5
    const parent = document.querySelector(".tabs-content")
    const headAction = document.querySelector(".header-action-row")
    const thead = document.querySelector(".ant-table-thead")
    const summary = document.querySelector('.ant-table-summary')
    const pagination = document.querySelector('.ant-pagination')
    const paginationHeight = pagination ? pagination.clientHeight + 16 * 2 : 0
    setScrollHeight(safeCompute(parent?.clientHeight) - safeCompute(headAction?.clientHeight) - safeCompute(thead?.clientHeight) - paginationHeight - safeCompute(summary?.clientHeight) - othersHeight)
  }, [screenResolutionStore.screenResolution.height, taskSupervisionStore.collapsed, searchReq.pageNum, searchReq.perPage])

  const transFormData = (row: TaskSupervisionRow[]) => {
    const resRow = JSON.parse(JSON.stringify(row))
    for (let i = 0; i < resRow.length; i++) {
      const item = resRow[i]
      if (item.childs.length) {
        item.childs = transFormData(item.childs)
      } else {
        delete item.childs
      }
    }
    return resRow
  }

  const handleView = (record: TaskSupervisionRow) => {
    setTaskSupervisionRow(record)
    setIsBatchUpdate(false)
    setIsView(true)
    setIsModalVisible(true);
  }

  const handleEdit = async (record: TaskSupervisionRow) => {

    const rsp = await ProcessRecordsService.getProcessRecords({
      businessId: record.id,
      pageNum: 1,
      perPage: 999,
      type: TableName,
    })
    const process = rsp.items.length ? rsp.items[0].process : ''
    const userId = rsp.items.find(o => o.process === process && o.status === FORM_COMMIT_TEXT)?.userId
    const con = rsp.items.some(o => o.process === process && o.status === FORM_REVOCATION_TEXT)
    // 如果本轮有撤回 （id为空） 否则 （发起最新一轮的提交的人的id）
    setSubmitterId(con ? undefined : userId)
    setLatestProcess(process) // 发起最新一轮的标志

    setTaskSupervisionRow(record)
    setIsBatchUpdate(false)
    setIsView(false)
    setIsModalVisible(true);
  }

  const handleDeleteBatch = (record?: TaskSupervisionRow) => {
    if (!record && selectedRowKeys.length === 0) {
      message.warning("请选择要删除的数据！")
      return
    }
    let ids: string[] = []
    if (record) {
      ids.push(record.id)
    } else {
      ids = selectedRowKeys.map(item => String(item))
    }
    Modal.confirm({
      title: "是否删除？",
      okText: "确定",
      cancelText: "取消",
      onOk() {
        setConfirmLoading(true)
        TaskSupervisionService.deleteTaskSupervision(ids).then(() => {
          message.success("删除成功！")
          setConfirmLoading(false)
          refreshData(searchReq)
        }).catch(showErr).finally(() => setConfirmLoading(false))
      },
    })
  }

  const handlePageChange = (page: number, pageSize: number) => {
    let req = {...searchReq};
    req.perPage = pageSize
    req.pageNum = page
    setSearchReq(req)
    refreshData(req)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const refreshData = (req: TaskSupervisionReq) => {
    setSearchReq(req)
    taskSupervisionStore.getTreeTaskSupervision(req)
  }

  const handleChildrenOk = (open: boolean) => {
    const row = {...taskSupervisionChildRow!}
    childrenBasicInfo.current!.validateFields().then(async (values) => {
      setConfirmLoading(true);
      Object.assign(row, values)

      if (open) {

        // 将row和按钮先存起来
        setSubmitRow(row)

        // 弹窗选择办理人
        setProcessRecordsIsOpen(true)
        setProcessRecordsRow(undefined)

      } else {

        const user = securityStore.getLoggedUser()

        // 默认办理人员是自己
        if (!row.id) {
          row.personResponsible = user._id
          row.personResponsibleName = user.name
        }

        try {

          const rsp = await insertOrUpdate(row)

          // 如果是发起前置任务
          if (isPredecessor) {
            if (rsp.data) {
              const row = taskSupervisionStore.taskSupervisionDatasource.data.items.find(o => o.id === taskSupervisionRow.id)
              row.tsTask = (row.tsTask as string || '').split(',')
              row.tsTask.push(rsp.data.id)
              row.tsTask = row.tsTask
                .filter((v, i, self) => self.indexOf(v) === i) // 去重
                .filter(v => !BLACK_LIST.includes(v))
                .join(',')
              let tsTaskCode = row.tsTaskCode.split(',')
              tsTaskCode.push(rsp.data.tsCode)
              row.tsTaskCode = tsTaskCode
                .filter((v, i, self) => self.indexOf(v) === i) // 去重
                .filter(v => !BLACK_LIST.includes(v))
                .join(',')
              actionThen(await TaskSupervisionService.updateTaskSupervision(row))
            } else {
              message.warning('前置任务成功，后续任务关联失败')
            }
          }

        } catch (error) {
          setProcessRecordsIsOpen(false)
          setProcessRecordsRow(undefined)
          setConfirmLoading(false)
          setIsModalVisible(false);
          setTaskSupervisionRow(undefined)
          setIsBatchUpdate(false)
          setIsModalChildrenVisible(false);
          setTaskSupervisionChildRow(undefined)
          message.warning(error.message)
        }

      }
    }).catch(logger.warn)
  }

  const handleOk = (open: boolean) => {
    const row = {...taskSupervisionRow!}
    basicInfo.current!.validateFields().then(async (values) => {
      setConfirmLoading(true);
      Object.assign(row, values)

      if (open) {

        // 将row和按钮先存起来
        setSubmitRow(row)

        // 弹窗选择办理人
        setProcessRecordsIsOpen(true)
        setProcessRecordsRow({
          ...processRecordsRow,
          person: row.organizersUserId,
          personName: row.organizersUserName
        })

      } else {
        const user = securityStore.getLoggedUser()
        if (isBatchUpdate) {
          batchUpdate(row)
        } else {
          // 默认办理人员是自己
          if (!row.id) {
            row.personResponsible = user._id
            row.personResponsibleName = user.name
          }

          try {
            await insertOrUpdate(row)
          } catch (error) {
            setProcessRecordsIsOpen(false)
            setProcessRecordsRow(undefined)
            setConfirmLoading(false)
            setIsModalVisible(false);
            setTaskSupervisionRow(undefined)
            setIsBatchUpdate(false)
            setIsModalChildrenVisible(false);
            setTaskSupervisionChildRow(undefined)
            message.warning(error.message)
          }
        }

      }
    }).catch(logger.warn)
  }

  const batchUpdate = (row: TaskSupervisionRow) => {
    // let flag = false
    const rows = taskSupervisionStore.taskSupervisionDatasource.data?.items.filter(o => selectedRowKeys.includes(o.id))
    for (let selectedRow of rows) {
      // if (selectedRow.faStatus === ONFILE) {
      //   flag = true
      //   continue
      // }

      if (typeof row.tsDate === 'object' && row.tsDate) {
        selectedRow.tsDate = row.tsDate.format(DATE_FMT);
      }
      if (row.tsDesc) {
        selectedRow.tsDesc = row.tsDesc
      }
      if (row.tsLevel) {
        selectedRow.tsLevel = row.tsLevel
      }
      if (row.tsTaskPid) {
        selectedRow.tsTaskPid = row.tsTaskPid
      }
      if (typeof row.tsTask === 'object' && row.tsTask) {
        selectedRow.tsTask = row.tsTask.join(",")
      }
      if (typeof row.wnUserNames === 'object' && row.wnUserNames) {
        selectedRow.wnUserNames = row.wnUserNames.join(",")
      }
      if (row.tsPlanProject) {
        selectedRow.tsPlanProject = row.tsPlanProject
      }
      if (typeof row.tsStartComp === 'object' && row.tsStartComp) {
        selectedRow.tsStartComp = row.tsStartComp.format(DATE_FMT);
      }
      if (typeof row.tsPlanComp === 'object' && row.tsPlanComp) {
        selectedRow.tsPlanComp = row.tsPlanComp.format(DATE_FMT);
      }
      if (typeof row.tsStartDate === 'object' && row.tsStartDate) {
        selectedRow.tsStartDate = row.tsStartDate.format(DATE_FMT);
      }
      if (typeof row.tsEndDate === 'object' && row.tsEndDate) {
        selectedRow.tsEndDate = row.tsEndDate.format(DATE_FMT);
      }
      if (row.tsPracticalProject) {
        selectedRow.tsPracticalProject = row.tsPracticalProject;
      }
      if (row.statusNotice) {
        selectedRow.statusNotice = row.statusNotice;
      }
      if (row.personResponsible) {
        selectedRow.personResponsible = row.personResponsible
        selectedRow.personResponsibleName = row.personResponsibleName
      }
      if (row.responsibilityProject) {
        selectedRow.code = row.code
        selectedRow.responsibilityProject = row.responsibilityProject
      }
      if (row.tsStatus) {
        selectedRow.tsStatus = row.tsStatus
      }
      if (row.cooperationUnit) {
        selectedRow.cooperationUnit = row.cooperationUnit
      }
    }
    // if (flag) {
    //   message.warning(`办理进度为${ONFILE}的数据不可编辑！`).then()
    // }
    TaskSupervisionService.batchUpdateTaskSupervision(rows).then((rsp) => {
      actionThen(rsp);
      setUpdateCount(updateCount + 1)
    }).catch(showErr).finally(() => setConfirmLoading(false));
  }

  const actionThen = (rsp: ResponseEntity<any>) => {
    if (rsp.code === 200) {
      message.success("操作成功！").then()
    } else {
      message.warning(rsp.message).then()
    }
    setIsModalVisible(false);
    setTaskSupervisionRow(undefined)
    setIsBatchUpdate(false)
    setIsModalChildrenVisible(false);
    setTaskSupervisionChildRow(undefined)
    refreshData(searchReq)
    setSelectedRowKeys([])
  }

  const handleTableColumnsChange = (value: string[]) => {
    setShowColumn(['expanded', ...value])
    tableColumnsStore.setItem(location.pathname, value)
  }

  const handleCollapsedChange = (collapsed: boolean) => {
    taskSupervisionStore.setCollapsed(collapsed)
  }

  const handleShowColumnReset = () => {
    // 窄屏判断
    if (screenResolutionStore.screenResolution.height < 700 || screenResolutionStore.screenResolution.width < 1400) {
      setShowColumn(narrowShowColumn)
    } else {
      setShowColumn(wideShowColumn)
    }
  }

  const handleDownloadTemp = () => {
    window.open(BASE_API_PATH + "/filepath/file/gzrw.xlsx")
  }

  /**
   * 操作记录按钮
   */
  const handleProcessRecordsClick = async (record: TaskSupervisionRow) => {
    const rsp = await ProcessRecordsService.getProcessRecords({
      businessId: record.id,
      pageNum: 1,
      perPage: 999,
      type: TableName
    })
    if (!rsp) {
      message.warning('查无数据')
      return
    }
    setProcessRecordsDataSource(rsp.items)
    setProcessRecordsOpen(true)
  }

  const handleSubmit = () => {
    const row = {...processRecordsRow!}
    processRecordsBasicInfo.current!.validateFields().then(async (values) => {
      Object.assign(row, values)

      const user = securityStore.getLoggedUser()
      const btnText = row.status

      // 当类型是撤回时 默认办理人员为提交人(自己)
      if (btnText === FORM_REVOCATION_TEXT) {
        row.personResponsible = user._id || submitterId
        row.personResponsibleName = user.name
      }

      // 主表
      const subRow = {...submitRow}
      subRow.personResponsible = row.personResponsible
      subRow.personResponsibleName = row.personResponsibleName
      subRow.tsStatus = APPROVE_FORM_TRANS_STATE[btnText] || subRow.tsStatus
      // 主表在撤回、退回、变更时->更新局部数据
      if ([FORM_REVOCATION_TEXT, FORM_RETURN_TEXT, FORM_ALTERNATE_TEXT].includes(btnText)) {
        subRow.organizersUserId = subRow.personResponsible
        subRow.organizersUserName = subRow.personResponsibleName
      }
      // 主表在转交、提交时->更新局部数据
      if ([FORM_HANDOVER_TEXT, FORM_COMMIT_TEXT].includes(btnText)) {
        subRow.organizersUserId = subRow.personResponsible
        subRow.organizersUserName = subRow.personResponsibleName
      }
      // 主表在提交时->更新局部数据
      if (btnText === FORM_COMMIT_TEXT) {
        // subRow.tsDate = new Date().toLocaleDateString().replace(/\//g, '-')
      }
      // 主表在结办时->更新局部数据
      if (btnText === FORM_CLOSE_TEXT) {
        subRow.tsEndDate = new Date().toLocaleDateString().replace(/\//g, '-')
        // 实际工期
        if (subRow.tsStartDate) {
          subRow.tsPracticalProject = String(dayjs(subRow.tsEndDate).diff(dayjs(subRow.tsStartDate), 'day') + 1)
        }
      }

      // 办理记录表
      row.type = TableName
      row.businessId = subRow.id // 有可能会没值
      row.status = btnText
      row.userId = user._id
      row.userName = user.name
      row.personResponsible = undefined
      row.personResponsibleName = undefined
      if (btnText === FORM_COMMIT_TEXT) {
        const date = new Date()
        row.process = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
      } else {
        row.process = latestProcess
      }
      if (FORM_PERSON_RESPONSIBLE_WHITELIST.includes(btnText)) {
        row.conca = `${user.name}${btnText}给${subRow.personResponsibleName}。`
      } else {
        row.conca = `${user.name}${btnText}了。`
      }

      // 判断是否 先新增主表 后新增记录和通知表
      if (subRow.id) {

        let firstOperationSucceeded = false;
        try {
          await insertOrUpdate(subRow);
          firstOperationSucceeded = true;
        } catch (error) {
          setProcessRecordsIsOpen(false)
          setProcessRecordsRow(undefined)
          setConfirmLoading(false)
          setIsModalVisible(false);
          setTaskSupervisionRow(undefined)
          setIsBatchUpdate(false)
          setIsModalChildrenVisible(false);
          setTaskSupervisionChildRow(undefined)
          message.warning(error.message)
        }
        if (!firstOperationSucceeded) return
        await ProcessRecordsService.addProcessRecords(row)
        const rsp = await ProcessRecordsService.getProcessRecords({
          businessId: subRow.id,
          pageNum: 1,
          perPage: 999,
          type: TableName,
        })
        const notificationScopeUserId = rsp.items
          .map(o => o.userId)
          .concat(row.person)
          .filter((v, i, self) => self.indexOf(v) === i) // 去重
          .filter(id => id !== user._id) // 过滤出不是自己的数据
          .join(',') || ''
        const notificationScopeUserName = rsp.items
          .map(o => o.userName)
          .concat((row.personName || '').split(','))
          .filter((v, i, self) => self.indexOf(v) === i) // 去重
          .filter(name => name !== user.name) // 过滤出不是自己的数据
          .join(',') || ''
        await processStore.newAssignmentNotice({
          businessId: subRow.id,
          businessCode: subRow.tsCode,
          cooperationUnit: subRow.cooperationUnit || 'N/A',
          notificationScopeUserId,
          notificationScopeUserName,
          personResponsible: subRow.personResponsible,
          personResponsibleName: subRow.personResponsibleName,
          responsibilityProject: subRow.responsibilityProject,
          type: "工作任务",
          wnDesc: `您参与办理的工作任务（${subRow.tsCode}），${row.conca} 请知悉！`,
          wnStatus: "未读"
        })

      } else {

        let subRsp: ResponseEntity<TaskSupervisionRow>
        try {
          subRsp = await insertOrUpdate(subRow)
        } catch (error) {
          setProcessRecordsIsOpen(false)
          setProcessRecordsRow(undefined)
          setConfirmLoading(false)
          setIsModalVisible(false);
          setTaskSupervisionRow(undefined)
          setIsBatchUpdate(false)
          setIsModalChildrenVisible(false);
          setTaskSupervisionChildRow(undefined)
          message.warning(error.message)
        }

        if (subRsp.data) {

          // 如果发起前置任务 - 重新填充关联前置任务后再更新
          if (isModalChildrenVisible && isPredecessor) {
            const row = taskSupervisionStore.taskSupervisionDatasource.data.items.find(o => o.id === taskSupervisionRow.id)
            row.tsTask = (row.tsTask as string || '').split(',')
            row.tsTask.push(subRsp.data.id)
            row.tsTask = row.tsTask
              .filter((v, i, self) => self.indexOf(v) === i) // 去重
              .filter(v => !BLACK_LIST.includes(v))
              .join(',')
            let tsTaskCode = row.tsTaskCode.split(',')
            tsTaskCode.push(subRsp.data.tsCode)
            row.tsTaskCode = tsTaskCode
              .filter((v, i, self) => self.indexOf(v) === i) // 去重
              .filter(v => !BLACK_LIST.includes(v))
              .join(',')
            actionThen(await TaskSupervisionService.updateTaskSupervision(row))
          }

          // 重新赋值再提交
          row.businessId = subRsp.data.id
          await ProcessRecordsService.addProcessRecords(row)
          const rsp = await ProcessRecordsService.getProcessRecords({
            businessId: subRsp.data.id,
            pageNum: 1,
            perPage: 999,
            type: TableName,
          })
          const notificationScopeUserId = rsp.items
            .map(o => o.userId)
            .concat(row.person)
            .filter((v, i, self) => self.indexOf(v) === i) // 去重
            .filter(id => id !== user._id) // 过滤出不是自己的数据
            .join(',') || ''
          const notificationScopeUserName = rsp.items
            .map(o => o.userName)
            .concat((row.personName || '').split(','))
            .filter((v, i, self) => self.indexOf(v) === i) // 去重
            .filter(name => name !== user.name) // 过滤出不是自己的数据
            .join(',') || ''
          await processStore.newAssignmentNotice({
            businessId: subRsp.data.id,
            businessCode: subRsp.data.tsCode,
            cooperationUnit: subRsp.data.cooperationUnit || 'N/A',
            notificationScopeUserId,
            notificationScopeUserName,
            personResponsible: subRsp.data.personResponsible,
            personResponsibleName: subRsp.data.personResponsibleName,
            responsibilityProject: subRsp.data.responsibilityProject,
            type: "工作任务",
            wnDesc: `您参与办理的工作任务（${subRsp.data.tsCode}），${row.conca} 请知悉！`,
            wnStatus: "未读"
          })
        } else {
          message.warning('主表保存成功，办理记录表和任务通知表提交失败')
        }

      }
      setProcessRecordsIsOpen(false)
      setProcessRecordsRow(undefined)
    }).catch(logger.warn)
  }

  const insertOrUpdate = async (row: TaskSupervisionRow) => {
    if (typeof row.tsDate === 'object' && row.tsDate) {
      row.tsDate = row.tsDate.format(DATE_FMT);
    }
    if (typeof row.tsStartComp === 'object' && row.tsStartComp) {
      row.tsStartComp = row.tsStartComp.format(DATE_FMT);
    }
    if (typeof row.tsPlanComp === 'object' && row.tsPlanComp) {
      row.tsPlanComp = row.tsPlanComp.format(DATE_FMT);
    }
    if (typeof row.tsStartDate === 'object' && row.tsStartDate) {
      row.tsStartDate = row.tsStartDate.format(DATE_FMT);
    }
    if (typeof row.tsEndDate === 'object' && row.tsEndDate) {
      row.tsEndDate = row.tsEndDate.format(DATE_FMT);
    }
    if (typeof row.tsTask === 'object' && row.tsTask) {
      row.tsTask = row.tsTask.join(",")
    }
    if (typeof row.wnUserNames === 'object' && row.wnUserNames) {
      row.wnUserNames = row.wnUserNames.join(",")
    }
    if (row.id) {
      const rsp = await TaskSupervisionService.updateTaskSupervision(row)
      actionThen(rsp)
      setUpdateCount(updateCount + 1)
      setConfirmLoading(false)
      return rsp
    } else {
      const rsp = await TaskSupervisionService.addTaskSupervision(row)
      actionThen(rsp)
      setUpdateCount(updateCount + 1)
      setConfirmLoading(false)
      return rsp
    }
  }

  // 发起下级任务
  const handleInitiateSubordinateTaskClick = (record: TaskSupervisionRow) => {
    const user = securityStore.getLoggedUser()
    setTaskSupervisionChildRow({
      ...record,
      id: undefined,
      tsLevel: String(safeCompute(record.tsLevel) + 1),
      tsCode: undefined,
      tsFile: undefined,
      tsTask: 'N/A',
      tsDate: dayjs(),
      tsTaskPid: record.id,
      tsStatus: READY,
      personResponsible: user._id,
      personResponsibleName: user.name,
      organizersUserId: user._id,
      organizersUserName: user.name
    })
    setIsModalChildrenVisible(true);
    setIsPredecessor(false);
  }

  // 发起后续任务
  const handleInitiateFollowUpTaskClick = (record: TaskSupervisionRow) => {
    const user = securityStore.getLoggedUser()
    setTaskSupervisionChildRow({
      ...record,
      id: undefined,
      tsCode: undefined,
      tsFile: undefined,
      tsTask: record.id,
      tsDate: dayjs(),
      tsStatus: READY,
      personResponsible: user._id,
      personResponsibleName: user.name,
      organizersUserId: user._id,
      organizersUserName: user.name
    })
    setIsModalChildrenVisible(true);
    setIsPredecessor(false);
  }

  // 发起前置任务
  const handleInitiatePredecessorTaskClick = (record: TaskSupervisionRow) => {
    const user = securityStore.getLoggedUser()
    setTaskSupervisionChildRow({
      ...record,
      id: undefined,
      tsCode: undefined,
      tsFile: undefined,
      tsTask: undefined,
      tsDate: dayjs(),
      tsStatus: READY,
      personResponsible: user._id,
      personResponsibleName: user.name,
      organizersUserId: user._id,
      organizersUserName: user.name
    })
    setIsModalChildrenVisible(true);
    setIsPredecessor(true);
  }

  const handlePrint = async (record: TaskSupervisionRow) => {
    const list = await taskSupervisionStore.getPrintList(record)
    if (list) {
      setContentList(list)
      setIsModalOpen(true)
    } else {
      message.warning('请检查')
    }
  }

  const handleAdd = (row?: TaskSupervisionRow) => {
    const user = securityStore.getLoggedUser()
    const initRow = {
      tsStatus: READY,
      personResponsible: user._id,
      personResponsibleName: user.name,
      tsDate: dayjs(),
    } as TaskSupervisionRow
    Object.assign(row || {}, initRow)
    setTaskSupervisionRow(row || initRow)
    setIsView(false)
    setIsBatchUpdate(false)
    setIsModalVisible(true)
  }

  const handleTableChange = (pagination, filters, sorter, extra) => {
    logger.log('params', pagination, filters, sorter, extra);
  };

  const handleEditBatch = async () => {

    try {

      if (selectedRowKeys.length === 0) {
        message.warning("请选择要操作的数据！").then()
        return
      }

      // 要操作的数据
      const rows = taskSupervisionStore.taskSupervisionDatasource.data?.items.filter(o => selectedRowKeys.includes(o.id))

      // 办理流程辅助字段：办理进度
      let tsStatus: string

      // 判断是否符合批量操作条件
      const con = rows.every((o, _, arr) => {
        const firstRow = arr[0]
        tsStatus = firstRow.tsStatus
        if (o.personResponsible !== firstRow.personResponsible) {
          message.warning("办理人员不一致！").then()
          return false
        } else if (o.tsStatus !== firstRow.tsStatus) {
          message.warning("办理进度不一致！").then()
          return false
        } else return true
      })

      if (con) {

        let latestProcessList: string[] = []
        let submitterIdList: string[] = []
        for (let i = 0; i < rows.length; i++) {
          const item = rows[i]
          const rsp = await ProcessRecordsService.getProcessRecords({
            businessId: item.id,
            pageNum: 1,
            perPage: 999,
            type: TableName,
          })
          const process = rsp.items.length ? rsp.items[0].process : ''
          const userId = rsp.items.find(o => o.process === process && o.status === FORM_COMMIT_TEXT)?.userId
          const userIdCon = rsp.items.some(o => o.process === process && o.status === FORM_REVOCATION_TEXT)
          latestProcessList.push(process)
          submitterIdList.push(userIdCon ? undefined : userId)
        }

        // 如果提交人全部一致才赋值给参数:【提交人】
        setSubmitterId(new Set(submitterIdList).size === 1 ? submitterIdList[0] : undefined)
        setLatestProcessBatch(latestProcessList)
        setIsView(false)
        setIsBatchUpdate(true)
        setTaskSupervisionRow({tsStatus} as TaskSupervisionRow)
        setIsModalVisible(true)

      }

    } catch (err) {
      message.warning("办理记录查询失败！").then()
      logger.log(err)
    }

  }

  const handleSubmitBatch = () => {
    const row = {...processRecordsRow!}
    processRecordsBasicInfo.current!.validateFields().then(async (values) => {
      Object.assign(row, values)
      const rows = taskSupervisionStore.taskSupervisionDatasource.data?.items.filter(o => selectedRowKeys.includes(o.id))

      for (let i = 0; i < rows.length; i++) {

        const user = securityStore.getLoggedUser()
        const btnText = row.status

        // 当类型是撤回时 默认办理人员为提交人(自己)
        if (btnText === FORM_REVOCATION_TEXT) {
          row.personResponsible = user._id || submitterId
          row.personResponsibleName = user.name
        }

        // 主表
        const subRow = {...rows[i]}
        subRow.personResponsible = row.personResponsible
        subRow.personResponsibleName = row.personResponsibleName
        subRow.tsStatus = APPROVE_FORM_TRANS_STATE[btnText] || subRow.tsStatus
        // 主表在撤回、退回、变更时->更新局部数据
        if ([FORM_REVOCATION_TEXT, FORM_RETURN_TEXT, FORM_ALTERNATE_TEXT].includes(btnText)) {
          subRow.organizersUserId = subRow.personResponsible
          subRow.organizersUserName = subRow.personResponsibleName
        }
        // 主表在转交、提交时->更新局部数据
        if ([FORM_HANDOVER_TEXT, FORM_COMMIT_TEXT].includes(btnText)) {
          subRow.organizersUserId = subRow.personResponsible
          subRow.organizersUserName = subRow.personResponsibleName
        }
        // 主表在提交时->更新局部数据
        if (btnText === FORM_COMMIT_TEXT) {
          subRow.tsDate = new Date().toLocaleDateString().replace(/\//g, '-')
        }
        // 主表在结办时->更新局部数据
        if (btnText === FORM_CLOSE_TEXT) {

        }

        // 办理记录表
        row.type = TableName
        row.businessId = subRow.id // 有可能会没值
        row.status = btnText
        row.userId = user._id
        row.userName = user.name
        row.personResponsible = undefined
        row.personResponsibleName = undefined
        if (btnText === FORM_COMMIT_TEXT) {
          const date = new Date()
          row.process = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
        } else {
          row.process = latestProcessBatch[i]
        }
        if (FORM_PERSON_RESPONSIBLE_WHITELIST.includes(btnText)) {
          row.conca = `${user.name}${btnText}给${subRow.personResponsibleName}。`
        } else {
          row.conca = `${user.name}${btnText}了。`
        }

        let firstOperationSucceeded = false;
        try {
          await insertOrUpdate(subRow);
          firstOperationSucceeded = true;
        } catch (error) {
          setProcessRecordsIsOpen(false)
          setProcessRecordsRow(undefined)
          setConfirmLoading(false)
          setIsModalVisible(false);
          message.warning(error.message)
        }
        if (!firstOperationSucceeded) return
        await ProcessRecordsService.addProcessRecords(row)
        const rsp = await ProcessRecordsService.getProcessRecords({
          businessId: subRow.id,
          pageNum: 1,
          perPage: 999,
          type: TableName,
        })
        const notificationScopeUserId = rsp.items
          .map(o => o.userId)
          .concat(row.person)
          .filter((v, i, self) => self.indexOf(v) === i) // 去重
          .filter(id => id !== user._id) // 过滤出不是自己的数据
          .join(',') || ''
        const notificationScopeUserName = rsp.items
          .map(o => o.userName)
          .concat((row.personName || '').split(','))
          .filter((v, i, self) => self.indexOf(v) === i) // 去重
          .filter(name => name !== user.name) // 过滤出不是自己的数据
          .join(',') || ''
        await processStore.newAssignmentNotice({
          businessId: subRow.id,
          businessCode: subRow.tsCode,
          cooperationUnit: subRow.cooperationUnit[0] || 'N/A',
          notificationScopeUserId,
          notificationScopeUserName,
          personResponsible: subRow.personResponsible,
          personResponsibleName: subRow.personResponsibleName,
          responsibilityProject: subRow.responsibilityProject,
          type: "工作任务",
          wnDesc: `您参与办理的工作任务（${subRow.tsCode}），${row.conca} 请知悉！`,
          wnStatus: "未读"
        })

      }

      setProcessRecordsIsOpen(false)
      setProcessRecordsRow(undefined)
    }).catch(logger.warn)
  }

  return (
    <div className={"institution"}>
      <HeaderActionCom
        size={props.isModalSelect ? 6 : undefined}
        locationPathname={props.locationPathname}
        updateCount={updateCount}
        setCollapsed={handleCollapsedChange}
        searchReq={searchReq}
        handleShowColumnReset={handleShowColumnReset}
        handleTableColumnsChange={handleTableColumnsChange}
        showColumn={showColumn}
        collapsed={taskSupervisionStore.collapsed}
        items={[
          {
            key: 'handleAdd',
            icon: <PlusOutlined/>,
            onClick: () => handleAdd()
          },
          {
            key: 'handleDeleteBatch',
            icon: <DeleteOutlined/>,
            onClick: () => handleDeleteBatch()
          },
          {
            key: 'handleDownloadTemp',
            icon: <CloudDownloadOutlined/>,
            onClick: () => handleDownloadTemp()
          },
          {
            key: 'handleImport',
            node: <CustomImport
              url={'/v1/taskSupervision/import'}
              callback={() => refreshData(taskSupervisionStore.taskSupervisionReq)}></CustomImport>
          },
          {
            key: 'handleEditBatch',
            icon: <EditOutlined/>,
            onClick: () => handleEditBatch()
          },
        ]}
        tableColumns={props.isModalSelect ? columns.filter(o => !blackColumnList.includes(o.key as string)) : columns.filter(o=>o.key !== 'expanded')}
        refreshData={props.dataSource ? undefined : (req) => refreshData({...searchReq, ...req})}/>

      <Table
        className={'margin-top-xss'}
        rowKey={"id"}
        dataSource={dataSource}
        columns={renderColumns}
        size={"middle"}
        loading={taskSupervisionStore.taskSupervisionDatasource.loading || confirmLoading}
        bordered
        onChange={handleTableChange}
        scroll={{y: scrollHeight, scrollToFirstRowOnChange: true}}
        pagination={{
          total: total,
          position: ['bottomCenter'],
          pageSize: searchReq.perPage,
          current: searchReq.pageNum,
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: total => `共${total}条`,
          onChange: handlePageChange
        }}
        rowSelection={{selectedRowKeys, onChange: setSelectedRowKeys, columnWidth: 50}}
        expandable={{
          expandedRowClassName: (_, __, indent) => `indent${indent}`,
          childrenColumnName: 'childs',
          indentSize: 0,
        }}/>

      <CustomForm
        title={'工作任务'}
        handleCancel={handleCancel}
        handleOk={handleOk}
        isModalVisible={isModalVisible}
        submitterId={submitterId}
        zIndex={1000}
        confirmLoading={confirmLoading}
        userId={taskSupervisionRow?.personResponsible}
        extraDisabled={!taskSupervisionRow?.id}
        extraOnClick={() => handlePrint(taskSupervisionRow)}
        status={taskSupervisionRow?.tsStatus}
        push={{distance: DEFAULT_MODAL_WIDTH}}
        item={[
          {
            key: 'initiateSubordinateTask',
            icon: <RotateRightOutlined/>,
            disabled: !taskSupervisionRow?.id || taskSupervisionRow.tsLevel === '3',
            onClick: () => handleInitiateSubordinateTaskClick(taskSupervisionRow)
          },
          {
            key: 'initiateFollowUpTask',
            icon: <RotateLeftOutlined/>,
            disabled: !taskSupervisionRow?.id,
            onClick: () => handleInitiateFollowUpTaskClick(taskSupervisionRow)
          },
          {
            key: 'initiatePredecessorTask',
            icon: <RotateLeftOutlined/>,
            disabled: !taskSupervisionRow?.id || taskSupervisionRow.tsLevel === '3',
            onClick: () => handleInitiatePredecessorTaskClick(taskSupervisionRow)
          },
          {
            key: 'handleProcessRecordsClick',
            icon: <FieldTimeOutlined/>,
            disabled: !taskSupervisionRow?.id,
            onClick: () => handleProcessRecordsClick(taskSupervisionRow)
          },
        ]}>
        <BasicInfo row={{...taskSupervisionRow!}} ref={basicInfo} isView={isView} isBatchUpdate={isBatchUpdate}/>
        <CustomForm
          title={'工作任务-副表'}
          handleCancel={() => setIsModalChildrenVisible(false)}
          handleOk={handleChildrenOk}
          isModalVisible={isModalChildrenVisible}
          confirmLoading={confirmLoading}
          userId={taskSupervisionChildRow?.personResponsible}
          extraDisabled={!taskSupervisionChildRow?.id}
          extraOnClick={() => handlePrint(taskSupervisionChildRow)}
          status={taskSupervisionChildRow?.tsStatus}
          item={[]}>
          <BasicInfo row={{...taskSupervisionChildRow!}} ref={childrenBasicInfo}/>
        </CustomForm>
      </CustomForm>

      { /*操作记录表*/ }
      <CustomDraggableModal
        title={<div className="text-align-center">办理记录</div>}
        open={processRecordsOpen}
        width={LARGE_MODAL_WIDTH_OTHER}
        onCancel={() => setProcessRecordsOpen(false)}
        destroyOnClose
        zIndex={1200}
        footer={null}>
        <ProcessRecords
          isHiddenOptions
          dataSource={processRecordsDataSource}
          isModalSelect
          locationPathname={'/processRecords'}/>
      </CustomDraggableModal>

      { /*操作记录表单*/ }
      <CustomDraggableModal
        title={<div className="text-align-center">办理记录</div>}
        open={processRecordsIsOpen}
        width={LARGE_MODAL_WIDTH}
        destroyOnClose
        zIndex={1100}
        onCancel={() => {
          setProcessRecordsIsOpen(false)
          setConfirmLoading(false)
        }}
        onOk={isBatchUpdate ? handleSubmitBatch : handleSubmit}>
        <ProcessRecordsBasicInfo
          tableName={TableName}
          businessId={isModalChildrenVisible ? taskSupervisionChildRow?.id : taskSupervisionRow?.id}
          submitterId={submitterId}
          userId={isModalChildrenVisible ? taskSupervisionChildRow?.personResponsible : taskSupervisionRow?.personResponsible}
          status={isModalChildrenVisible ? taskSupervisionChildRow?.tsStatus : taskSupervisionRow?.tsStatus}
          row={processRecordsRow}
          ref={processRecordsBasicInfo}/>
      </CustomDraggableModal>

      <WindowPrint
        isHiddenTime
        title={"工作任务"}
        contentList={contentList}
        isModalOpen={isModalOpen}
        handleCancel={() => setIsModalOpen(false)}/>
    </div>
  )
}))
