import React, {useEffect, useState} from "react";
import {Col, DatePicker, Form, Input, InputNumber, Row} from "antd";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import dayjs from "dayjs";
import {SelectPersonCharge} from "@components/SelectPersonCharge";
import {SelectProject} from "@components/SelectProject";
import {SelectCooperativeUnit} from "@components/SelectCooperativeUnit";
import {allStores} from "@/stores";
import {ProjectRow} from "@services/dto/project";
import {SelectFiling} from "@components/SelectFiling";
import {FORM_DISABLED_STATE} from "@/config";
import {SelectProcessStatus} from "@components/SelectProcessStatus";
import {BudgetManagementRow} from "@services/dto/budgetManagement";
import {SelectBudgetManagement} from "@components/SelectBudgetManagement";
import {SelectDict} from "@components/SelectDict";
import {SelectProjectStructure} from "@components/SelectProjectStructure";
import {ThreeSelect} from "@modules/BudgetManagement/Components/ThreeSelect";

export interface PropsType {
  row: BudgetManagementRow
  isView?: boolean
}

export const BasicInfo = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, ref) => {
  const [form] = Form.useForm()
  const {filingStore} = allStores
  const [responsibilityProject, setResponsibilityProject] = useState<string>()
  const [level, setLevel] = useState<string>()
  const [cooperationUnit, setCooperationUnit] = useState<string | string[]>()

  useEffect(() => {
    form.resetFields()
    const budgetManagementRow = {...props.row}
    filingStore.setFilingFaCode(budgetManagementRow.bmCode)
    if (typeof budgetManagementRow.bmDate === 'string' && budgetManagementRow.bmDate !== '') {
      budgetManagementRow.bmDate = dayjs(budgetManagementRow.bmDate)
    }
    if (typeof budgetManagementRow.cooperationUnit === 'string') {
      budgetManagementRow.cooperationUnit = budgetManagementRow.cooperationUnit !== '' ? budgetManagementRow.cooperationUnit.split(',') : []
    }
    setResponsibilityProject(budgetManagementRow.responsibilityProject);
    setCooperationUnit(budgetManagementRow.cooperationUnit)
    form.setFieldsValue(budgetManagementRow);
  }, [props.row.id])

  /**
   * @desc 责任部门修改 code拿到并赋值
   * @param value
   * @param options
   */
  const handleProjectChange = (value: any, options: ProjectRow[]) => {
    setResponsibilityProject(value)
    const row = options.find(o => value === o.name)
    form.setFieldsValue({code: row?.code})
  }

  const handleLevelChange = (value) => {
    setLevel(value)
    form.setFieldsValue({bmType: undefined})
  }

  return (
    <Form
      name="basic"
      ref={ref}
      form={form}
      labelCol={{span: 6}}
      wrapperCol={{span: 16}}
      initialValues={{
        cooperationUnit: ['N/A'],
      }}
      disabled={props.isView || FORM_DISABLED_STATE.includes(props.row.bmStatus)}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Form.Item hidden name="id">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="code">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="personResponsibleName">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="organizersUserName">
          <Input/>
        </Form.Item>
        <Col span={24}>
          <Form.Item
            label="制单日期"
            name="bmDate"
            rules={[{required: false, message: '请选择制单日期!'}]}>
            <DatePicker disabled placeholder={"请选择制单日期"} className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="制单编号"
            name="bmCode"
            rules={[{required: false, message: '请输入制单编号!'}]}>
            <Input disabled placeholder="请输入制单编号"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="预算级别"
            name="bmLevel"
            rules={[{required: false, message: '请输入预算级别!'}]}>
            <SelectDict
              className={'width-100-percentage'}
              allowClear
              code={'budget_level'}
              onChange={handleLevelChange}
              placeholder={'请输入预算级别'}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="预算类别"
            name="bmType"
            rules={[{required: false, message: '请输入预算类别!'}]}>
            <ThreeSelect level={level} allowClear code={'dict_budget_category'} placeholder={'请输入预算类别'} className={'width-100-percentage'}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="预算摘要"
            name="bmSummary"
            rules={[{required: false, message: '请输入预算摘要!'}]}>
            <Input.TextArea placeholder="请输入预算摘要" autoSize={{minRows: 3, maxRows: 9}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="预算金额"
            name="bmAmount"
            rules={[{ required: false, message: '请输入预算金额!' }]}>
            <InputNumber min={0} precision={2} placeholder="请输入预算金额" className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="预算余额"
            name="bmBudgetBalance"
            rules={[{ required: false, message: '请选择预算余额!' }]}>
            <InputNumber min={0} precision={2} placeholder="请输入预算金额" className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="决算金额"
            name="bmFinalAccountsBudgetBalance"
            rules={[{ required: false, message: '请输入决算金额!' }]}>
            <InputNumber min={0} precision={2} placeholder="请输入决算金额" className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="关联上级预算"
            name="parentId"
            rules={[{ required: false, message: '请选择关联上级预算!' }]}>
            <SelectBudgetManagement placeholder={'请选择关联上级预算'} allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="关联项目审批"
            name="psBudget"
            rules={[{ required: false, message: '请选择关联项目审批!' }]}>
            <SelectProjectStructure placeholder={'请选择关联项目审批'} allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="责任部门"
            name="responsibilityProject"
            rules={[{required: false, message: '请选择责任部门!'}]}>
            <SelectProject
              placeholder={"请选择责任部门"} allowClear onChange={handleProjectChange}
              fieldNames={{value: 'name', label: 'name'}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="协作单位"
            name="cooperationUnit"
            rules={[{required: false, message: '请输入协作单位!'}]}>
            <SelectCooperativeUnit
              allowClear
              mode={'tags'}
              onChange={(value) => setCooperationUnit(value)}
              fieldNames={{value: 'cuName', label: 'cuName'}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="存档批号"
            name="bmFile"
            rules={[{required: false, message: '请选择存档批号!'}]}>
            <SelectFiling
              isHidePlus
              isHideClose
              responsibilityProject={responsibilityProject}
              cooperationUnit={cooperationUnit}
              placeholder={"请选择存档批号"}
              inputDisabled
              fieldNames={{value: "id", label: "faBatchNumber"}}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="主办人员"
            name="organizersUserId"
            rules={[{required: false, message: '请选择主办人员!'}]}>
            <SelectPersonCharge
              className={"width-100-percentage"}
              placeholder={"请选择主办人员"}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="办理人员"
            name="personResponsible"
            rules={[{required: false, message: '请选择办理人员!'}]}>
            <SelectPersonCharge
              disabled
              className={"width-100-percentage"}
              placeholder={"请选择办理人员"}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="参与人员"
            name="wnUserNames"
            rules={[{required: false, message: '请输入参与人员!'}]}>
            <Input placeholder="请输入参与人员" disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="办理进度"
            name="bmStatus"
            rules={[{required: false, message: '请选择办理进度',}]}>
            <SelectProcessStatus
              allowClear
              placeholder={"请选择办理进度"}
              disabled/>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}))
