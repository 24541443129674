// 生产环境：	hr-api.gxyq.top   测试环境：	hr-api-dev.gxyq.top  本地测试： http://172.20.10.110:8000/serve
// export const BASE_API_PATH = 'http://192.168.3.108:3002'
export const BASE_API_PATH = 'https://imis.gxyq.top/serve'

// 生产环境：	hr.gxyq.top   测试环境：	hr-dev.gxyq.top
export const ACCESS_PATH = 'http://hr-dev.gxyq.top'
export const STATUS_401 = '401'

// 基本配置
export const VERSION_NUMBER = "2.0.2"
export const APP_TITLE = "数字化集成管理信息系统"
export const LOGIN_USER_KEY = 'LOGIN_USER_KEY'
export const COPY_RIGHT = "Copyright 2023-02-09 国兴亦青 (永州) 实业有限公司"
export const BASE64_URL = 'data:image/jpegbase64,'

// geocode 配置
export const MAP_WEB_SERVICE_KEY = '5f3969987593d7cfa50f2085803f59ab'
export const MAP_WEB_KEY = '3099a3e886f8876f6fc767e4086faacc'

// 状态字典
export const WorkerRecruitmentStatus = {
  pending: 'pending', // 工人申请了
  recruiterConfirmed: 'recruiterConfirmed', // 招聘经理已确认
  hrConfirmed: 'hrConfirmed', // 人事已确认
  pmConfirmed: 'pmConfirmed', // 项目经理已确认
  hired: 'hired', // 入职已结束
  exited: 'exited' // 已离职
}

// 日期格式
export const DATE_FMT = 'YYYY-MM-DD'
export const DATE_MONTH_FMT = 'YYYY-MM'
export const DATE_SECONDS_FMT = "YYYY-MM-DD HH:mm:ss"
export const DATE_MINUTE_FMT = "YYYY-MM-DD HH:mm"

// 数据的黑名单
export const BLACK_LIST = ['', '空白', 'N/A', '容缺']

// 通用状态值
export const READY = '准备中'
export const PROCESS = '办理中'
export const ONFILE = '已存档'
export const CLOSE = '已办结'
export const END = '已终止'

// 表单底部按钮文本
export const FORM_HANDLE_TEXT = '办理'
export const FORM_SAVE_TEXT = '保存'
export const FORM_CANCEL_TEXT = '取消'

// 表单按钮文本显示的状态时机
export const FORM_HANDLE_TEXT_STATE = [READY, PROCESS, CLOSE, ONFILE]
export const FORM_HANDLE_SUBMIT_TEXT_STATE = [PROCESS]
export const FORM_SAVE_TEXT_STATE = [READY, PROCESS]

// 表单内禁用时机
export const FORM_DISABLED_STATE = [/*PROCESS, CLOSE, ONFILE,*/ END]

// 流程发起前提
export const FORM_INITIATE_STATE = [CLOSE, ONFILE]

// 流程表操作类型
export const FORM_COMMIT_TEXT = '提交'
export const FORM_REVOCATION_TEXT = '撤回'
export const FORM_AGREE_TEXT = '同意'
export const FORM_RETURN_TEXT = '退回'
export const FORM_HANDOVER_TEXT = '转交'
export const FORM_CLOSE_TEXT = '办结'
export const FORM_ALTERNATE_TEXT = '变更'
export const FORM_END_TEXT = '终止'

// 最高角色权限
export const SUPER_ROLE = ['管理员', '老板']

// 按钮点击会触发【经办记录表单】弹窗，又要选择办理人员
export const FORM_PERSON_RESPONSIBLE_WHITELIST = [
  FORM_COMMIT_TEXT,
  FORM_AGREE_TEXT,
  FORM_RETURN_TEXT,
  FORM_HANDOVER_TEXT,
]

// 不同类型在不同状态下的可点击状态(不禁选)
export const APPROVE_FORM_DISABLED_STATE = {
  [FORM_COMMIT_TEXT]: [READY],
  [FORM_REVOCATION_TEXT]: [PROCESS],
  [FORM_AGREE_TEXT]: [PROCESS],
  [FORM_RETURN_TEXT]: [PROCESS],
  [FORM_HANDOVER_TEXT]: [READY, PROCESS],
  [FORM_CLOSE_TEXT]: [PROCESS],
  [FORM_ALTERNATE_TEXT]: [CLOSE, ONFILE],
  [FORM_END_TEXT]: [ONFILE],
}

// 选择对应类型修改为对应的状态
export const APPROVE_FORM_TRANS_STATE = {
  [FORM_COMMIT_TEXT]: PROCESS,
  [FORM_REVOCATION_TEXT]: READY,
  [FORM_AGREE_TEXT]: PROCESS,
  [FORM_RETURN_TEXT]: READY,
  [FORM_HANDOVER_TEXT]: undefined,
  [FORM_CLOSE_TEXT]: CLOSE,
  [FORM_ALTERNATE_TEXT]: READY,
  [FORM_END_TEXT]: END,
}

// 表格行内右侧按钮文本
export const ADD_TEXT = '新增'
export const EDIT_TEXT = '修改'
export const VIEW_TEXT = '查看'
export const DEL_TEXT = '删除'

// 表格配置 固定表头后表体的高度 超过高度则滚动
export const DEFAULT_TABLE_SCROLL_HEIGHT = 50 * 10 + 10
export const SMALL_TABLE_SCROLL_HEIGHT = 36 * 10 + 10

// 表格的弹窗宽度
export const DEFAULT_MODAL_WIDTH = 600
export const LARGE_MODAL_WIDTH = 800
export const LARGE_MODAL_WIDTH_OTHER = 1000
export const LARGER_MODAL_WIDTH = 1200

// 表格列配置 单元格的宽度
export const TABLE_CELL_WIDTH_1x = 100
export const TABLE_CELL_WIDTH_1_5x = 150
export const TABLE_CELL_WIDTH_2x = 200
export const TABLE_CELL_WIDTH_3x = 300

// 推荐意见
export const APPROVAL_OPTIONS = {
  [FORM_COMMIT_TEXT]: ["请办理。", "请审核。"],
  [FORM_REVOCATION_TEXT]: ["需要修订，撤回重新办理。"],
  [FORM_AGREE_TEXT]: ["审核通过，同意办理。", "请转入线下签批。"],
  [FORM_RETURN_TEXT]: ["审核不通过，不同意办理，退回重新办理。"],
  [FORM_HANDOVER_TEXT]: ["请办理。", "请审核。"],
  [FORM_CLOSE_TEXT]: ["审核通过，同意办结。"],
  [FORM_ALTERNATE_TEXT]: ["需要修订，变更重新办理"],
  [FORM_END_TEXT]: ["审核通过，同意终止。"],
}

// 界面左侧菜单配置
export const IS_SHOW_ICON = false
