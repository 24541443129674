import {Page, ResponseEntity, ResponsesEntity} from "@services/dto/common";
import {apiV1} from "@services/apiHelper";
import {TaskSupervisionReq, TaskSupervisionRow} from "@services/dto/taskSupervision";

export class TaskSupervisionService {
  static async getTreeTaskSupervision(req: TaskSupervisionReq): Promise<Page<TaskSupervisionRow>> {
    return await apiV1.get("/taskSupervision/tree", {params: req})
  }

  static async getTaskSupervision(req: TaskSupervisionReq): Promise<Page<TaskSupervisionRow>> {
    return await apiV1.get("/taskSupervision/search", {params: req})
  }

  static async addTaskSupervision(row: TaskSupervisionRow): Promise<ResponseEntity<TaskSupervisionRow>> {
    return await apiV1.post("/taskSupervision/save", {...row})
  }

  static async updateTaskSupervision(row: TaskSupervisionRow): Promise<ResponseEntity<TaskSupervisionRow>> {
    return await apiV1.put("/taskSupervision/update", {...row})
  }

  static async deleteTaskSupervision(ids: string[]): Promise<ResponseEntity<string>> {
    return await apiV1.delete("/taskSupervision/delete", {data: ids})
  }

  static async getOne(id: string): Promise<ResponseEntity<TaskSupervisionRow>> {
    return await apiV1.get(`/taskSupervision/getOne/${id}`)
  }

  static async getBatch(ids: string[]): Promise<ResponsesEntity<TaskSupervisionRow>> {
    return await apiV1.get(`/taskSupervision/getBatch/${ids.join(',')}`)
  }

  static async batchUpdateTaskSupervision(rows: TaskSupervisionRow[]): Promise<ResponseEntity<string>> {
    return await apiV1.put("/taskSupervision/batchUpdate", [...rows])
  }
}
