import {observer} from "mobx-react";
import React, {forwardRef, useEffect, useImperativeHandle, useLayoutEffect, useState} from "react";
import {FormInstance, message, Modal, Space, Table, Tag, Typography} from "antd";
import {allStores} from "@/stores";
import {ColumnsType} from "antd/es/table";
import {accAdd, logger, safeCompute, showErr} from "@common/utils";
import {BasicInfo} from "@modules/InvoiceRegister/Components/BasicInfo";
import {InvoiceRegisterReq, InvoiceRegisterRow, InvoiceRegisterRowSummaryTotal} from "@services/dto/invoiceRegister";
import {InvoiceRegisterService} from "@services/InvoiceRegisterService";
import {headerSearchStore} from "@/layout/Stores/HeaderSearchStore";
import {LabelValue, ResponseEntity} from "@services/dto/common";
import {TableEllipsisText} from "@components/TableEllipsisText";
import {TableMoneyText} from "@components/TableMoneyText";
import {HeaderActionCom} from "@modules/InvoiceRegister/Components/HeaderActionCom";
import {useLocation} from "react-router-dom";
import {ModalSelectContext, SelectRowKeyService} from "@modules/InvoiceRegister/Context/ModalSelectContext";
import {BasicInfo as ProcessRecordsBasicInfo} from "@modules/ProcessRecords/Components/BasicInfo";
import {TaxAccountingRow} from "@services/dto/taxAccounting";
import {TaxAccounting} from "@modules/TaxAccounting";
import {WindowPrint} from "@modules/WindowPrint";
import {
  CloudDownloadOutlined,
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  FieldTimeOutlined,
  PlusOutlined,
  PrinterOutlined
} from "@ant-design/icons";
import {TableApplicationFormText} from "@components/TableApplicationFormText";
import {CustomDraggableModal} from "@components/CustomDraggableModal";
import {
  APPROVE_FORM_TRANS_STATE,
  BASE_API_PATH,
  BLACK_LIST,
  DATE_FMT,
  FORM_ALTERNATE_TEXT,
  FORM_CLOSE_TEXT,
  FORM_COMMIT_TEXT,
  FORM_DISABLED_STATE,
  FORM_HANDOVER_TEXT,
  FORM_PERSON_RESPONSIBLE_WHITELIST,
  FORM_RETURN_TEXT,
  FORM_REVOCATION_TEXT,
  LARGE_MODAL_WIDTH,
  LARGE_MODAL_WIDTH_OTHER,
  LARGER_MODAL_WIDTH,
  ONFILE,
  READY,
  SMALL_TABLE_SCROLL_HEIGHT,
  TABLE_CELL_WIDTH_1_5x,
  TABLE_CELL_WIDTH_1x,
  TABLE_CELL_WIDTH_2x
} from "@/config";
import {ProcessRecordsRow} from "@services/dto/processRecords";
import {TableAuthOptions} from "@components/CustomAuthOptions";
import dayjs from "dayjs";
import {ProcessRecordsService} from "@services/ProcessRecordsService";
import {ProcessRecords} from "@modules/ProcessRecords";
import {CustomForm} from "@components/CustomForm";
import {TableFilingText} from "@components/TableFilingText";
import {CustomImport} from "@components/CustomImport/CustomImport";

const wideShowColumn: string[] = [
  'irDate',
  // 'irInvoiceCode2',
  'irInvoiceCode',
  'irType',
  // 'irContent',
  // 'salesListName',
  'irAmount-summation',
  // 'irTaxRate',
  'irTaxAmount-summation',
  'irTaxRateSum-summation',
  'irSeller',
  'irPurchaser',
  // 'irApplicationForm',
  // 'irContractNoId',
  // 'outputAccountingCode',
  // 'inputAccountingCode',
  // 'personResponsibleName',
  // 'responsibilityProject',
  // 'cooperationUnit',
  // 'irFileList',
  'irStatus',
  'statusCommon',
  'action',
]

const narrowShowColumn: string[] = [
  'irDate',
  // 'irInvoiceCode2',
  'irInvoiceCode',
  'irType',
  // 'irContent',
  // 'salesListName',
  // 'irAmount-summation',
  // 'irTaxRate',
  // 'irTaxAmount-summation',
  // 'irTaxRateSum-summation',
  'irSeller',
  'irPurchaser',
  // 'irApplicationForm',
  // 'irContractNoId',
  // 'outputAccountingCode',
  // 'inputAccountingCode',
  // 'personResponsibleName',
  // 'responsibilityProject',
  // 'cooperationUnit',
  // 'irFileList',
  'irStatus',
  // 'statusCommon',
  'action',
]

export interface PropsType {
  isHiddenOptions?: boolean
  isModalSelect?: boolean
  rowKeys?: React.Key[]
  notInIds?: string
  inIds?: string
  outOrInIsNull?: string
  locationPathname?: string // 路由
  dataSource?: InvoiceRegisterRow[] // 数据源
  irPurchaser?: string  //购买方
  irSelle?: string  //销售方
  irType?: string // 发票类型
}

export const TableName = 'invoiceRegistration'

export const InvoiceRegister = observer(forwardRef<SelectRowKeyService, PropsType>((props, ref) => {
  const {
    processStore,
    securityStore,
    invoiceRegisterStore,
    screenResolutionStore,
    tableColumnsStore
  } = allStores
  const basicInfo = React.createRef<FormInstance>();
  const processRecordsBasicInfo = React.createRef<FormInstance>();
  const [dataSource, setDataSource] = useState<InvoiceRegisterRow[]>([])
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [total, setTotal] = useState<number>(0)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>(props.rowKeys || []);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [invoiceRegisterRow, setInvoiceRegisterRow] = useState<InvoiceRegisterRow>()
  const [isView, setIsView] = useState<boolean>(false)
  const [scrollHeight, setScrollHeight] = useState<number>(SMALL_TABLE_SCROLL_HEIGHT)
  const [renderColumns, setRenderColumns] = useState<ColumnsType<InvoiceRegisterRow>>([])
  const location = useLocation()
  const [showColumn, setShowColumn] = useState<string[]>(wideShowColumn)
  const [taxAccountingOpen, setTaxAccountingOpen] = useState<boolean>(false)
  const [isBatchUpdate, setIsBatchUpdate] = useState(false);
  const [updateCount, setUpdateCount] = useState<number>(0)
  const [taxAccountingDataSource, setTaxAccountingDataSource] = useState<TaxAccountingRow[]>([])
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [contentList, setContentList] = useState<LabelValue[]>([]);
  const [processRecordsIsOpen, setProcessRecordsIsOpen] = useState<boolean>(false)
  const [processRecordsRow, setProcessRecordsRow] = useState<ProcessRecordsRow>()
  const [processRecordsOpen, setProcessRecordsOpen] = useState<boolean>(false)
  const [processRecordsDataSource, setProcessRecordsDataSource] = useState<ProcessRecordsRow[]>([])
  const [submitRow, setSubmitRow] = useState<InvoiceRegisterRow>()
  const [submitterId, setSubmitterId] = useState<string>()
  const [latestProcess, setLatestProcess] = useState<string>()
  const [latestProcessBatch, setLatestProcessBatch] = useState<string[]>()
  const [summaryTotalObj, setSummaryTotalObj] = useState<InvoiceRegisterRowSummaryTotal>({
    irAmount: 0,
    irTaxAmount: 0,
    irTaxRateSum: 0,
  })
  const [searchReq, setSearchReq] = useState<InvoiceRegisterReq>({
    pageNum: 1,
    perPage: 10,
    cooperationUnit: headerSearchStore.headerSearchReq.cooperationUnit,
    responsibilityProject: headerSearchStore.headerSearchReq.responsibilityProject,
    inIds: props.inIds,
    notInIds: props.notInIds,
    outOrInIsNull: props.outOrInIsNull,
    irPurchaser: props.irPurchaser,
    irSeller: props.irSelle,
    irType: props.irType,
    ...invoiceRegisterStore.invoiceRegisterReq,
  })

  useImperativeHandle(ref, () => {
    return {
      getSelectedRowKeys() {
        return selectedRowKeys
      },
      setSelectedRowKeys(rowKeys: React.Key[]) {
        setSelectedRowKeys(rowKeys)
      }
    }
  })

  useEffect(() => {
    const req = {...searchReq}
    if (req.cooperationUnit !== headerSearchStore.headerSearchReq.cooperationUnit || req.responsibilityProject !== headerSearchStore.headerSearchReq.responsibilityProject) {
      req.cooperationUnit = headerSearchStore.headerSearchReq.cooperationUnit
      req.responsibilityProject = headerSearchStore.headerSearchReq.responsibilityProject
      setSearchReq(req)
      refreshData(req)
    }
  }, [headerSearchStore.headerSearchReq])

  const columns: ColumnsType<InvoiceRegisterRow> = [
    {
      title: '开票日期',
      dataIndex: 'irDate',
      key: 'irDate',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '制单编号',
      dataIndex: 'irInvoiceCode2',
      key: 'irInvoiceCode2',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '发票代码-号码',
      dataIndex: 'irInvoiceCode',
      key: 'irInvoiceCode',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '发票类型',
      dataIndex: 'irType',
      key: 'irType',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '开票内容',
      dataIndex: 'irContent',
      key: 'irContent',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '关联销货清单',
      dataIndex: 'salesListName',
      key: 'salesListName',
      width: TABLE_CELL_WIDTH_1_5x,
      align: 'center',
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '金额(元)',
      dataIndex: 'irAmount',
      key: 'irAmount-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '税率',
      dataIndex: 'irTaxRate',
      key: 'irTaxRate',
      align: 'right',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableMoneyText isPercentage={true} value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '税额(元)',
      dataIndex: 'irTaxAmount',
      key: 'irTaxAmount-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '价税合计(元)',
      dataIndex: 'irTaxRateSum',
      key: 'irTaxRateSum-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '销售方',
      dataIndex: 'irSeller',
      key: 'irSeller',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '购买方',
      dataIndex: 'irPurchaser',
      key: 'irPurchaser',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '关联申请类型',
      dataIndex: 'irApplicationForm',
      key: 'irApplicationForm',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (_, record) => (
        <Space>
          <TableEllipsisText
            value={record.irContractNoObj?.length ? record.irContractNoObj[0].type : ''}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '关联申请编号',
      dataIndex: 'irContractNoId',
      key: 'irContractNoId',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (_, record) => (
        <Space>
          <TableApplicationFormText
            type={record.irContractNoObj?.length ? record.irContractNoObj[0].type : ''}
            value={record.irContractNoObj?.map(o => o.contractSettlementId)}/>
        </Space>
      )
    },
    {
      title: '关联销项核算',
      dataIndex: 'outputAccountingCode',
      key: 'outputAccountingCode',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (_, record) => (
        <Space>
          {
            record.outputAccountingId === 'N/A' ? <TableEllipsisText value={record.outputAccountingId}/> :
              <TableEllipsisText
                copyValue={(record.taxAccountings as TaxAccountingRow[])?.filter(o => o.invoiceType === 'out').map(o => o.taCode).join(',')}
                value={
                  <Typography.Text
                    type="success"
                    className={'c-pointer'}
                    onClick={() => {
                      setTaxAccountingDataSource((record.taxAccountings as TaxAccountingRow[])?.filter(o => o.invoiceType === 'out'))
                      setTaxAccountingOpen(true)
                    }}>
                    { (record.taxAccountings as TaxAccountingRow[])?.filter(o => o.invoiceType === 'out').map(o => o.taCode).join(',') }
                  </Typography.Text>
                }></TableEllipsisText>
          }
        </Space>
      )
    },
    {
      title: '关联进项核算',
      dataIndex: 'inputAccountingCode',
      key: 'inputAccountingCode',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (_, record) => (
        <Space>
          {
            record.inputAccountingId === 'N/A' ? <TableEllipsisText value={record.inputAccountingId}/> :
              <TableEllipsisText
                copyValue={(record.taxAccountings as TaxAccountingRow[])?.filter(o => o.invoiceType === 'in').map(o => o.taCode).join(',')}
                value={
                  <Typography.Text
                    type="success"
                    className={'c-pointer'}
                    onClick={() => {
                      setTaxAccountingDataSource((record.taxAccountings as TaxAccountingRow[])?.filter(o => o.invoiceType === 'in'))
                      setTaxAccountingOpen(true)
                    }}>
                    { (record.taxAccountings as TaxAccountingRow[])?.filter(o => o.invoiceType === 'in').map(o => o.taCode).join(',') }
                  </Typography.Text>
                }></TableEllipsisText>
          }
        </Space>
      )
    },
    {
      title: '责任部门',
      dataIndex: 'responsibilityProject',
      key: 'responsibilityProject',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '协作单位',
      dataIndex: 'cooperationUnit',
      key: 'cooperationUnit',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '存档批号',
      dataIndex: 'irFileCode',
      key: 'irFileCode',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (_, record) => (
        <Space>
          <TableFilingText id={record.irFile} value={record.irFileCode}></TableFilingText>
        </Space>
      )
    },
    {
      title: '办理人员',
      dataIndex: 'personResponsibleName',
      key: 'personResponsibleName',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '主办人员',
      dataIndex: 'organizersUserName',
      key: 'organizersUserName',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '办理进度',
      dataIndex: 'irStatus',
      key: 'irStatus',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '校验',
      dataIndex: 'statusCommon',
      key: 'statusCommon',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <Tag color={text === '完成' ? "success" : 'error'}>{ text }</Tag>
        </Space>
      )
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (_, record) => (
        <TableAuthOptions
          mainBtn={['handleEdit', 'handleView']}
          items={[
            {
              key: 'handleView',
              icon: <EllipsisOutlined/>,
              onClick: () => handleView(record)
            },
            {
              key: 'handleEdit',
              icon: <EditOutlined/>,
              disabled: FORM_DISABLED_STATE.includes(record.irStatus),
              onClick: async () => await handleEdit(record)
            },
            {
              key: 'handlePrint',
              icon: <PrinterOutlined/>,
              onClick: () => handlePrint(record)
            },
            {
              key: 'handleDeleteBatch',
              icon: <DeleteOutlined/>,
              disabled: FORM_DISABLED_STATE.includes(record.irStatus),
              onClick: () => handleDeleteBatch(record)
            },
          ]}></TableAuthOptions>
      )
    }
  ];

  useEffect(() => {
    // 读取存储
    let showColumn: string[] = tableColumnsStore.getItem(props.locationPathname || location.pathname) || []
    if (showColumn.length) {
      // 检验存储中的列表是否符合规范
      showColumn = showColumn.filter(o => columns.some(column => column.key === o))
      // 无论有无存储都触发change，保存当前显示的字段
      setShowColumn(showColumn)
    } else {
      // 窄屏判断
      if (screenResolutionStore.screenResolution.height < 700 || screenResolutionStore.screenResolution.width < 1400) {
        setShowColumn(narrowShowColumn)
      }
    }

    // 当作为组件在其他页面使用且传了数据时 不请求数据
    if (!props.dataSource) {
      refreshData(searchReq)
    }
  }, [])

  useEffect(() => {
    if (invoiceRegisterStore.invoiceRegisterDatasource.data) {
      setDataSource(invoiceRegisterStore.invoiceRegisterDatasource.data.items || []);
      setTotal(invoiceRegisterStore.invoiceRegisterDatasource.data.total || 0)
    }
  }, [invoiceRegisterStore.invoiceRegisterDatasource])

  useEffect(() => {
    const objInitValue = {
      irAmount: 0,
      irTaxAmount: 0,
      irTaxRateSum: 0,
    }
    let obj = summaryTotalObj
    const datasource = props.isModalSelect ? props.dataSource : invoiceRegisterStore.invoiceRegisterDatasource.data?.items
    const arr = datasource?.filter(o => selectedRowKeys.includes(o.id))
    if (arr?.length) {
      obj = arr.reduce((acc, cur) => ({
        irAmount: accAdd(acc.irAmount, cur.irAmount),
        irTaxAmount: accAdd(acc.irTaxAmount, cur.irTaxAmount),
        irTaxRateSum: accAdd(acc.irTaxRateSum, cur.irTaxRateSum),
      }), objInitValue);
      setSummaryTotalObj(obj)
    } else {
      setSummaryTotalObj(objInitValue)
    }
  }, [invoiceRegisterStore.invoiceRegisterDatasource, selectedRowKeys])

  useEffect(() => {
    if (props.isModalSelect) {
      setRenderColumns(columns.filter(o => o.key !== 'action').filter(o => showColumn.includes(String(o.key))));
    } else {
      setRenderColumns(columns.filter(o => showColumn.includes(String(o.key))));
    }

  }, [showColumn])

  /**
   * 动态修改表格高度
   */
  useLayoutEffect(() => {
    const othersHeight = 10 + 5 + 5
    const parent = document.querySelector(".tabs-content")
    const headAction = document.querySelector(".header-action-row")
    const thead = document.querySelector(".ant-table-thead")
    const summary = document.querySelector('.ant-table-summary')
    const pagination = document.querySelector('.ant-pagination')
    const paginationHeight = pagination ? pagination.clientHeight + 16 * 2 : 0
    setScrollHeight(safeCompute(parent?.clientHeight) - safeCompute(headAction?.clientHeight) - safeCompute(thead?.clientHeight) - paginationHeight - safeCompute(summary?.clientHeight) - othersHeight)
  }, [screenResolutionStore.screenResolution.height, invoiceRegisterStore.collapsed, searchReq.pageNum, searchReq.perPage])

  const handlePageChange = (page: number, pageSize: number) => {
    let req = {...searchReq};
    req.perPage = pageSize
    req.pageNum = page
    setSearchReq(req)
    refreshData(req)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const refreshData = (req: InvoiceRegisterReq) => {
    setSearchReq(req)
    invoiceRegisterStore.getInvoiceRegister(req)
  }

  const handleTableColumnsChange = (value: string[]) => {
    setShowColumn(value)
    tableColumnsStore.setItem(props.locationPathname || location.pathname, value)
  }

  const handleRowSelection = (selectedRowKeys: React.Key[]) => {
    if (!props.isModalSelect) {
      setSelectedRowKeys(selectedRowKeys)
    }
  }

  const handleRowSelect = (record: InvoiceRegisterRow, selected: boolean) => {
    if (props.isModalSelect) {
      const rowKeys = [...selectedRowKeys]
      if (selected) {
        rowKeys.push(record.id)
      } else {
        const index = rowKeys.findIndex(key => key === record.id)
        rowKeys.splice(index, 1)
      }
      setSelectedRowKeys(rowKeys)
    }
  }

  const handleRowSelectAll = (selected: boolean, _: InvoiceRegisterRow[], changeRows: InvoiceRegisterRow[]) => {
    if (props.isModalSelect) {
      const rowKeys = [...selectedRowKeys]
      if (selected) {
        for (let i = 0; i < changeRows.length; i++) {
          rowKeys.push(changeRows[i].id)
        }
      } else {
        for (let i = 0; i < changeRows.length; i++) {
          const index = rowKeys.findIndex(key => key === changeRows[i].id)
          if (index > -1) {
            rowKeys.splice(index, 1)
          }
        }
      }
      setSelectedRowKeys(rowKeys)
    }
  }

  const handleShowColumnReset = () => {
    // 窄屏判断
    if (screenResolutionStore.screenResolution.height < 700 || screenResolutionStore.screenResolution.width < 1400) {
      setShowColumn(narrowShowColumn)
    } else {
      setShowColumn(wideShowColumn)
    }
  }

  /**
   * 打印单条数据
   * @param record
   */
  const handlePrint = async (record: InvoiceRegisterRow) => {
    const list = await invoiceRegisterStore.getPrintList(record)
    if (list) {
      setContentList(list)
      setIsModalOpen(true)
    } else {
      message.warning('请检查')
    }
  }

  const batchUpdate = (row: InvoiceRegisterRow) => {
    let flag = false
    const rows = invoiceRegisterStore.invoiceRegisterDatasource.data?.items.filter(o => selectedRowKeys.includes(o.id))
    for (let selectedRow of rows) {
      if (selectedRow.irStatus === ONFILE) {
        flag = true
        continue
      }
      if (row.irDate && typeof row.irDate === 'object') {
        selectedRow.irDate = row.irDate.format(DATE_FMT);
      }
      if (row.responsibilityProject) {
        selectedRow.responsibilityProject = row.responsibilityProject;
        selectedRow.code = row.code;
      }
      if (typeof row.cooperationUnit === 'object' && row.cooperationUnit.length) {
        selectedRow.cooperationUnit = row.cooperationUnit.join(',');
      }
    }
    if (flag) {
      message.warning(`办理进度为${ONFILE}的数据不可编辑！`).then()
    }
    InvoiceRegisterService.batchUpdateInvoiceRegister(rows).then((rsp) => {
      actionThen(rsp);
      setUpdateCount(updateCount + 1)
    }).catch(showErr).finally(() => setConfirmLoading(false));
  }

  // 新增
  const handleAdd = () => {
    const user = securityStore.getLoggedUser()
    setInvoiceRegisterRow({
      irStatus: READY,
      personResponsible: user._id,
      personResponsibleName: user.name,
      organizersUserId: user._id,
      organizersUserName: user.name,
      irDate: dayjs(),
    } as InvoiceRegisterRow)
    setIsView(false)
    setIsBatchUpdate(false)
    setIsModalVisible(true)
  }

  const handleView = (record: InvoiceRegisterRow) => {
    setInvoiceRegisterRow(record)
    setIsView(true)
    setIsBatchUpdate(false)
    setIsModalVisible(true);
  }

  const handleEdit = async (record: InvoiceRegisterRow) => {

    try {
      const rsp = await ProcessRecordsService.getProcessRecords({
        businessId: record.id,
        pageNum: 1,
        perPage: 999,
        type: TableName,
      })
      const process = rsp.items.length ? rsp.items[0].process : ''
      const userId = rsp.items.find(o => o.process === process && o.status === FORM_COMMIT_TEXT)?.userId
      const con = rsp.items.some(o => o.process === process && o.status === FORM_REVOCATION_TEXT)
      // 如果本轮有撤回 （id为空） 否则 （发起最新一轮的提交的人的id）
      setSubmitterId(con ? undefined : userId)
      setLatestProcess(process) // 发起最新一轮的标志

      setInvoiceRegisterRow(record)
      setIsView(false)
      setIsBatchUpdate(false)
      setIsModalVisible(true);
    } catch (err) {
      message.warning("办理记录查询失败！").then()
      logger.log(err)
    }

  }

  const handleEditBatch = async () => {

    try {

      if (selectedRowKeys.length === 0) {
        message.warning("请选择要操作的数据！").then()
        return
      }

      // 要操作的数据
      const rows = invoiceRegisterStore.invoiceRegisterDatasource.data?.items.filter(o => selectedRowKeys.includes(o.id))

      // 办理流程辅助字段：办理进度
      let irStatus: string

      // 判断是否符合批量操作条件
      const con = rows.every((o, i, arr) => {
        const firstRow = arr[0]
        irStatus = firstRow.irStatus
        if (o.personResponsible !== firstRow.personResponsible) {
          message.warning("办理人员不一致！").then()
          return false
        } else if (o.irStatus !== firstRow.irStatus) {
          message.warning("办理进度不一致！").then()
          return false
        } else return true
      })

      if (con) {

        let latestProcessList: string[] = []
        let submitterIdList: string[] = []
        for (let i = 0; i < rows.length; i++) {
          const item = rows[i]
          const rsp = await ProcessRecordsService.getProcessRecords({
            businessId: item.id,
            pageNum: 1,
            perPage: 999,
            type: TableName,
          })
          const process = rsp.items.length ? rsp.items[0].process : ''
          const userId = rsp.items.find(o => o.process === process && o.status === FORM_COMMIT_TEXT)?.userId
          const userIdCon = rsp.items.some(o => o.process === process && o.status === FORM_REVOCATION_TEXT)
          latestProcessList.push(process)
          submitterIdList.push(userIdCon ? undefined : userId)
        }

        // 如果提交人全部一致才赋值给参数:【提交人】
        setSubmitterId(new Set(submitterIdList).size === 1 ? submitterIdList[0] : undefined)
        setLatestProcessBatch(latestProcessList)
        setIsView(false)
        setIsBatchUpdate(true)
        setInvoiceRegisterRow({irStatus} as InvoiceRegisterRow)
        setIsModalVisible(true)

      }

    } catch (err) {
      message.warning("办理记录查询失败！").then()
      logger.log(err)
    }

  }

  const handleDeleteBatch = (record?: InvoiceRegisterRow) => {
    const keys = [...selectedRowKeys]
    if (!record && keys.length === 0) {
      message.warning("请选择要删除的数据！").then()
      return
    }
    let ids: string[] = [];
    if (record) {
      ids.push(record.id)
    } else {
      ids = keys.map(item => String(item))
    }
    Modal.confirm({
      title: "是否删除？",
      okText: "确定",
      cancelText: "取消",
      onOk() {
        setConfirmLoading(true)
        InvoiceRegisterService.deleteInvoiceRegister(ids).then(() => {
          message.success("删除成功！").then()
          setConfirmLoading(false)
          refreshData(invoiceRegisterStore.invoiceRegisterReq)
        }).catch(showErr).finally(() => setConfirmLoading(false))
      },
    })
  }

  const insertOrUpdate = async (row: InvoiceRegisterRow) => {
    if (typeof row.irDate === 'object' && row.irDate) {
      row.irDate = row.irDate.format(DATE_FMT);
    }
    if (typeof row.irContractNoId === 'object' && row.irContractNoId) {
      row.irContractNoId = row.irContractNoId.join(',')
    }
    if (typeof row.cooperationUnit === 'object') {
      row.cooperationUnit = []
      if (row.irSeller && !BLACK_LIST.includes(row.irSeller)) {
        row.cooperationUnit.push(row.irSeller)
      }
      if (row.irPurchaser && !BLACK_LIST.includes(row.irPurchaser)) {
        row.cooperationUnit.push(row.irPurchaser)
      }
      row.cooperationUnit = row.cooperationUnit
        .filter((v, i, self) => self.indexOf(v) === i) // 去重
        .filter(value => !(BLACK_LIST.includes(value))) // 去黑名单
        .join(',');
    }
    if (typeof row.inputAccountingId === 'object') {
      row.inputAccountingId = row.inputAccountingId.join(",")
    }
    if (row.id) {
      const rsp = await InvoiceRegisterService.updateInvoiceRegister(row)
      actionThen(rsp)
      setUpdateCount(updateCount + 1)
      setConfirmLoading(false)
      return rsp
    } else {
      const rsp = await InvoiceRegisterService.addInvoiceRegister(row)
      actionThen(rsp)
      setUpdateCount(updateCount + 1)
      setConfirmLoading(false)
      return rsp
    }
  }

  const actionThen = (rsp: ResponseEntity<any>) => {
    if (rsp.code === 200) {
      message.success("操作成功！").then()
    } else {
      message.warning(rsp.message).then()
    }
    setIsModalVisible(false);
    setInvoiceRegisterRow(undefined)
    refreshData(invoiceRegisterStore.invoiceRegisterReq)
    setSelectedRowKeys([])
  }

  const handleOk = (open: boolean) => {
    const row = {...invoiceRegisterRow!}
    basicInfo.current!.validateFields().then(async (values) => {
      setConfirmLoading(true)
      Object.assign(row, values)

      if (open) {

        // 将row和按钮先存起来
        setSubmitRow(row)

        // 弹窗选择处理人
        setProcessRecordsIsOpen(true)
        setProcessRecordsRow(undefined)

      } else {

        const user = securityStore.getLoggedUser()

        if (isBatchUpdate) {
          batchUpdate(row)
        } else {
          // 默认处理人员是自己
          if (!row.id) {
            row.personResponsible = user._id
            row.personResponsibleName = user.name
          }

          try {
            await insertOrUpdate(row)
          } catch (error) {
            setProcessRecordsIsOpen(false)
            setProcessRecordsRow(undefined)
            setConfirmLoading(false)
            setIsModalVisible(false);
            message.warning(error.message)
          }
        }

      }

    }).catch(logger.warn)
  }

  const handleSubmit = () => {
    const row = {...processRecordsRow!}
    processRecordsBasicInfo.current!.validateFields().then(async (values) => {
      Object.assign(row, values)

      const user = securityStore.getLoggedUser()
      const btnText = row.status

      // 当类型是撤回时 默认办理人员为提交人(自己)
      if (btnText === FORM_REVOCATION_TEXT) {
        row.personResponsible = user._id || submitterId
        row.personResponsibleName = user.name
      }

      // 主表
      const subRow = {...submitRow}
      subRow.personResponsible = row.personResponsible
      subRow.personResponsibleName = row.personResponsibleName
      subRow.irStatus = APPROVE_FORM_TRANS_STATE[btnText] || subRow.irStatus
      // 主表在撤回、退回、变更时->更新局部数据
      if ([FORM_REVOCATION_TEXT, FORM_RETURN_TEXT, FORM_ALTERNATE_TEXT].includes(btnText)) {
        subRow.organizersUserId = subRow.personResponsible
        subRow.organizersUserName = subRow.personResponsibleName
      }
      // 主表在转交、提交时->更新局部数据
      if ([FORM_HANDOVER_TEXT, FORM_COMMIT_TEXT].includes(btnText)) {
        subRow.organizersUserId = subRow.personResponsible
        subRow.organizersUserName = subRow.personResponsibleName
      }
      // 主表在提交时->更新局部数据
      if (btnText === FORM_COMMIT_TEXT) {
        subRow.irDate = new Date().toLocaleDateString().replace(/\//g, '-')
      }
      // 主表在结办时->更新局部数据
      if (btnText === FORM_CLOSE_TEXT) {

      }

      // 办理记录表
      row.type = TableName
      row.businessId = subRow.id // 有可能会没值
      row.status = btnText
      row.userId = user._id
      row.userName = user.name
      row.personResponsible = undefined
      row.personResponsibleName = undefined
      if (btnText === FORM_COMMIT_TEXT) {
        const date = new Date()
        row.process = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
      } else {
        row.process = latestProcess
      }
      if (FORM_PERSON_RESPONSIBLE_WHITELIST.includes(btnText)) {
        row.conca = `${user.name}${btnText}给${subRow.personResponsibleName}。`
      } else {
        row.conca = `${user.name}${btnText}了。`
      }

      // 判断是否 先新增主表 后新增记录和通知表
      if (subRow.id) {

        let firstOperationSucceeded = false;
        try {
          await insertOrUpdate(subRow);
          firstOperationSucceeded = true;
        } catch (error) {
          setProcessRecordsIsOpen(false)
          setProcessRecordsRow(undefined)
          setConfirmLoading(false)
          setIsModalVisible(false);
          message.warning(error.message)
        }
        if (!firstOperationSucceeded) return
        await ProcessRecordsService.addProcessRecords(row)
        const rsp = await ProcessRecordsService.getProcessRecords({
          businessId: subRow.id,
          pageNum: 1,
          perPage: 999,
          type: TableName,
        })
        const notificationScopeUserId = rsp.items
          .map(o => o.userId)
          .concat(row.person)
          .filter((v, i, self) => self.indexOf(v) === i) // 去重
          .filter(id => id !== user._id) // 过滤出不是自己的数据
          .join(',') || ''
        const notificationScopeUserName = rsp.items
          .map(o => o.userName)
          .concat((row.personName || '').split(','))
          .filter((v, i, self) => self.indexOf(v) === i) // 去重
          .filter(name => name !== user.name) // 过滤出不是自己的数据
          .join(',') || ''
        await processStore.newAssignmentNotice({
          businessId: subRow.id,
          businessCode: subRow.irInvoiceCode2,
          cooperationUnit: subRow.cooperationUnit[0] || 'N/A',
          notificationScopeUserId,
          notificationScopeUserName,
          personResponsible: subRow.personResponsible,
          personResponsibleName: subRow.personResponsibleName,
          responsibilityProject: subRow.responsibilityProject,
          type: "发票登记",
          wnDesc: `您参与办理的发票登记（${subRow.irInvoiceCode2}），${row.conca} 请知悉！`,
          wnStatus: "未读"
        })

      } else {

        let subRsp: ResponseEntity<InvoiceRegisterRow>
        try {
          subRsp = await insertOrUpdate(subRow)
        } catch (error) {
          setProcessRecordsIsOpen(false)
          setProcessRecordsRow(undefined)
          setConfirmLoading(false)
          setIsModalVisible(false);
          message.warning(error.message)
        }

        if (subRsp.data) {
          // 重新赋值再提交
          row.businessId = subRsp.data.id
          await ProcessRecordsService.addProcessRecords(row)
          const rsp = await ProcessRecordsService.getProcessRecords({
            businessId: subRsp.data.id,
            pageNum: 1,
            perPage: 999,
            type: TableName,
          })
          const notificationScopeUserId = rsp.items
            .map(o => o.userId)
            .concat(row.person)
            .filter((v, i, self) => self.indexOf(v) === i) // 去重
            .filter(id => id !== user._id) // 过滤出不是自己的数据
            .join(',') || ''
          const notificationScopeUserName = rsp.items
            .map(o => o.userName)
            .concat((row.personName || '').split(','))
            .filter((v, i, self) => self.indexOf(v) === i) // 去重
            .filter(name => name !== user.name) // 过滤出不是自己的数据
            .join(',') || ''
          await processStore.newAssignmentNotice({
            businessId: subRsp.data.id,
            businessCode: subRsp.data.irInvoiceCode2,
            cooperationUnit: (subRsp.data.cooperationUnit as string).split(',')[0] || 'N/A',
            notificationScopeUserId,
            notificationScopeUserName,
            personResponsible: subRsp.data.personResponsible,
            personResponsibleName: subRsp.data.personResponsibleName,
            responsibilityProject: subRsp.data.responsibilityProject,
            type: "发票登记",
            wnDesc: `您参与办理的发票登记（${subRsp.data.irInvoiceCode2}），${row.conca} 请知悉！`,
            wnStatus: "未读"
          })
        } else {
          message.warning('主表保存成功，办理记录表和任务通知表提交失败')
        }

      }
      setProcessRecordsIsOpen(false)
      setProcessRecordsRow(undefined)
    }).catch(logger.warn)
  }

  const handleSubmitBatch = () => {
    const row = {...processRecordsRow!}
    processRecordsBasicInfo.current!.validateFields().then(async (values) => {

      const rows = invoiceRegisterStore.invoiceRegisterDatasource.data?.items.filter(o => selectedRowKeys.includes(o.id))
      for (let i = 0; i < rows.length; i++) {

        Object.assign(row, values)

        const user = securityStore.getLoggedUser()
        const btnText = row.status

        // 当类型是撤回时 默认办理人员为提交人(自己)
        if (btnText === FORM_REVOCATION_TEXT) {
          row.personResponsible = user._id || submitterId
          row.personResponsibleName = user.name
        }

        // 主表
        const subRow = {...rows[i]}
        subRow.personResponsible = row.personResponsible
        subRow.personResponsibleName = row.personResponsibleName
        subRow.irStatus = APPROVE_FORM_TRANS_STATE[btnText] || subRow.irStatus
        // 主表在撤回、退回、变更时->更新局部数据
        if ([FORM_REVOCATION_TEXT, FORM_RETURN_TEXT, FORM_ALTERNATE_TEXT].includes(btnText)) {
          subRow.organizersUserId = subRow.personResponsible
          subRow.organizersUserName = subRow.personResponsibleName
        }
        // 主表在转交、提交时->更新局部数据
        if ([FORM_HANDOVER_TEXT, FORM_COMMIT_TEXT].includes(btnText)) {
          subRow.organizersUserId = subRow.personResponsible
          subRow.organizersUserName = subRow.personResponsibleName
        }
        // 主表在提交时->更新局部数据
        if (btnText === FORM_COMMIT_TEXT) {
          subRow.irDate = new Date().toLocaleDateString().replace(/\//g, '-')
        }
        // 主表在结办时->更新局部数据
        if (btnText === FORM_CLOSE_TEXT) {

        }

        // 办理记录表
        row.type = TableName
        row.businessId = subRow.id // 有可能会没值
        row.status = btnText
        row.userId = user._id
        row.userName = user.name
        row.personResponsible = undefined
        row.personResponsibleName = undefined
        if (btnText === FORM_COMMIT_TEXT) {
          const date = new Date()
          row.process = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
        } else {
          row.process = latestProcessBatch[i]
        }
        if (FORM_PERSON_RESPONSIBLE_WHITELIST.includes(btnText)) {
          row.conca = `${user.name}${btnText}给${subRow.personResponsibleName}。`
        } else {
          row.conca = `${user.name}${btnText}了。`
        }

        let firstOperationSucceeded = false;
        try {
          await insertOrUpdate(subRow);
          firstOperationSucceeded = true;
        } catch (error) {
          setProcessRecordsIsOpen(false)
          setProcessRecordsRow(undefined)
          setConfirmLoading(false)
          setIsModalVisible(false);
          message.warning(error.message)
        }
        if (!firstOperationSucceeded) return
        await ProcessRecordsService.addProcessRecords(row)
        const rsp = await ProcessRecordsService.getProcessRecords({
          businessId: subRow.id,
          pageNum: 1,
          perPage: 999,
          type: TableName,
        })
        const notificationScopeUserId = rsp.items
          .map(o => o.userId)
          .concat(row.person)
          .filter((v, i, self) => self.indexOf(v) === i) // 去重
          .filter(id => id !== user._id) // 过滤出不是自己的数据
          .join(',') || ''
        const notificationScopeUserName = rsp.items
          .map(o => o.userName)
          .concat((row.personName || '').split(','))
          .filter((v, i, self) => self.indexOf(v) === i) // 去重
          .filter(name => name !== user.name) // 过滤出不是自己的数据
          .join(',') || ''
        await processStore.newAssignmentNotice({
          businessId: subRow.id,
          businessCode: subRow.irInvoiceCode2,
          cooperationUnit: subRow.cooperationUnit[0] || 'N/A',
          notificationScopeUserId,
          notificationScopeUserName,
          personResponsible: subRow.personResponsible,
          personResponsibleName: subRow.personResponsibleName,
          responsibilityProject: subRow.responsibilityProject,
          type: "发票登记",
          wnDesc: `您参与办理的发票登记（${subRow.irInvoiceCode2}），${row.conca} 请知悉！`,
          wnStatus: "未读"
        })

      }

      setProcessRecordsIsOpen(false)
      setProcessRecordsRow(undefined)
    }).catch(logger.warn)
  }

  /**
   * 操作记录按钮
   */
  const handleProcessRecordsClick = async (record: InvoiceRegisterRow) => {
    const rsp = await ProcessRecordsService.getProcessRecords({
      businessId: record.id,
      pageNum: 1,
      perPage: 999,
      type: TableName
    })
    if (!rsp) {
      message.warning('查无数据')
      return
    }
    setProcessRecordsDataSource(rsp.items)
    setProcessRecordsOpen(true)
  }

  const handleCopyClick = (record: InvoiceRegisterRow) => {
    const user = securityStore.getLoggedUser()
    setInvoiceRegisterRow({
      ...record,
      id: '',
      irFile: '',
      taxAccountings: [],
      irStatus: READY,
      personResponsible: user._id,
      personResponsibleName: user.name,
      organizersUserId: user._id,
      organizersUserName: user.name,
      irDate: dayjs(),
    })
    setIsView(false)
    setIsBatchUpdate(false)
    setIsModalVisible(true)
  }

  const handleDownloadTemp = () => {
    window.open(BASE_API_PATH + "/filepath/file/fpdj.xlsx")
  }

  return (
    <ModalSelectContext.Provider value={props.isModalSelect}>
      <div className={"institution"}>
        <HeaderActionCom
          size={props.isModalSelect ? 6 : undefined}
          locationPathname={props.locationPathname}
          collapsed={invoiceRegisterStore.collapsed}
          setCollapsed={(value) => invoiceRegisterStore.setCollapsed(value)}
          handleTableColumnsChange={handleTableColumnsChange}
          handleShowColumnReset={handleShowColumnReset}
          showColumn={showColumn}
          updateCount={updateCount}
          searchReq={searchReq}
          tableColumns={props.isModalSelect ? columns.filter(o => o.key !== 'action') : columns}
          refreshData={props.dataSource ? undefined : ((req) => refreshData({...searchReq, ...req}))}
          items={[
            {
              key: 'handleAdd',
              icon: <PlusOutlined/>,
              onClick: () => handleAdd()
            },
            {
              key: 'handleDeleteBatch',
              icon: <DeleteOutlined/>,
              onClick: () => handleDeleteBatch()
            },
            {
              key: 'handleEditBatch',
              icon: <EditOutlined/>,
              onClick: () => handleEditBatch()
            },
            {
              key: 'handleDownloadTemp',
              icon: <CloudDownloadOutlined/>,
              onClick: () => handleDownloadTemp()
            },
            {
              key: 'handleImport',
              node: <CustomImport
                url={'/v1/invoiceRegistration/import'}
                callback={() => refreshData(invoiceRegisterStore.invoiceRegisterReq)}></CustomImport>,
            },
          ]}></HeaderActionCom>

        <Table
          className={'margin-top-xss'}
          rowKey={"id"}
          dataSource={props.dataSource || dataSource}
          columns={renderColumns}
          size={"middle"}
          loading={props.dataSource ? false : invoiceRegisterStore.invoiceRegisterDatasource.loading || confirmLoading}
          bordered
          scroll={{y: scrollHeight, scrollToFirstRowOnChange: true}}
          rowSelection={{
            selectedRowKeys,
            onChange: handleRowSelection,
            onSelect: handleRowSelect,
            onSelectAll: handleRowSelectAll,
            columnWidth: 50
          }}
          pagination={!props.dataSource && {
            total: total,
            position: ['bottomCenter'],
            pageSize: searchReq.perPage,
            current: searchReq.pageNum,
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: total => `共${total}条`,
            onChange: handlePageChange
          }}
          summary={() => (
            <Table.Summary fixed={"bottom"}>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} key={0} colSpan={1} className='text-align-center'>
                  { selectedRowKeys.length }
                </Table.Summary.Cell>
                {
                  (showColumn as string[]).map((key, index) =>
                    key.includes('-summation') ? <Table.Summary.Cell
                      index={index + 1} key={index + 1} colSpan={1} className='text-align-right'>
                      <TableMoneyText value={summaryTotalObj[key.slice(0, key.indexOf('-'))]}></TableMoneyText>
                    </Table.Summary.Cell> : <Table.Summary.Cell key={index + 1} index={index + 1} colSpan={1}/>)
                }
              </Table.Summary.Row>
            </Table.Summary>
          )}/>

        { /*操作记录表*/ }
        <CustomDraggableModal
          title={<div className="text-align-center">办理记录</div>}
          open={processRecordsOpen}
          width={LARGE_MODAL_WIDTH_OTHER}
          onCancel={() => setProcessRecordsOpen(false)}
          destroyOnClose
          footer={null}>
          <ProcessRecords
            isHiddenOptions
            dataSource={processRecordsDataSource}
            isModalSelect
            locationPathname={'/processRecords'}/>
        </CustomDraggableModal>

        { /*操作记录表单*/ }
        <CustomDraggableModal
          title={<div className="text-align-center">办理记录</div>}
          open={processRecordsIsOpen}
          width={LARGE_MODAL_WIDTH}
          destroyOnClose
          onCancel={() => {
            setProcessRecordsIsOpen(false)
            setConfirmLoading(false)
          }}
          onOk={isBatchUpdate ? handleSubmitBatch : handleSubmit}>
          <ProcessRecordsBasicInfo
            tableName={TableName}
            businessId={invoiceRegisterRow?.id}
            submitterId={submitterId}
            userId={invoiceRegisterRow?.personResponsible}
            status={invoiceRegisterRow?.irStatus}
            row={processRecordsRow}
            ref={processRecordsBasicInfo}/>
        </CustomDraggableModal>

        { /*关联税费核算*/ }
        <CustomDraggableModal
          title={<div className="text-align-center">关联税费核算</div>}
          open={taxAccountingOpen}
          width={LARGER_MODAL_WIDTH}
          onCancel={() => setTaxAccountingOpen(false)}
          destroyOnClose
          footer={null}>
          <TaxAccounting
            dataSource={taxAccountingDataSource}
            isModalSelect
            isHiddenOptions
            locationPathname={'/taxAccounting'}/>
        </CustomDraggableModal>

        <CustomForm
          title={'发票登记'}
          handleCancel={handleCancel}
          handleOk={handleOk}
          isModalVisible={isModalVisible}
          submitterId={submitterId}
          confirmLoading={confirmLoading}
          userId={invoiceRegisterRow?.personResponsible}
          status={invoiceRegisterRow?.irStatus}
          extraDisabled={!invoiceRegisterRow?.id}
          isView={isView}
          extraOnClick={() => handlePrint(invoiceRegisterRow)}
          item={[
            // {
            //   key: 'handleContractSettlementClick',
            //   icon: <RetweetOutlined/>,
            //   hidden: !FORM_INITIATE_STATE.includes(invoiceRegisterRow?.irStatus),
            //   onClick: () => handleContractSettlementClick(invoiceRegisterRow)
            // },
            {
              key: 'handleCopyClick',
              icon: <FieldTimeOutlined/>,
              disabled: !invoiceRegisterRow?.id,
              onClick: () => handleCopyClick(invoiceRegisterRow)
            },
            {
              key: 'handleProcessRecordsClick',
              icon: <FieldTimeOutlined/>,
              disabled: !invoiceRegisterRow?.id,
              onClick: () => handleProcessRecordsClick(invoiceRegisterRow)
            },
          ]}>
          <BasicInfo row={{...invoiceRegisterRow!}} ref={basicInfo} isView={isView} isBatchUpdate={isBatchUpdate}/>
        </CustomForm>

        <WindowPrint
          isHiddenTime
          title={"发票登记单"}
          contentList={contentList}
          isModalOpen={isModalOpen}
          handleCancel={() => setIsModalOpen(false)}/>
      </div>
    </ModalSelectContext.Provider>
  )
}))
