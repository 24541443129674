import {observer} from "mobx-react";
import React, {useEffect, useLayoutEffect, useState} from "react";
import {FormInstance, message, Modal, Space, Table} from "antd";
import {allStores} from "@/stores";
import {ColumnsType} from "antd/es/table";
import {logger, parseURLParams, safeCompute, showErr} from "@common/utils";
import {
  APPROVE_FORM_TRANS_STATE,
  CLOSE,
  DATE_FMT,
  FORM_ALTERNATE_TEXT,
  FORM_CLOSE_TEXT,
  FORM_COMMIT_TEXT,
  FORM_HANDOVER_TEXT,
  FORM_PERSON_RESPONSIBLE_WHITELIST,
  FORM_RETURN_TEXT,
  FORM_REVOCATION_TEXT,
  LARGE_MODAL_WIDTH,
  LARGE_MODAL_WIDTH_OTHER,
  READY,
  SMALL_TABLE_SCROLL_HEIGHT,
  TABLE_CELL_WIDTH_1_5x,
  TABLE_CELL_WIDTH_1x,
  TABLE_CELL_WIDTH_2x
} from "@/config";
import {BasicInfo} from "@modules/WorkSchedule/Components/BasicInfo";
import {BasicInfo as ProcessRecordsBasicInfo} from "@modules/ProcessRecords/Components/BasicInfo";
import {HeaderActionCom} from "@modules/WorkSchedule/Components/HeaderActionCom";
import {headerSearchStore} from "@/layout/Stores/HeaderSearchStore";
import {TableEllipsisText} from "@components/TableEllipsisText";
import {useLocation} from "react-router-dom";
import {DeleteOutlined, EditOutlined, EllipsisOutlined, FieldTimeOutlined} from "@ant-design/icons";
import {WorkScheduleReq, WorkScheduleRow} from "@services/dto/workSchedule";
import {WorkScheduleService} from "@services/WorkScheduleService";
import {TableFilingText} from "@components/TableFilingText";
import {CustomForm} from "@components/CustomForm";
import {CustomDraggableModal} from "@components/CustomDraggableModal";
import {ProcessRecords} from "@modules/ProcessRecords";
import {ProcessRecordsRow} from "@services/dto/processRecords";
import {ProcessRecordsService} from "@services/ProcessRecordsService";
import {LabelValue, ResponseEntity} from "@services/dto/common";
import {TableAuthOptions} from "src/components/CustomAuthOptions";
import {WindowPrint} from "@modules/WindowPrint";
import dayjs from "dayjs";

const blackColumnList = [
  'action',
]

const wideShowColumn: string[] = [
  'tsDate',
  'wsCode',
  'wsSummary',
  // 'wsCyclePeriod',
  // 'wsWorkPlanStartDate',
  'wsWorkPlanCompletionDate',
  'wsWorkPlanDuration',
  'wsFile',
  'handlingRecords',
  // 'personResponsibleName',
  // 'responsibilityProject',
  // 'cooperationUnit',
  'status',
  'action',
]

const narrowShowColumn: string[] = [
  'tsDate',
  'wsCode',
  'wsSummary',
  'wsCyclePeriod',
  // 'wsWorkPlanStartDate',
  // 'wsWorkPlanCompletionDate',
  // 'wsWorkPlanDuration',
  'wsFile',
  'handlingRecords',
  // 'personResponsibleName',
  // 'responsibilityProject',
  // 'cooperationUnit',
  'status',
  'action',
]

export interface PropsType {
  isModalSelect?: boolean // 拟态宽
  locationPathname?: string // 路由
  isHiddenOptions?: boolean
  dataSource?: WorkScheduleRow[] // 数据源
}

export const TableName = 'workSchedule'

export const WorkSchedule = observer(React.forwardRef<FormInstance, PropsType>((props, _) => {
  const {
    processStore,
    workScheduleStore,
    screenResolutionStore,
    tableColumnsStore,
    securityStore,
  } = allStores
  const location = useLocation()
  const basicInfo = React.createRef<FormInstance>();
  const processRecordsBasicInfo = React.createRef<FormInstance>();
  const [dataSource, setDataSource] = useState<WorkScheduleRow[]>([])
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [total, setTotal] = useState<number>(0)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [workScheduleRow, setWorkScheduleRow] = useState<WorkScheduleRow>()
  const [updateCount, setUpdateCount] = useState<number>(0)
  const [isView, setIsView] = useState<boolean>(false)
  const [scrollHeight, setScrollHeight] = useState<number>(SMALL_TABLE_SCROLL_HEIGHT)
  const [renderColumns, setRenderColumns] = useState<ColumnsType<WorkScheduleRow>>([])
  const [showColumn, setShowColumn] = useState<string[]>(wideShowColumn)
  const [processRecordsIsOpen, setProcessRecordsIsOpen] = useState<boolean>(false)
  const [processRecordsRow, setProcessRecordsRow] = useState<ProcessRecordsRow>()
  const [processRecordsOpen, setProcessRecordsOpen] = useState<boolean>(false)
  const [processRecordsDataSource, setProcessRecordsDataSource] = useState<ProcessRecordsRow[]>([])
  const [submitRow, setSubmitRow] = useState<WorkScheduleRow>()
  const [submitterId, setSubmitterId] = useState<string>()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [contentList, setContentList] = useState<LabelValue[]>([]);
  const [latestProcess, setLatestProcess] = useState<string>()
  const [searchReq, setSearchReq] = useState<WorkScheduleReq>({
    pageNum: 1,
    perPage: 10,
    cooperationUnit: headerSearchStore.headerSearchReq.cooperationUnit,
    responsibilityProject: headerSearchStore.headerSearchReq.responsibilityProject,
    ...workScheduleStore.workScheduleReq
  })

  const columns: ColumnsType<WorkScheduleRow> = [
    {
      title: '制单日期',
      dataIndex: 'wsDate',
      key: 'wsDate',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '制单编号',
      dataIndex: 'wsCode',
      key: 'wsCode',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '工作摘要',
      dataIndex: 'wsSummary',
      key: 'wsSummary',
      align: 'left',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '计划工期',
      dataIndex: 'wsWorkPlanDuration',
      key: 'wsWorkPlanDuration',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '开始日期',
      dataIndex: 'wsWorkPlanStartDate',
      key: 'wsWorkPlanStartDate',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '完成日期',
      dataIndex: 'wsWorkPlanCompletionDate',
      key: 'wsWorkPlanCompletionDate',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '通知日期',
      dataIndex: 'wsDay',
      key: 'wsDay',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (_, record) => (
        <Space>
          <TableEllipsisText value={`${dayjs(record.wsWorkPlanStartDate).subtract(safeCompute(record.wsDay), 'day').format(DATE_FMT)}（${{
            0: '开始日期当天',
            1: '开始日期前1天',
            3: '开始日期前3天',
            7: '开始日期前7天',
            15: '开始日期前15天',
            30: '开始日期前30天',
          }[record.wsDay || 0]}）`}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '循环周期',
      dataIndex: 'wsCyclePeriod',
      key: 'wsCyclePeriod',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '关联任务',
      dataIndex: 'wsSecondaryTasksCode',
      key: 'wsSecondaryTasksCode',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '责任部门',
      dataIndex: 'responsibilityProject',
      key: 'responsibilityProject',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '协作单位',
      dataIndex: 'cooperationUnit',
      key: 'cooperationUnit',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '存档批号',
      dataIndex: 'wsFile',
      key: 'wsFile',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (_, record) => (
        <Space>
          <TableFilingText id={record.wsFile} value={record.wsFileCode}></TableFilingText>
        </Space>
      )
    },
    {
      title: '办理人员',
      dataIndex: 'handlingRecordsName',
      key: 'handlingRecordsName',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '主办人员',
      dataIndex: 'organizersUserName',
      key: 'organizersUserName',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '参与人员',
      dataIndex: 'wnUserNames',
      key: 'wnUserNames',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '办理进度',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (_, record) => (
        <TableAuthOptions
          mainBtn={['handleEdit', 'handleView']}
          items={[
            {
              key: 'handleView',
              icon: <EllipsisOutlined/>,
              onClick: () => handleView(record)
            },
            {
              key: 'handleEdit',
              icon: <EditOutlined/>,
              disabled: record.status === CLOSE,
              onClick: async () => await handleEdit(record)
            },
            {
              key: 'handleDeleteBatch',
              icon: <DeleteOutlined/>,
              disabled: record.status === CLOSE,
              onClick: () => handleDeleteBatch(record)
            },
          ]}></TableAuthOptions>
      )
    }
  ];

  useEffect(() => {
    // 读取存储
    let showColumn: string[] = tableColumnsStore.getItem(props.locationPathname || location.pathname) || []
    if (showColumn.length) {
      // 检验存储中的列表是否符合规范
      showColumn = showColumn.filter(o => columns.some(column => column.key === o))
      // 无论有无存储都触发change，保存当前显示的字段
      setShowColumn(showColumn)
    } else {
      // 窄屏判断
      if (screenResolutionStore.screenResolution.height < 700 || screenResolutionStore.screenResolution.width < 1400) {
        setShowColumn(narrowShowColumn)
      }
    }

    // 当作为组件在其他页面使用且传了数据时 不请求数据
    if (!props.dataSource) {
      refreshData(searchReq)
    }
  }, [])

  useEffect(() => {
    if (workScheduleStore.workScheduleDatasource.data) {
      setDataSource(workScheduleStore.workScheduleDatasource.data.items || []);
      setTotal(workScheduleStore.workScheduleDatasource.data.total || 0)
    }
  }, [workScheduleStore.workScheduleDatasource.data])

  useEffect(() => {
    const req = {...searchReq}
    if (req.cooperationUnit !== headerSearchStore.headerSearchReq.cooperationUnit || req.responsibilityProject !== headerSearchStore.headerSearchReq.responsibilityProject) {
      req.cooperationUnit = headerSearchStore.headerSearchReq.cooperationUnit
      req.responsibilityProject = headerSearchStore.headerSearchReq.responsibilityProject
      setSearchReq(req)
      refreshData(req)
    }
  }, [headerSearchStore.headerSearchReq])

  useEffect(() => {
    if (props.isModalSelect) {
      setRenderColumns(columns.filter(o => o.key !== 'action').filter(o => showColumn.includes(String(o.key))));
    } else {
      setRenderColumns(columns.filter(o => showColumn.includes(String(o.key))));
    }
  }, [showColumn])

  useEffect(() => {
    if (workScheduleStore.workScheduleOption) {
      const [option, paramsStr] = workScheduleStore.workScheduleOption.split("?")
      if (option === 'add') {
        handleAdd()
      } else if (paramsStr) {
        const params = parseURLParams(paramsStr)
        if (option === 'edit') {
          WorkScheduleService.getOne(params.id).then(rsp => {
            if (rsp.code === 200) {
              handleEdit(rsp.data).then()
            }
          })
        }
      }
      workScheduleStore.updateWorkScheduleOption(undefined)
    }
  }, [workScheduleStore.workScheduleOption])

  /**
   * 动态修改表格高度
   */
  useLayoutEffect(() => {
    const othersHeight = 10 + 5 + 5
    const parent = document.querySelector(".tabs-content")
    const headAction = document.querySelector(".header-action-row")
    const thead = document.querySelector(".ant-table-thead")
    const summary = document.querySelector('.ant-table-summary')
    const pagination = document.querySelector('.ant-pagination')
    const paginationHeight = pagination ? pagination.clientHeight + 16 * 2 : 0
    setScrollHeight(safeCompute(parent?.clientHeight) - safeCompute(headAction?.clientHeight) - safeCompute(thead?.clientHeight) - paginationHeight - safeCompute(summary?.clientHeight) - othersHeight)
  }, [screenResolutionStore.screenResolution.height, workScheduleStore.collapsed, searchReq.pageNum, searchReq.perPage])

  const handleAdd = () => {
    const user = securityStore.getLoggedUser()
    setWorkScheduleRow({
      wsDate: dayjs(),
      status: READY,
      handlingRecords: user._id,
      handlingRecordsName: user.name
    } as WorkScheduleRow)
    setIsView(false)
    setIsModalVisible(true)
  }

  const handleView = (record: WorkScheduleRow) => {
    setWorkScheduleRow(record)
    setIsView(true)
    setIsModalVisible(true);
  }

  const handleEdit = async (record: WorkScheduleRow) => {

    const rsp = await ProcessRecordsService.getProcessRecords({
      businessId: record.id,
      pageNum: 1,
      perPage: 999,
      type: TableName,
    })
    const process = rsp.items.length ? rsp.items[0].process : ''
    const userId = rsp.items.find(o => o.process === process && o.status === FORM_COMMIT_TEXT)?.userId
    const con = rsp.items.some(o => o.process === process && o.status === FORM_REVOCATION_TEXT)
    // 如果本轮有撤回 （id为空） 否则 （发起最新一轮的提交的人的id）
    setSubmitterId(con ? undefined : userId)
    setLatestProcess(process) // 发起最新一轮的标志

    setWorkScheduleRow(record)
    setIsView(false)
    setIsModalVisible(true);
  }

  const handleDeleteBatch = (record?: WorkScheduleRow) => {
    if (!record && selectedRowKeys.length === 0) {
      message.warning("请选择要删除的数据！")
      return
    }
    let ids: string[] = []
    if (record) {
      ids.push(record.id)
    } else {
      ids = selectedRowKeys.map(item => String(item))
    }
    Modal.confirm({
      title: "是否删除？",
      okText: "确定",
      cancelText: "取消",
      onOk() {
        setConfirmLoading(true)
        WorkScheduleService.deleteWorkSchedule(ids).then(() => {
          message.success("删除成功！")
          setConfirmLoading(false)
          refreshData(searchReq)
        }).catch(showErr).finally(() => setConfirmLoading(false))
      },
    })
  }

  const handlePageChange = (page: number, pageSize: number) => {
    let req = {...searchReq};
    req.perPage = pageSize
    req.pageNum = page
    setSearchReq(req)
    refreshData(req)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const refreshData = (req: WorkScheduleReq) => {
    setSearchReq(req)
    workScheduleStore.getWorkSchedule(req)
  }

  const handleOk = (open: boolean) => {
    const row = {...workScheduleRow!}
    basicInfo.current!.validateFields().then(async (values) => {
      setConfirmLoading(true);
      Object.assign(row, values)

      if (open) {

        // 将row和按钮先存起来
        setSubmitRow(row)

        // 弹窗选择处理人
        setProcessRecordsIsOpen(true)
        setProcessRecordsRow({
          ...processRecordsRow,
          person: row.organizersUserId,
          personName: row.organizersUserName
        })

      } else {

        const user = securityStore.getLoggedUser()

        // 默认办理人员是自己
        if (!row.id) {
          row.handlingRecords = user._id
          row.handlingRecordsName = user.name
        }

        try {
          await insertOrUpdate(row)
        } catch (error) {
          setProcessRecordsIsOpen(false)
          setProcessRecordsRow(undefined)
          setConfirmLoading(false)
          setIsModalVisible(false);
          message.warning(error.message)
        }

      }

    }).catch(logger.warn)
  }

  const actionThen = (rsp: ResponseEntity<WorkScheduleRow>) => {
    if (rsp.code === 200) {
      message.success("操作成功！").then()
    } else {
      message.warning(rsp.message).then()
    }
    setIsModalVisible(false);
    setWorkScheduleRow(undefined)
    refreshData(searchReq)
    setSelectedRowKeys([])
  }

  const handleTableColumnsChange = (value: string[]) => {
    setShowColumn(value)
    tableColumnsStore.setItem(location.pathname, value)
  }

  const handleCollapsedChange = (collapsed: boolean) => {
    workScheduleStore.setCollapsed(collapsed)
  }

  const handleShowColumnReset = () => {
    // 窄屏判断
    if (screenResolutionStore.screenResolution.height < 700 || screenResolutionStore.screenResolution.width < 1400) {
      setShowColumn(narrowShowColumn)
    } else {
      setShowColumn(wideShowColumn)
    }
  }

  const insertOrUpdate = async (row: WorkScheduleRow) => {
    if (typeof row.wsDate === 'object' && row.wsDate) {
      row.wsDate = row.wsDate.format(DATE_FMT);
    }
    if (typeof row.wsWorkPlanStartDate === 'object' && row.wsWorkPlanStartDate) {
      row.wsWorkPlanStartDate = row.wsWorkPlanStartDate.format(DATE_FMT);
    }
    if (typeof row.wsWorkPlanCompletionDate === 'object' && row.wsWorkPlanCompletionDate) {
      row.wsWorkPlanCompletionDate = row.wsWorkPlanCompletionDate.format(DATE_FMT);
    }
    if (row.id) {
      const rsp = await WorkScheduleService.updateWorkSchedule(row)
      actionThen(rsp)
      setUpdateCount(updateCount + 1)
      setConfirmLoading(false)
      return rsp
    } else {
      const rsp = await WorkScheduleService.addWorkSchedule(row)
      actionThen(rsp)
      setUpdateCount(updateCount + 1)
      setConfirmLoading(false)
      return rsp
    }
  }

  const handleSubmit = () => {
    const row = {...processRecordsRow!}
    processRecordsBasicInfo.current!.validateFields().then(async (values) => {
      Object.assign(row, values)

      const user = securityStore.getLoggedUser()
      const btnText = row.status

      // 当类型是撤回时 默认办理人员为提交人(自己)
      if (btnText === FORM_REVOCATION_TEXT) {
        row.personResponsible = user._id || submitterId
        row.personResponsibleName = user.name
      }

      // 主表
      const subRow = {...submitRow}
      subRow.handlingRecords = row.personResponsible
      subRow.handlingRecordsName = row.personResponsibleName
      subRow.status = APPROVE_FORM_TRANS_STATE[btnText] || subRow.status
      // 主表在撤回、退回、变更时->更新局部数据
      if ([FORM_REVOCATION_TEXT, FORM_RETURN_TEXT, FORM_ALTERNATE_TEXT].includes(btnText)) {
        subRow.organizersUserId = subRow.personResponsible
        subRow.organizersUserName = subRow.personResponsibleName
      }
      // 主表在转交、提交时->更新局部数据
      if ([FORM_HANDOVER_TEXT, FORM_COMMIT_TEXT].includes(btnText)) {
        subRow.organizersUserId = subRow.handlingRecords
        subRow.organizersUserName = subRow.handlingRecordsName
      }
      // 主表在提交时->更新局部数据
      if (btnText === FORM_COMMIT_TEXT) {
        subRow.wsDate = new Date().toLocaleDateString().replace(/\//g, '-')
      }
      // 主表在结办时->更新局部数据
      if (btnText === FORM_CLOSE_TEXT) {

      }

      // 办理记录表
      row.type = TableName
      row.businessId = subRow.id // 有可能会没值
      row.status = btnText
      row.userId = user._id
      row.userName = user.name
      row.personResponsible = undefined
      row.personResponsibleName = undefined
      if (btnText === FORM_COMMIT_TEXT) {
        const date = new Date()
        row.process = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
      } else {
        row.process = latestProcess
      }
      if (FORM_PERSON_RESPONSIBLE_WHITELIST.includes(btnText)) {
        row.conca = `${user.name}${btnText}给${subRow.handlingRecordsName}。`
      } else {
        row.conca = `${user.name}${btnText}了。`
      }

      // 判断是否 先新增主表 后新增记录和通知表
      if (subRow.id) {

        let firstOperationSucceeded = false;
        try {
          await insertOrUpdate(subRow);
          firstOperationSucceeded = true;
        } catch (error) {
          message.warning(error.message)
        } finally {
          setProcessRecordsIsOpen(false)
          setProcessRecordsRow(undefined)
          setConfirmLoading(false)
          setIsModalVisible(false);
        }
        if (!firstOperationSucceeded) return
        await ProcessRecordsService.addProcessRecords(row)
        const rsp = await ProcessRecordsService.getProcessRecords({
          businessId: subRow.id,
          pageNum: 1,
          perPage: 999,
          type: TableName,
        })
        const notificationScopeUserId = rsp.items
          .map(o => o.userId)
          .concat(row.person)
          .filter((v, i, self) => self.indexOf(v) === i) // 去重
          .filter(id => id !== user._id) // 过滤出不是自己的数据
          .join(',') || ''
        const notificationScopeUserName = rsp.items
          .map(o => o.userName)
          .concat((row.personName || '').split(','))
          .filter((v, i, self) => self.indexOf(v) === i) // 去重
          .filter(name => name !== user.name) // 过滤出不是自己的数据
          .join(',') || ''
        await processStore.newAssignmentNotice({
          businessId: subRow.id,
          businessCode: subRow.wsCode,
          cooperationUnit: subRow.cooperationUnit || 'N/A',
          notificationScopeUserId,
          notificationScopeUserName,
          personResponsible: subRow.handlingRecords,
          personResponsibleName: subRow.handlingRecordsName,
          responsibilityProject: subRow.responsibilityProject,
          type: "工作日程",
          wnDesc: `您参与办理的工作日程（${subRow.wsCode}），${row.conca} 请知悉！`,
          wnStatus: "未读"
        })

      } else {

        let subRsp: ResponseEntity<WorkScheduleRow>
        try {
          subRsp = await insertOrUpdate(subRow)
        } catch (error) {
          message.warning(error.message)
        } finally {
          setProcessRecordsIsOpen(false)
          setProcessRecordsRow(undefined)
          setConfirmLoading(false)
          setIsModalVisible(false);
        }

        if (subRsp.data) {
          // 重新赋值再提交
          row.businessId = subRsp.data.id
          await ProcessRecordsService.addProcessRecords(row)
          const rsp = await ProcessRecordsService.getProcessRecords({
            businessId: subRsp.data.id,
            pageNum: 1,
            perPage: 999,
            type: TableName,
          })
          const notificationScopeUserId = rsp.items
            .map(o => o.userId)
            .concat(row.person)
            .filter((v, i, self) => self.indexOf(v) === i) // 去重
            .filter(id => id !== user._id) // 过滤出不是自己的数据
            .join(',') || ''
          const notificationScopeUserName = rsp.items
            .map(o => o.userName)
            .concat((row.personName || '').split(','))
            .filter((v, i, self) => self.indexOf(v) === i) // 去重
            .filter(name => name !== user.name) // 过滤出不是自己的数据
            .join(',') || ''
          await processStore.newAssignmentNotice({
            businessId: subRsp.data.id,
            businessCode: subRsp.data.wsCode,
            cooperationUnit: subRsp.data.cooperationUnit || 'N/A',
            notificationScopeUserId,
            notificationScopeUserName,
            personResponsible: subRsp.data.handlingRecords,
            personResponsibleName: subRsp.data.handlingRecordsName,
            responsibilityProject: subRsp.data.responsibilityProject,
            type: "工作日程",
            wnDesc: `您参与办理的工作日程（${subRsp.data.wsCode}），${row.conca} 请知悉！`,
            wnStatus: "未读"
          })
        } else {
          message.warning('主表保存成功，办理记录表和任务通知表提交失败')
        }

      }
      setProcessRecordsIsOpen(false)
      setProcessRecordsRow(undefined)
      setConfirmLoading(false)
      setIsModalVisible(false);

    }).catch(logger.warn)
  }

  /**
   * 操作记录按钮
   */
  const handleProcessRecordsClick = async (record: WorkScheduleRow) => {
    const rsp = await ProcessRecordsService.getProcessRecords({
      businessId: record.id,
      pageNum: 1,
      perPage: 999,
      type: TableName
    })
    if (!rsp) {
      message.warning('查无数据')
      return
    }
    setProcessRecordsDataSource(rsp.items)
    setProcessRecordsOpen(true)
  }

  const handlePrint = async (record: WorkScheduleRow) => {
    const list = await workScheduleStore.getPrintList(record)
    if (list) {
      setContentList(list)
      setIsModalOpen(true)
    } else {
      message.warning('请检查')
    }
  }

  return (
    <div className={"institution"}>
      <HeaderActionCom
        size={props.isModalSelect ? 6 : undefined}
        locationPathname={props.locationPathname}
        isHiddenOptions={props.isHiddenOptions}
        updateCount={updateCount}
        collapsed={workScheduleStore.collapsed}
        setCollapsed={handleCollapsedChange}
        searchReq={searchReq}
        selectedRowKeys={selectedRowKeys}
        handleShowColumnReset={handleShowColumnReset}
        handleDeleteBatch={handleDeleteBatch}
        handleAdd={handleAdd}
        handleTableColumnsChange={handleTableColumnsChange}
        showColumn={showColumn}
        tableColumns={props.isModalSelect ? columns.filter(o => !blackColumnList.includes(o.key as string)) : columns}
        refreshData={props.dataSource ? undefined : (req) => refreshData({...searchReq, ...req})}></HeaderActionCom>

      <Table
        className={'margin-top-xss'}
        rowKey={"id"}
        dataSource={dataSource}
        columns={renderColumns}
        size={"middle"}
        loading={workScheduleStore.workScheduleDatasource.loading || confirmLoading}
        bordered
        scroll={{y: scrollHeight, scrollToFirstRowOnChange: true}}
        pagination={{
          total: total,
          position: ['bottomCenter'],
          pageSize: searchReq.perPage,
          current: searchReq.pageNum,
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: total => `共${total}条`,
          onChange: handlePageChange
        }}
        rowSelection={{selectedRowKeys, onChange: setSelectedRowKeys, columnWidth: 50}}/>

      { /*操作记录表*/ }
      <CustomDraggableModal
        title={<div className="text-align-center">办理记录</div>}
        open={processRecordsOpen}
        width={LARGE_MODAL_WIDTH_OTHER}
        onCancel={() => setProcessRecordsOpen(false)}
        destroyOnClose
        footer={null}>
        <ProcessRecords
          isHiddenOptions
          dataSource={processRecordsDataSource}
          isModalSelect
          locationPathname={'/processRecords'}/>
      </CustomDraggableModal>

      { /*操作记录表单*/ }
      <CustomDraggableModal
        title={<div className="text-align-center">办理记录</div>}
        open={processRecordsIsOpen}
        width={LARGE_MODAL_WIDTH}
        destroyOnClose
        onCancel={() => {
          setProcessRecordsIsOpen(false)
          setConfirmLoading(false)
        }}
        onOk={handleSubmit}>
        <ProcessRecordsBasicInfo
          tableName={TableName}
          businessId={workScheduleRow?.id}
          submitterId={submitterId}
          userId={workScheduleRow?.handlingRecords}
          status={workScheduleRow?.status}
          row={processRecordsRow}
          ref={processRecordsBasicInfo}/>
      </CustomDraggableModal>

      <CustomForm
        title={'工作日程'}
        handleCancel={handleCancel}
        handleOk={handleOk}
        submitterId={submitterId}
        isModalVisible={isModalVisible}
        userId={workScheduleRow?.handlingRecords}
        confirmLoading={confirmLoading}
        extraDisabled={!workScheduleRow?.id}
        extraOnClick={() => handlePrint(workScheduleRow)}
        status={workScheduleRow?.status}
        item={[
          {
            key: 'handleProcessRecordsClick',
            icon: <FieldTimeOutlined/>,
            disabled: !workScheduleRow?.id,
            onClick: () => handleProcessRecordsClick(workScheduleRow)
          },
        ]}>
        <BasicInfo row={{...workScheduleRow!}} ref={basicInfo} isView={isView}/>
      </CustomForm>

      <WindowPrint
        isHiddenTime
        title={"工作日程"}
        contentList={contentList}
        isModalOpen={isModalOpen}
        handleCancel={() => setIsModalOpen(false)}/>
    </div>
  )
}))
