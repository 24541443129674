import React, {useEffect, useState} from "react";
import {Col, DatePicker, Form, Input, InputNumber, Row} from "antd";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import {SelectProject} from "@components/SelectProject";
import {SelectDict} from "@components/SelectDict";
import {SelectPersonCharge} from "@components/SelectPersonCharge";
import dayjs from "dayjs";
import {SelectCooperativeUnit} from "@components/SelectCooperativeUnit";
import {allStores} from "@/stores";
import {ProjectRow} from "@services/dto/project";
import {SelectFiling} from "@components/SelectFiling";
import {TaxPlanRow} from "@services/dto/taxPlan";
import {SelectProcessStatus} from "@components/SelectProcessStatus";
import {FORM_DISABLED_STATE} from "@/config";

export interface PropsType {
  row: TaxPlanRow
  isView?: boolean
  isBatchUpdate?: boolean
}

export const BasicInfo = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, ref) => {
  const [form] = Form.useForm()
  const {filingStore} = allStores
  const [responsibilityProject, setResponsibilityProject] = useState<string>()
  const [cooperationUnit, setCooperationUnit] = useState<string | string[]>()

  useEffect(() => {
    form.resetFields()
    const taxPlanRow = {...props.row}
    filingStore.setFilingFaCode(taxPlanRow.efaApplyCode)
    if (typeof taxPlanRow.efaAccountingObject === 'string' && taxPlanRow.efaAccountingObject !== '') {
      taxPlanRow.efaAccountingObject = dayjs(taxPlanRow.efaAccountingObject)
    }
    if (typeof taxPlanRow.cooperationUnit === 'string') {
      taxPlanRow.cooperationUnit = taxPlanRow.cooperationUnit !== '' ? taxPlanRow.cooperationUnit.split(',') : []
    }
    setResponsibilityProject(taxPlanRow.responsibilityProject)
    setCooperationUnit(taxPlanRow.cooperationUnit)
    form.setFieldsValue(taxPlanRow);
  }, [props.row.id])

  /**
   * @desc 责任部门修改 code拿到并赋值
   * @param value
   * @param options
   */
  const handleProjectChange = (value: any, options: ProjectRow[]) => {
    setResponsibilityProject(value)
    const row = options.find(o => value === o.name)
    form.setFieldsValue({code: row?.code})
  }

  return (
    <Form
      name="basic"
      ref={ref}
      form={form}
      labelCol={{span: 6}}
      wrapperCol={{span: 16}}
      disabled={props.isView || FORM_DISABLED_STATE.includes(props.row.efaStatus)}
      initialValues={{
        efaAmountPayout: 0,
        efaBalance: 0,
        cooperationUnit: ['N/A']
      }}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Form.Item hidden name="id">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="code">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="personResponsibleName">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="organizersUserName">
          <Input/>
        </Form.Item>
        <Col span={24}>
          <Form.Item
            label="制单日期"
            name="efaAccountingObject"
            rules={[{required: false, message: '请选择制单日期!'}]}>
            <DatePicker disabled placeholder={"请输入制单日期"} className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="制单编号"
            name="efaApplyCode"
            rules={[{required: false, message: '请输入制单编号!'}]}>
            <Input disabled placeholder="请输入制单编号"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="税费类别"
            name="efaPayType"
            rules={[{required: false, message: '请选择税费类别!'}]}>
            <SelectDict code={'tax_plan_type'} placeholder={'请选择税费类别'}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="税费说明"
            name="efaTitle"
            rules={[{required: false, message: '请输入税费说明!'}]}>
            <Input.TextArea placeholder="请输入税费说明" autoSize={{minRows: 3, maxRows: 9}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="关联立项"
            name="efaRelatedProjectApproval"
            rules={[{required: false, message: '请输入关联立项!'}]}>
            <Input placeholder="请输入关联立项"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="计划金额"
            name="efaApplyAmount"
            rules={[{required: false, message: '请输入计划金额!'}]}>
            <InputNumber min={0} precision={2} placeholder="请输入计划金额" className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="实现金额"
            name="efaAmountPayout"
            rules={[{required: false, message: '请输入实现金额!'}]}>
            <InputNumber
              min={0} precision={2} disabled placeholder="请输入实现金额"
              className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="余额"
            name="efaBalance"
            rules={[{required: false, message: '请输入金额!'}]}>
            <InputNumber min={0} precision={2} disabled placeholder="请输入金额" className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="办理人员"
            name="personResponsible"
            rules={[{required: false, message: '请选择办理人员!'}]}>
            <SelectPersonCharge
              className={"width-100-percentage"}
              placeholder={"请选择办理人员"}
              disabled
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="主办人员"
            name="organizersUserId"
            rules={[{required: false, message: '请选择主办人员!'}]}>
            <SelectPersonCharge
              className={"width-100-percentage"}
              placeholder={"请选择主办人员"}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="责任部门"
            name="responsibilityProject"
            rules={[{required: !props.isBatchUpdate, message: '请选择责任部门!'}]}>
            <SelectProject
              placeholder={"请选择责任部门"} allowClear onChange={handleProjectChange}
              fieldNames={{value: 'name', label: 'name'}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="协作单位"
            name="cooperationUnit"
            rules={[{required: !props.isBatchUpdate, message: '请输入协作单位!'}]}>
            <SelectCooperativeUnit
              allowClear
              mode={'multiple'}
              onChange={(value) => setCooperationUnit(value)}
              fieldNames={{value: 'cuName', label: 'cuName'}}/>
          </Form.Item>
        </Col>
        {
          props.row.id && <Col span={24}>
            <Form.Item
              label="存档批号"
              name="efaFileArchive"
              rules={[{required: false, message: '请选择存档批号!'}]}>
              <SelectFiling
                responsibilityProject={responsibilityProject}
                cooperationUnit={cooperationUnit}
                placeholder={"请选择存档批号"}
                inputDisabled
                isHideClose
                isHidePlus
                fieldNames={{value: "id", label: "faBatchNumber"}}
                allowClear/>
            </Form.Item>
          </Col>
        }
        <Col span={24}>
          <Form.Item
            label="办理进度"
            name="efaStatus"
            rules={[{required: false, message: '请选择办理进度!'}]}>
            <SelectProcessStatus
              allowClear
              placeholder={"请选择办理进度"}
              disabled/>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}))
