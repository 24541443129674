import React, {useEffect, useState} from "react";
import {Col, DatePicker, Form, Input, Row, TabsProps} from "antd";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import dayjs from "dayjs";
import {FilingRow} from "@services/dto/filing";
import {SelectProject} from "@components/SelectProject";
import {SelectCooperativeUnit} from "@components/SelectCooperativeUnit";
import {CustomUpload, normFile} from "@components/CustomUpload";
import {getUploadStringToArray} from "@common/utils";
import {useLocation} from "react-router-dom";
import {SelectDict} from "@components/SelectDict";
import {allStores} from "@/stores";
import {SelectPersonCharge} from "@components/SelectPersonCharge";
import {FORM_DISABLED_STATE} from "@/config";
import {SelectProcessStatus} from "@components/SelectProcessStatus";

export interface PropsType {
  row: FilingRow
  isView?: boolean
  isHideField?: boolean
  filingMode?: 'multiple' | 'tags'
}

export const BasicInfo = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, ref) => {
  const [form] = Form.useForm()
  const location = useLocation()
  const {tabsViewStore} = allStores
  const [mode, setMode] = useState<'multiple' | 'tags'>()

  useEffect(() => {
    form.resetFields()
    const filingRow = {...props.row}
    // 根据所在界面的单号字段
    if (filingRow.id) {
      /*if (typeof filingRow.faTime === 'string' && filingRow.faTime !== '') {
        filingRow.faTime = dayjs(filingRow.faTime)
      }*/
      if (typeof filingRow.faSaveDate === 'string' && filingRow.faSaveDate !== '') {
        filingRow.faSaveDate = dayjs(filingRow.faSaveDate)
      }
      if (typeof filingRow.efaAccountingObject === 'string') {
        filingRow.efaAccountingObject = getUploadStringToArray(filingRow.efaAccountingObject)
      }
      if (typeof filingRow.cooperationUnit === 'string') {
        const cooperationUnits = filingRow.cooperationUnit.split(",")
        if (cooperationUnits.length > 1) {
          filingRow.cooperationUnit = cooperationUnits
          setMode("multiple")
        }
      }
    } else {
      // 文件类别根据所在页面自动选择
      const tabsItems: TabsProps['items'] = tabsViewStore.tabsItems
      filingRow.faType = String(tabsItems?.find(o => o.key === location.pathname)?.label)
      if (filingRow.faType !== '文件存档') {
        filingRow.faName = filingRow.faType + '凭证'
      }
    }
    form.setFieldsValue(filingRow);
  }, [props.row])

  return (
    <Form
      name="basic"
      ref={ref}
      form={form}
      labelCol={{span: 6}}
      wrapperCol={{span: 16}}
      disabled={props.isView || FORM_DISABLED_STATE.includes(props.row.status)}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Form.Item hidden name="id">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="code">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="personResponsibleName">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="organizersUserName">
          <Input/>
        </Form.Item>
        <Col span={24}>
          <Form.Item
            label="文件编号"
            name="faCode"
            rules={[{required: false, message: '请输入文件编号!'}]}>
            <Input placeholder="请输入文件编号" disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="文件名称"
            name="faName"
            rules={[{required: false, message: '请选择文件名称!'}]}>
            <Input disabled placeholder="请选择文件名称"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="文件类别"
            name="faType"
            rules={[{required: false, message: '请选择文件类别!'}]}>
            <SelectDict
              disabled
              className={'width-100-percentage'}
              code={'fa_file_type'}
              placeholder={'请选择文件类别'}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="文件摘要"
            name="remark"
            rules={[{required: false, message: '请输入文件摘要!'}]}>
            <Input.TextArea disabled placeholder="请输入文件摘要" autoSize={{minRows: 3, maxRows: 9}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="电子文件"
            name="efaAccountingObject"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            extra={"支持文件(pdf,doc,docx),20M以内"}
            rules={[{required: false, message: '请输入电子文件!'}]}>
            <CustomUpload
              updateSize={20}
              maxCount={10}
              uploadType={"all"}
              // accept={"application/*"}
              listType={"picture"}
              multiple={true}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="存档批号"
            name="faBatchNumber"
            rules={[{required: false, message: '请输入存档批号!'}]}>
            <Input placeholder="请输入存档批号" disabled/>
          </Form.Item>
        </Col>
        {
          !props.isHideField && <>
            <Col span={24}>
              <Form.Item
                label="存档日期"
                name="faSaveDate"
                rules={[{required: false, message: '请选择存档日期!'}]}>
                <DatePicker placeholder={"请选择存档日期"} className={"width-100-percentage"}/>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="存档盒号"
                name="faSaveBox"
                rules={[{required: false, message: '请输入存档盒号!'}]}>
                <Input placeholder="请输入存档盒号"/>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="存档地点"
                name="faSaveAddress"
                rules={[{required: false, message: '请输入存档地点!'}]}>
                <Input placeholder="请输入存档地点"/>
              </Form.Item>
            </Col>
          </>
        }
        {
          !props.isHideField && <>
            { /*<Col span={24}>
              <Form.Item
                label="交件人"
                name="faSubmitter"
                rules={[{required: false, message: '请输入交件人!'}]}>
                <SelectPersonCharge
                  className={"width-100-percentage"}
                  placeholder={"请选择交件人"}
                  allowClear/>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="收件人"
                name="faRecipient"
                rules={[{required: false, message: '请输入收件人!'}]}>
                <SelectPersonCharge
                  className={"width-100-percentage"}
                  placeholder={"请选择交件人"}
                  allowClear/>
              </Form.Item>
            </Col>*/ }
            <Col span={24}>
              <Form.Item
                label="办理人员"
                name="personResponsible"
                rules={[{required: false, message: '请选择办理人员!'}]}>
                <SelectPersonCharge
                  disabled className={"width-100-percentage"} placeholder={"请选择办理人员"} allowClear/>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="主办人员"
                name="organizersUserId"
                rules={[{required: false, message: '请选择主办人员!'}]}>
                <SelectPersonCharge
                  className={"width-100-percentage"}
                  placeholder={"请选择主办人员"}
                  allowClear/>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="责任部门"
                name="responsibilityProject"
                rules={[{required: true, message: '请选择责任部门!'}]}>
                <SelectProject
                  placeholder={"请选择责任部门"}
                  allowClear
                  onChange={(value, options) => form.setFieldValue("code", options.find(item => item.name === value)?.code)}
                  fieldNames={{value: 'name', label: 'name'}}/>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="协作单位"
                name="cooperationUnit"
                rules={[{required: false, message: '请输入协作单位!'}]}>
                <SelectCooperativeUnit
                  disabled allowClear mode={props.filingMode || mode}
                  fieldNames={{value: 'cuName', label: 'cuName'}}/>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="办理进度"
                name="status"
                rules={[{required: false, message: '请选择办理进度!'}]}>
                <SelectProcessStatus
                  allowClear
                  placeholder={"请选择办理进度"}
                  disabled/>
              </Form.Item>
            </Col>
          </>
        }
      </Row>
    </Form>
  )
}))
