import {observer} from "mobx-react";
import './index.less'
import React, {useEffect, useLayoutEffect, useState} from "react";
import {FormInstance, message, Modal, Space, Table, Tag, Typography} from "antd";
import {allStores} from "@/stores";
import {ColumnsType} from "antd/es/table";
import {accAdd, logger, parseURLParams, safeCompute, showErr} from "@common/utils";
import {BankStatementReq, BankStatementRow, BankStatementSummaryTotal} from "@services/dto/bankStatement";
import {BankStatementService} from "@services/BankStatementService";
import {BasicInfo} from "@modules/BankStatement/Components/BasicInfo";
import {headerSearchStore} from "@/layout/Stores/HeaderSearchStore";
import {TableEllipsisText} from "@components/TableEllipsisText";
import {TableMoneyText} from "@components/TableMoneyText";
import {HeaderActionCom} from "@modules/BankStatement/Components/HeaderActionCom";
import {useLocation, useNavigate} from "react-router-dom";
import {
  CloudDownloadOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  EllipsisOutlined,
  FieldTimeOutlined,
  PlusOutlined,
  PrinterOutlined,
  RetweetOutlined,
  UpOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import {BasicInfo as ProcessRecordsBasicInfo} from "@modules/ProcessRecords/Components/BasicInfo";
import {FundApprovalRow} from "@services/dto/fundApproval";
import {FundApprovalService} from "@services/FundApprovalService";
import {LabelValue, ResponseEntity} from "@services/dto/common";
import {ToolService} from "@services/ToolService";
import {FundApprovalModal} from "@modules/BankStatement/Components/FundApprovalModal";
import {WindowPrint} from "@modules/WindowPrint";
import {FundApprovalTableModal} from "@modules/BankStatement/Components/FundApprovalTableModal";
import {ProjectService} from "@/services/ProjectService";
import {TableFilingText} from "@components/TableFilingText";
import {
  APPROVE_FORM_TRANS_STATE,
  BASE_API_PATH,
  BLACK_LIST,
  CLOSE,
  DATE_FMT,
  DATE_SECONDS_FMT,
  DEFAULT_TABLE_SCROLL_HEIGHT,
  FORM_ALTERNATE_TEXT,
  FORM_CLOSE_TEXT,
  FORM_COMMIT_TEXT,
  FORM_DISABLED_STATE,
  FORM_HANDOVER_TEXT,
  FORM_PERSON_RESPONSIBLE_WHITELIST,
  FORM_RETURN_TEXT,
  FORM_REVOCATION_TEXT,
  LARGE_MODAL_WIDTH,
  LARGE_MODAL_WIDTH_OTHER,
  READY,
  TABLE_CELL_WIDTH_1_5x,
  TABLE_CELL_WIDTH_1x,
  TABLE_CELL_WIDTH_2x
} from "@/config";
import {CustomImport} from "@components/CustomImport/CustomImport";
import {ProcessRecordsRow} from "@services/dto/processRecords";
import {TableAuthOptions} from "@components/CustomAuthOptions";
import {ProcessRecordsService} from "@services/ProcessRecordsService";
import {CustomDraggableModal} from "@components/CustomDraggableModal";
import {ProcessRecords} from "@modules/ProcessRecords";
import {CustomForm} from "@components/CustomForm";
import {TableApplicationFormText} from "@components/TableApplicationFormText";

const wideShowColumn: string[] = [
  'bsDateM',
  // 'bsDate',
  'bsCode',
  'bsType',
  // 'bsSummary',
  'bsName',
  // 'baType1',
  'bsInAmount-summation',
  'bsOutAmount-summation',
  'bsAmount-summation',
  'bsOtherAccountName',
  // 'fundingApprovalCode',
  // 'fundingApprovalCodeStatus',
  // 'personResponsibleName',
  // 'responsibilityProject',
  // 'cooperationUnit',
  // 'bsFile',
  'bsBalance',
  'bsStatus',
  'action',
]

const narrowShowColumn: string[] = [
  'bsDateM',
  // 'bsDate',
  'bsCode',
  'bsType',
  // 'bsSummary',
  'bsName',
  // 'baType1',
  // 'bsInAmount-summation',
  // 'bsOutAmount-summation',
  // 'bsAmount-summation',
  'bsOtherAccountName',
  // 'fundingApprovalCode',
  // 'fundingApprovalCodeStatus',
  // 'personResponsibleName',
  // 'responsibilityProject',
  // 'cooperationUnit',
  // 'bsFile',
  // 'bsBalance',
  'bsStatus',
  'action',
]

export interface PropsType {
  isModalSelect?: boolean // 拟态宽
  locationPathname?: string // 路由
  isHiddenOptions?: boolean
  dataSource?: BankStatementRow[] // 数据源
}

export const TableName = 'bankStatement'

export const BankStatement = observer(React.forwardRef<FormInstance, PropsType>((props, _) => {
  const navigate = useNavigate()
  const location = useLocation()
  const {
    processStore,
    securityStore,
    bankStatementStore,
    screenResolutionStore,
    tableColumnsStore,
    fundApprovalStore
  } = allStores
  const basicInfo = React.createRef<FormInstance>();
  const processRecordsBasicInfo = React.createRef<FormInstance>();
  const [dataSource, setDataSource] = useState<BankStatementRow[]>([])
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [total, setTotal] = useState<number>(0)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isBatchUpdate, setIsBatchUpdate] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [bankStatementRow, setBankStatementRow] = useState<BankStatementRow>()
  const [isView, setIsView] = useState<boolean>(false)
  const [scrollHeight, setScrollHeight] = useState<number>(DEFAULT_TABLE_SCROLL_HEIGHT)
  const [renderColumns, setRenderColumns] = useState<ColumnsType<BankStatementRow>>([])
  const [showColumn, setShowColumn] = useState<string[]>(wideShowColumn)
  const [FundApprovalModalOpen, setFundApprovalModalOpen] = useState<boolean>(false)
  const [fundApprovalModalDataSource, setFundApprovalModalDataSource] = useState<FundApprovalRow[]>([])
  const [updateCount, setUpdateCount] = useState<number>(0)
  const [printTitle, setPrintTitle] = useState<string>('')
  const [isPrintTable, setIsPrintTable] = useState<boolean>(false)
  const [printDataSource, setPrintDataSource] = useState<BankStatementRow[]>([])
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [contentList, setContentList] = useState<LabelValue[]>([]);
  const [FundApprovalTableModalOpen, setFundApprovalTableModalOpen] = useState<boolean>(false)
  const [FundApprovalTableModalLoading, setFundApprovalTableModalLoading] = useState<boolean>(false)
  const [processRecordsIsOpen, setProcessRecordsIsOpen] = useState<boolean>(false)
  const [processRecordsRow, setProcessRecordsRow] = useState<ProcessRecordsRow>()
  const [processRecordsOpen, setProcessRecordsOpen] = useState<boolean>(false)
  const [processRecordsDataSource, setProcessRecordsDataSource] = useState<ProcessRecordsRow[]>([])
  const [submitRow, setSubmitRow] = useState<BankStatementRow>()
  const [submitterId, setSubmitterId] = useState<string>()
  const [latestProcess, setLatestProcess] = useState<string>()
  const [latestProcessBatch, setLatestProcessBatch] = useState<string[]>()
  const [summaryTotalObj, setSummaryTotalObj] = useState<BankStatementSummaryTotal>({
    bsInAmount: 0,
    bsOutAmount: 0,
    bsAmount: 0,
  })
  const [searchReq, setSearchReq] = useState<BankStatementReq>({
    pageNum: 1,
    perPage: 10,
    cooperationUnit: headerSearchStore.headerSearchReq.cooperationUnit,
    responsibilityProject: headerSearchStore.headerSearchReq.responsibilityProject,
    ...bankStatementStore.bankStatementReq,
    isBind: undefined,
  })

  const columns: ColumnsType<BankStatementRow> = [
    {
      title: '制单日期',
      dataIndex: 'bsDateM',
      key: 'bsDateM',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '制单编号',
      dataIndex: 'bsCode',
      key: 'bsCode',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '实现日期',
      dataIndex: 'bsDate',
      key: 'bsDate',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text?.slice(0, 10)}></TableEllipsisText>
        </Space>
      )
    },
    // {
    //   title: '出纳交易类型',
    //   dataIndex: 'bsType',
    //   key: 'bsType',
    //   align: 'center',
    //   width: TABLE_CELL_WIDTH_1_5x,
    //   render: (text) => (
    //     <Space>
    //       <TableEllipsisText value={text}></TableEllipsisText>
    //     </Space>
    //   )
    // },
    {
      title: '款项摘要',
      dataIndex: 'bsSummary',
      key: 'bsSummary',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '出纳账户',
      dataIndex: 'bsName',
      key: 'bsName',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '出纳账户类型',
      dataIndex: 'baType1',
      key: 'baType1',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '转入金额(元)',
      dataIndex: 'bsInAmount',
      key: 'bsInAmount-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '转出金额(元)',
      dataIndex: 'bsOutAmount',
      key: 'bsOutAmount-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '余额',
      dataIndex: 'bsAmount',
      key: 'bsAmount-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '对方账户',
      dataIndex: 'bsOtherAccountName',
      key: 'bsOtherAccountName',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '关联资金审批',
      dataIndex: 'fundingApprovalCode',
      key: 'fundingApprovalCode',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text, record) => (
        <Space>
          <TableEllipsisText copyValue={text} value={
            <Typography.Text
              type="success"
              className={'c-pointer'}
              onClick={() => {
                handleFundingApprovalCodeClick(record)
              }}>{ text }</Typography.Text>
          }></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '关联申请类型',
      dataIndex: 'faApplicationFormType',
      key: 'faApplicationFormType',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (_, record) => (
        <Space>
          <TableEllipsisText
            value={record.faApplicationFormType ? record.faApplicationFormType : ''}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '关联申请编号',
      dataIndex: 'faApplicationForm',
      key: 'faApplicationForm',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (_, record) => (
        <Space>
          <TableApplicationFormText
            type={record.faApplicationFormType ? record.faApplicationFormType : ''}
            value={record.faApplicationForm?.split(',')}/>
        </Space>
      )
    },
    {
      title: '关联计划表单',
      dataIndex: 'play',
      key: 'play',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (_, record) => (
        <Space>
          <TableEllipsisText
            value={record?.planCode}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '部门余额',
      dataIndex: 'departmentBalance',
      key: 'departmentBalance',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText
            value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '资金审批办理进度',
      dataIndex: 'fundingApprovalCodeStatus',
      key: 'fundingApprovalCodeStatus',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (_, record) => (
        <Space>
          {
            record.fundingApprovalId.includes(',') ? <Tag color={'error'}>错误</Tag> : <Tag color={'blue'}>正常</Tag>
          }
        </Space>
      )
    },
    {
      title: '责任部门',
      dataIndex: 'responsibilityProject',
      key: 'responsibilityProject',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '协作单位',
      dataIndex: 'cooperationUnit',
      key: 'cooperationUnit',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '存档批号',
      dataIndex: 'bsFile',
      key: 'bsFile',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (_, record) => (
        <Space>
          <TableFilingText id={record.bsFile} value={record.bsFileCode}></TableFilingText>
        </Space>
      )
    },
    {
      title: '办理人员',
      dataIndex: 'personResponsibleName',
      key: 'personResponsibleName',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '主办人员',
      dataIndex: 'organizersUserName',
      key: 'organizersUserName',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '办理进度',
      dataIndex: 'bsStatus',
      key: 'bsStatus',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (_, record) => (
        <TableAuthOptions
          mainBtn={['handleEdit', 'handleView']}
          items={[
            {
              key: 'handleView',
              icon: <EllipsisOutlined/>,
              onClick: () => handleView(record)
            },
            {
              key: 'handleEdit',
              icon: <EditOutlined/>,
              disabled: FORM_DISABLED_STATE.includes(record.bsStatus),
              onClick: async () => await handleEdit(record)
            },
            {
              key: 'handleUpClick',
              icon: <UpOutlined/>,
              onClick: () => handleUpClick(record)
            },
            {
              key: 'handleDownClick',
              icon: <DownOutlined/>,
              onClick: () => handleDownClick(record)
            },
            {
              key: 'handlePrint',
              icon: <PrinterOutlined/>,
              onClick: () => handlePrint(record)
            },
            {
              key: 'handleDeleteBatch',
              icon: <DeleteOutlined/>,
              disabled: FORM_DISABLED_STATE.includes(record.bsStatus),
              onClick: () => handleDeleteBatch(record)
            },
          ]}></TableAuthOptions>
      )
    }
  ];

  /**
   * @description 初始化
   */
  useEffect(() => {

    // 读取存储
    let showColumn: string[] = tableColumnsStore.getItem(props.locationPathname || location.pathname) || []
    if (showColumn.length) {
      // 检验存储中的列表是否符合规范
      showColumn = showColumn.filter(o => columns.some(column => column.key === o))
      // 无论有无存储都触发change，保存当前显示的字段
      setShowColumn(showColumn)
    } else {
      // 窄屏判断
      if (screenResolutionStore.screenResolution.height < 700 || screenResolutionStore.screenResolution.width < 1400) {
        setShowColumn(narrowShowColumn)
      }
    }

    // 当作为组件在其他页面使用且传了数据时 不请求数据
    if (!props.dataSource) {
      refreshData(searchReq)
    }
  }, [])

  /**
   * @description 全局查询
   */
  useEffect(() => {
    const req = {...searchReq}
    if (req.cooperationUnit !== headerSearchStore.headerSearchReq.cooperationUnit || req.responsibilityProject !== headerSearchStore.headerSearchReq.responsibilityProject) {
      req.cooperationUnit = headerSearchStore.headerSearchReq.cooperationUnit
      req.responsibilityProject = headerSearchStore.headerSearchReq.responsibilityProject
      setSearchReq(req)
      refreshData(req)
    }
  }, [headerSearchStore.headerSearchReq])

  /**
   * @description 表格数据变化
   */
  useEffect(() => {
    if (bankStatementStore.bankStatementDatasource.data) {
      setDataSource(bankStatementStore.bankStatementDatasource.data.items || []);
      setTotal(bankStatementStore.bankStatementDatasource.data.total || 0)
    }
  }, [bankStatementStore.bankStatementDatasource.data])

  /**
   * @description 表单数据变化
   */
  useEffect(() => {
    if (bankStatementStore.bankStatementRow?.fundingApprovalId) {
      handleAdd(bankStatementStore.bankStatementRow)
      bankStatementStore.updateBankStatementRow({...bankStatementStore.bankStatementRow, fundingApprovalId: ''})
    }
  }, [bankStatementStore.bankStatementRow])

  useEffect(() => {
    if (bankStatementStore.bankStatementOption) {
      const [option, paramsStr] = bankStatementStore.bankStatementOption.split("?")
      if (option === 'add') {
        handleAdd()
      } else if (paramsStr) {
        const params = parseURLParams(paramsStr)
        if (option === 'edit') {
          BankStatementService.getOne(params.id).then(rsp => {
            if (rsp.code === 200) {
              handleEdit(rsp.data).then()
            }
          })
        }
      }
      bankStatementStore.updateBankStatementOption(undefined)
    }
  }, [bankStatementStore.bankStatementOption])

  /**
   * @description 表格合计
   */
  useEffect(() => {
    const objInitValue = {bsInAmount: 0, bsOutAmount: 0, bsAmount: 0,}
    let obj = summaryTotalObj
    const datasource = props.dataSource ? props.dataSource : bankStatementStore.bankStatementDatasource.data?.items
    const arr = datasource?.filter(o => selectedRowKeys.includes(o.id))
    if (arr?.length) {
      obj = arr.reduce((acc, cur) => ({
        bsInAmount: accAdd(acc.bsInAmount, cur.bsInAmount),
        bsOutAmount: accAdd(acc.bsOutAmount, cur.bsOutAmount),
        bsAmount: accAdd(acc.bsAmount, cur.bsAmount),
      }), objInitValue);

      // 余额bsInAmount = 转入bsInAmount - 转出bsOutAmount
      obj.bsAmount = obj.bsInAmount - obj.bsOutAmount
      setSummaryTotalObj(obj)
    } else {
      setSummaryTotalObj(objInitValue)
    }
  }, [bankStatementStore.bankStatementDatasource, selectedRowKeys])

  /**
   * 列配置变化
   */
  useEffect(() => {
    if (props.isModalSelect) {
      setRenderColumns(columns.filter(o => o.key !== 'action').filter(o => showColumn.includes(String(o.key))));
    } else {
      setRenderColumns(columns.filter(o => showColumn.includes(String(o.key))));
    }
  }, [showColumn])

  /**
   * 动态修改表格高度
   */
  useLayoutEffect(() => {
    const othersHeight = 10 + 5 + 5
    const parent = document.querySelector(".tabs-content")
    const headAction = document.querySelector(".header-action-row")
    const thead = document.querySelector(".ant-table-thead")
    const summary = document.querySelector('.ant-table-summary')
    const pagination = document.querySelector('.ant-pagination')
    const paginationHeight = pagination ? pagination.clientHeight + 16 * 2 : 0
    setScrollHeight(safeCompute(parent?.clientHeight) - safeCompute(headAction?.clientHeight) - safeCompute(thead?.clientHeight) - paginationHeight - safeCompute(summary?.clientHeight) - othersHeight)
  }, [screenResolutionStore.screenResolution.height, bankStatementStore.collapsed, searchReq.pageNum, searchReq.perPage])

  const handleFundApprovalClick = async (record: BankStatementRow) => {

    // 获取详情
    const {code: detailCode, data: row, message: detailMessage} = await BankStatementService.getOne(record.id)
    if (detailCode !== 200) {
      message.warning(detailMessage).then()
    }

    // 1.转入大于0，出纳账户是收款账户，对方账户是付款账户，转入金额是资金审批金额，出纳日期就是实现日期，
    //                    资金审批的制单编号返回资金出纳的关联资金审批，责任人、协作单位、责任部门一致
    // 转出大于0，对方账户是付款账户，出纳账户是收款账户，转出金额是资金审批金额，资金审批的制单编号返回资金出纳的关联资金审批，责任人、协作单位、责任部门一致

    if (!row.responsibilityProject) {
      message.warning("请检查责任部门！").then()
      return
    }
    const names: string[] = typeof row.responsibilityProject === 'object' ? row.responsibilityProject : row.responsibilityProject.split(',')
    const responsibilityProject = await ProjectService.getBatchByName(names)
    if (typeof responsibilityProject === 'object' && responsibilityProject.length !== names.length) {
      message.warning("请检查责任部门！").then()
      return
    }
    const code = responsibilityProject
      .map(o => o.code)
      .filter(o => o)
      .filter((v, i, self) => self.indexOf(v) === i)
      .join(',')

    fundApprovalStore.updateFundApprovalRow({
      id: undefined,
      fundApprovalId: row.id,
      bankStatementIds: row.id, // 资金出纳id
      bankStatementCodes: undefined,
      faDate: dayjs(new Date().toDateString()), // 制单日期
      faCode: undefined, // 制单编号
      faType: undefined, // 款项类别
      faSummary: row.bsSummary, // 款项摘要
      faAmount: row.bsInAmount > 0 ? row.bsInAmount : row.bsOutAmount, // 金额

      faPaymentAccount: row.bsInAmount > 0 ? row.bsOtherAccountName : row.bsName,
      faPaymentAccountId: row.bsInAmount > 0 ? row.bsOtherAccountId : row.bankAccountId,
      baName1: row.bsInAmount > 0 ? row.baName2 : row.baName1,
      baAccount1: row.bsInAmount > 0 ? row.bsAccount : row.baAccount1,
      baBlank1: row.bsInAmount > 0 ? row.baBlank2 : row.baBlank1,

      faAccountsReceivable: row.bsInAmount > 0 ? row.bsName : row.bsOtherAccountName,
      faAccountsReceivableId: row.bsInAmount > 0 ? row.bankAccountId : row.bsOtherAccountId,
      baName2: row.bsInAmount > 0 ? row.baName1 : row.baName2,
      baAccount2: row.bsInAmount > 0 ? row.baAccount1 : row.bsAccount,
      baBlank2: row.bsInAmount > 0 ? row.baBlank1 : row.baBlank2,

      faPayDate: row.bsDate ? dayjs(row.bsDate) : row.bsDate, // 实现日期
      faStatus: undefined, // 办理进度
      faApplicationForm: undefined, // 关联申请id
      faApplicationFormType: undefined, // 关联申请类型
      contractListIds: [],// 关联合同 商务合同
      contractLists: [],// 关联合同
      lnvoiceRegistration: [], // 关联发票登记
      lnvoiceRegistrationIds: [], // 关联发票登记
      personResponsible: row.personResponsible, // 办理人员
      personResponsibleName: row.personResponsibleName, // 办理人员
      organizersUserId: undefined,
      organizersUserName: undefined,
      responsibilityProject: typeof row.responsibilityProject === 'string' ? row.responsibilityProject : row.responsibilityProject.join(','), // 责任部门
      cooperationUnit: ['N/A'], // 协作单位
      faFile: undefined, // 存档批号
      faFileList: undefined, // 上传文件
      code // 责任部门编号
    })
    navigate("/fundApproval")
  }

  const handlePageChange = (page: number, pageSize: number) => {
    let req = {...searchReq};
    req.perPage = pageSize
    req.pageNum = page
    setSearchReq(req)
    refreshData(req)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const refreshData = (req: BankStatementReq) => {
    setSearchReq(req)
    bankStatementStore.getBankStatement(req)
  }

  const handleUpClick = async (record: BankStatementRow) => {
    const index = bankStatementStore.bankStatementDatasource.data?.items.findIndex(o => o.id === record.id)
    if (index === 0) {
      message.warning('已经无法上移')
      return
    }
    const upRow = bankStatementStore.bankStatementDatasource.data?.items[index - 1]
    const row = bankStatementStore.bankStatementDatasource.data?.items[index]
    const tempSort = upRow.sort
    upRow.sort = row.sort
    row.sort = tempSort
    setConfirmLoading(true)
    const rsp = await BankStatementService.updateBankStatement(row)
    await actionThen('', rsp)
    const upRsp = await BankStatementService.updateBankStatement(upRow)
    await actionThen('', upRsp)
    setConfirmLoading(false)
    setUpdateCount(updateCount + 1)
  }

  const handleDownClick = async (record: BankStatementRow) => {
    const index = bankStatementStore.bankStatementDatasource.data?.items.findIndex(o => o.id === record.id)
    if (index === bankStatementStore.bankStatementDatasource.data?.items.length - 1) {
      message.warning('已经无法下移')
      return
    }
    const row = bankStatementStore.bankStatementDatasource.data?.items[index]
    const downRow = bankStatementStore.bankStatementDatasource.data?.items[index + 1]
    const tempSort = downRow.sort
    downRow.sort = row.sort
    row.sort = tempSort
    setConfirmLoading(true)
    const downRsp = await BankStatementService.updateBankStatement(downRow)
    actionThen('', downRsp).catch();
    const upRsp = await BankStatementService.updateBankStatement(row)
    actionThen('', upRsp).catch();
    setConfirmLoading(false)
    setUpdateCount(updateCount + 1)
  }

  const handleTableColumnsChange = (value: string[]) => {
    setShowColumn(value)
    tableColumnsStore.setItem(props.locationPathname || location.pathname, value)
  }

  /**
   * 资金审批编号点击详情
   * @param record
   */
  const handleFundingApprovalCodeClick = (record: BankStatementRow) => {
    let ids: string[] = (typeof record.fundingApprovalId === 'string' && record.fundingApprovalId !== '') ? record.fundingApprovalId.split(',') : []
    // 1. 去重
    // 2. 黑名单
    ids = ids.filter((v, i, self) => self.indexOf(v) === i)
      .filter(o => !BLACK_LIST.includes(o)) || []
    if (!ids.length) {
      message.warning("请检查审批编号！").then()
      return
    }
    FundApprovalService.getBatch(ids).then(rsp => {
      if (rsp.data.length) {
        setFundApprovalModalDataSource(rsp.data)
        setFundApprovalModalOpen(true)
      } else {
        message.warning("操作失败！").then()
      }
    }).catch(() => {
      message.warning("操作失败！").then()
    })
  }

  /**
   * 批量关联资金审批
   */
  const handleBatchRelatedFundApproval = () => {
    if (!selectedRowKeys.length) {
      message.warning("请勾选资金出纳").then();
      return;
    }
    setFundApprovalTableModalOpen(true)
  }

  /**
   * 批量关联资金审批确认按钮
   * @param keys
   */
  const handleFundApprovalTableModalOk = async (keys: React.Key[]) => {
    setFundApprovalTableModalLoading(true)
    let resList = []
    for (let i = 0; i < keys.length; i++) {
      message.loading(`请稍等！${i + 1}/${keys.length}`).then()
      const key = keys[i]
      const fundingApprovalId: string = key as string
      const rsp = await BankStatementService.bindD({
        FundingApprovalId: fundingApprovalId,
        BankStatementIds: selectedRowKeys as string[]
      })
      if (rsp) {
        resList.push(rsp)
      }
    }
    Modal.info({
      title: `温馨提示-批量关联资金审批`,
      content: <>{ resList.map(o => <div>{ o.message }</div>) }</>,
      okText: "确定",
      cancelText: "取消",
      onOk() {
        setFundApprovalTableModalOpen(false)
        setFundApprovalTableModalLoading(false)
        refreshData(bankStatementStore.bankStatementReq)
        setSelectedRowKeys([])
      },
    })
  }

  /**
   * @description 打印表格
   */
  const handlePrintTable = () => {
    // 数据处理
    setPrintDataSource(bankStatementStore.bankStatementDatasource.data?.items.filter(o => selectedRowKeys.includes(o.id)))
    setIsPrintTable(true)
    setPrintTitle('资金出纳表格')
    setIsModalOpen(true)
  }

  /**
   * 打印单条数据
   * @param record
   */
  const handlePrint = async (record: BankStatementRow) => {
    const list = await bankStatementStore.getPrintList(record)
    if (list) {
      setContentList(list)
      setPrintTitle('资金出纳单')
      setIsPrintTable(false)
      setIsModalOpen(true)
    } else {
      message.warning('请检查')
    }
  }

  const handleCollapsedChange = (collapsed: boolean) => {
    bankStatementStore.setCollapsed(collapsed)
  }

  const handleShowColumnReset = () => {
    // 窄屏判断
    if (screenResolutionStore.screenResolution.height < 700 || screenResolutionStore.screenResolution.width < 1400) {
      setShowColumn(narrowShowColumn)
    } else {
      setShowColumn(wideShowColumn)
    }
  }

  const handleDownloadTemp = () => {
    window.open(BASE_API_PATH + "/filepath/file/zjcn2.xlsx")
  }

  const batchUpdate = (row: BankStatementRow) => {
    // let flag = false
    const rows = bankStatementStore.bankStatementDatasource.data?.items.filter(o => selectedRowKeys.includes(o.id))
    for (let selectedRow of rows) {
      // if (selectedRow.bsStatus === ONFILE) {
      //   flag = true
      //   continue
      // }
      if (row.bsDate && typeof row.bsDate === 'object') {
        selectedRow.bsDate = row.bsDate.format(DATE_SECONDS_FMT);
      }
      if (row.bsDateM && typeof row.bsDateM === 'object') {
        selectedRow.bsDateM = row.bsDateM.format(DATE_FMT);
      }
      if (row.bsSummary) {
        selectedRow.bsSummary = row.bsSummary;
      }
      if (row.bankAccountId) {
        selectedRow.bsName = row.bsName;
        selectedRow.bankAccountId = row.bankAccountId;
        selectedRow.baName1 = row.baName1;
        selectedRow.baAccount1 = row.baAccount1;
        selectedRow.baBlank1 = row.baBlank1;
        selectedRow.baType1 = row.baType1;
      }
      if (row.bsOtherAccountId) {
        selectedRow.bsOtherAccountName = row.bsOtherAccountName;
        selectedRow.bsOtherAccountId = row.bsOtherAccountId;
        selectedRow.baName2 = row.baName2;
        selectedRow.bsAccount = row.bsAccount;
        selectedRow.baBlank2 = row.baBlank2;
        selectedRow.baType2 = row.baType2;
      }
      if (row.bsInAmount || row.bsInAmount === 0) {
        selectedRow.bsInAmount = row.bsInAmount
      }
      if (row.bsOutAmount || row.bsOutAmount === 0) {
        selectedRow.bsOutAmount = row.bsOutAmount
      }
      if (row.fundingApprovalId) {
        selectedRow.fundingApprovalId = row.fundingApprovalId
      }
      if (row.fundingApprovalCode) {
        selectedRow.fundingApprovalCode = row.fundingApprovalCode
      }
      if (row.personResponsible) {
        selectedRow.personResponsible = row.personResponsible
        selectedRow.personResponsibleName = row.personResponsibleName
      }
      if (row.responsibilityProject) {
        selectedRow.code = row.code
        selectedRow.responsibilityProject = row.responsibilityProject
      }
      if (row.bsFile) {
        selectedRow.bsFile = row.bsFile
      }
      if (row.bsStatus) {
        selectedRow.bsStatus = row.bsStatus
      }
      if (typeof row.cooperationUnit === 'object') {
        selectedRow.cooperationUnit = []
        if (row.bankAccountId && !BLACK_LIST.includes(row.bankAccountId)) {
          selectedRow.cooperationUnit.push(row.baName1)
        }
        if (row.bsOtherAccountId && !BLACK_LIST.includes(row.bsOtherAccountId)) {
          selectedRow.cooperationUnit.push(row.baName2)
        }
        selectedRow.cooperationUnit = selectedRow.cooperationUnit
          .filter((v, i, self) => self.indexOf(v) === i) // 去重
          .filter(value => !(BLACK_LIST.includes(value))) // 去黑名单
          .join(',');
      }
    }
    // if (flag) {
    //   message.warning(`办理进度为${ONFILE}的数据不可编辑！`).then()
    // }
    BankStatementService.batchUpdateBankStatement(rows).then((rsp) => {
      actionThen("", rsp).then();
      setUpdateCount(updateCount + 1)
    }).catch(showErr).finally(() => setConfirmLoading(false));
  }

  // 新增
  // 资金审批发起
  // 资金审批批量发起
  const handleAdd = (row?: BankStatementRow) => {
    const user = securityStore.getLoggedUser()
    const initRow = {
      bsStatus: READY,
      personResponsible: user._id,
      personResponsibleName: user.name,
      organizersUserId: user._id,
      organizersUserName: user.name,
      bsDateM: dayjs(),
    } as BankStatementRow
    Object.assign(row || {}, initRow)
    setBankStatementRow(row || initRow)
    setIsView(false)
    setIsBatchUpdate(false)
    setIsModalVisible(true)
  }

  const handleView = (record: BankStatementRow) => {
    setBankStatementRow(record)
    setIsView(true)
    setIsBatchUpdate(false)
    setIsModalVisible(true);
  }

  const handleEdit = async (record: BankStatementRow) => {

    try {
      const rsp = await ProcessRecordsService.getProcessRecords({
        businessId: record.id,
        pageNum: 1,
        perPage: 999,
        type: TableName,
      })
      const process = rsp.items.length ? rsp.items[0].process : ''
      const userId = rsp.items.find(o => o.process === process && o.status === FORM_COMMIT_TEXT)?.userId
      const con = rsp.items.some(o => o.process === process && o.status === FORM_REVOCATION_TEXT)
      // 如果本轮有撤回 （id为空） 否则 （发起最新一轮的提交的人的id）
      setSubmitterId(con ? undefined : userId)
      setLatestProcess(process) // 发起最新一轮的标志

      setBankStatementRow(record)
      setIsView(false)
      setIsBatchUpdate(false)
      setIsModalVisible(true);
    } catch (err) {
      message.warning("办理记录查询失败！").then()
      logger.log(err)
    }

  }

  const handleEditBatch = async () => {

    try {

      if (selectedRowKeys.length === 0) {
        message.warning("请选择要操作的数据！").then()
        return
      }

      // 要操作的数据
      const rows = bankStatementStore.bankStatementDatasource.data?.items.filter(o => selectedRowKeys.includes(o.id))

      // 办理流程辅助字段：办理进度
      let bsStatus: string

      // 判断是否符合批量操作条件
      const con = rows.every((o, _, arr) => {
        const firstRow = arr[0]
        bsStatus = firstRow.bsStatus
        if (o.personResponsible !== firstRow.personResponsible) {
          message.warning("办理人员不一致！").then()
          return false
        } else if (o.bsStatus !== firstRow.bsStatus) {
          message.warning("办理进度不一致！").then()
          return false
        } else return true
      })

      if (con) {

        let latestProcessList: string[] = []
        let submitterIdList: string[] = []
        for (let i = 0; i < rows.length; i++) {
          const item = rows[i]
          const rsp = await ProcessRecordsService.getProcessRecords({
            businessId: item.id,
            pageNum: 1,
            perPage: 999,
            type: TableName,
          })
          const process = rsp.items.length ? rsp.items[0].process : ''
          const userId = rsp.items.find(o => o.process === process && o.status === FORM_COMMIT_TEXT)?.userId
          const userIdCon = rsp.items.some(o => o.process === process && o.status === FORM_REVOCATION_TEXT)
          latestProcessList.push(process)
          submitterIdList.push(userIdCon ? undefined : userId)
        }

        // 如果提交人全部一致才赋值给参数:【提交人】
        setSubmitterId(new Set(submitterIdList).size === 1 ? submitterIdList[0] : undefined)
        setLatestProcessBatch(latestProcessList)
        setIsView(false)
        setIsBatchUpdate(true)
        setBankStatementRow({bsStatus} as BankStatementRow)
        setIsModalVisible(true)

      }

    } catch (err) {
      message.warning("办理记录查询失败！").then()
      logger.log(err)
    }

  }

  const handleDeleteBatch = (record?: BankStatementRow) => {
    const keys = [...selectedRowKeys]
    if (!record && keys.length === 0) {
      message.warning("请选择要删除的数据！").then()
      return
    }
    let ids: string[] = []
    if (record) {
      ids.push(record.id)
    } else {
      ids = keys.map(item => String(item))
    }
    Modal.confirm({
      title: "是否删除？",
      okText: "确定",
      cancelText: "取消",
      onOk() {
        setConfirmLoading(true)
        BankStatementService.deleteBankStatement(ids).then(() => {
          message.success("删除成功！").then()
          setConfirmLoading(false)
          refreshData(bankStatementStore.bankStatementReq)
        }).catch(showErr).finally(() => setConfirmLoading(false))
      },
    })
  }

  const insertOrUpdate = async (row: BankStatementRow) => {
    if (row.bsDate && typeof row.bsDate === 'object') {
      row.bsDate = row.bsDate.format(DATE_SECONDS_FMT);
    }
    if (row.bsDateM && typeof row.bsDateM === 'object') {
      row.bsDateM = row.bsDateM.format(DATE_FMT);
    }
    if (typeof row.fundingApprovalCode === 'object' && row.fundingApprovalCode) {
      row.fundingApprovalCode = row.fundingApprovalCode.join(',');
    }
    if (typeof row.fundingApprovalId === 'object' && row.fundingApprovalId) {
      row.fundingApprovalId = row.fundingApprovalId.join(',');
    }
    if (typeof row.responsibilityProject === 'object' && row.responsibilityProject) {
      row.responsibilityProject = row.responsibilityProject.join(',');
    }
    if (typeof row.cooperationUnit === 'object') {
      row.cooperationUnit = []
      if (row.bankAccountId && !BLACK_LIST.includes(row.bankAccountId)) {
        row.cooperationUnit.push(row.baName1)
      }
      if (row.bsOtherAccountId && !BLACK_LIST.includes(row.bsOtherAccountId)) {
        row.cooperationUnit.push(row.baName2)
      }
      row.cooperationUnit = row.cooperationUnit
        .filter((v, i, self) => self.indexOf(v) === i) // 去重
        .filter(value => !(BLACK_LIST.includes(value))) // 去黑名单
        .join(',');
    }
    if (row.id) {
      const rsp = await BankStatementService.updateBankStatement(row)
      setUpdateCount(updateCount + 1)
      setConfirmLoading(false)
      await actionThen('', rsp)
      return rsp
    } else {
      const rsp = await BankStatementService.addBankStatement(row)
      setUpdateCount(updateCount + 1)
      setConfirmLoading(false)
      await actionThen('', rsp)
      return rsp
    }
  }

  const actionThen = async (ids: string, rsp: ResponseEntity<any>) => {

    try {
      if (ids) {
        await ToolService.submittedSuccess(ids, rsp.data.id)
      }
      if (rsp.code === 200) {
        message.success("操作成功！").then()
      } else {
        message.warning(rsp.message).then()
      }
      setIsModalVisible(false)
      setIsBatchUpdate(false)
      refreshData(bankStatementStore.bankStatementReq)
      setSelectedRowKeys([])
    } catch (e) {
      showErr(e)
    }

  }

  const handleOk = (open: boolean) => {
    const row = {...bankStatementRow!}
    basicInfo.current!.validateFields().then(async (values) => {
      Object.assign(row, values)

      if (open) {

        // 将row和按钮先存起来
        setSubmitRow(row)

        // 弹窗选择处理人
        setProcessRecordsIsOpen(true)
        setProcessRecordsRow(undefined)

      } else {

        const user = securityStore.getLoggedUser()

        if (isBatchUpdate) {
          batchUpdate(row)
        } else {
          // 默认处理人员是自己
          if (!row.id) {
            row.personResponsible = user._id
            row.personResponsibleName = user.name
          }

          try {
            await insertOrUpdate(row)
          } catch (error) {
            setProcessRecordsIsOpen(false)
            setProcessRecordsRow(undefined)
            setConfirmLoading(false)
            setIsModalVisible(false);
            message.warning(error.message)
          }
        }

      }

    }).catch(logger.warn)
  }

  const handleSubmit = () => {
    const row = {...processRecordsRow!}
    processRecordsBasicInfo.current!.validateFields().then(async (values) => {
      Object.assign(row, values)

      const user = securityStore.getLoggedUser()
      const btnText = row.status

      // 当类型是撤回时 默认办理人员为提交人(自己)
      if (btnText === FORM_REVOCATION_TEXT) {
        row.personResponsible = user._id || submitterId
        row.personResponsibleName = user.name
      }

      // 主表
      const subRow = {...submitRow}
      subRow.personResponsible = row.personResponsible
      subRow.personResponsibleName = row.personResponsibleName
      subRow.bsStatus = APPROVE_FORM_TRANS_STATE[btnText] || subRow.bsStatus
      // 主表在撤回、退回、变更时->更新局部数据
      if ([FORM_REVOCATION_TEXT, FORM_RETURN_TEXT, FORM_ALTERNATE_TEXT].includes(btnText)) {
        subRow.organizersUserId = subRow.personResponsible
        subRow.organizersUserName = subRow.personResponsibleName
      }
      // 主表在转交、提交时->更新局部数据
      if ([FORM_HANDOVER_TEXT, FORM_COMMIT_TEXT].includes(btnText)) {
        subRow.organizersUserId = subRow.personResponsible
        subRow.organizersUserName = subRow.personResponsibleName
      }
      // 主表在提交时->更新局部数据
      if (btnText === FORM_COMMIT_TEXT) {
        subRow.bsDateM = new Date().toLocaleDateString().replace(/\//g, '-')
      }
      // 主表在结办时->更新局部数据
      if (btnText === FORM_CLOSE_TEXT) {

      }

      // 办理记录表
      row.type = TableName
      row.businessId = subRow.id // 有可能会没值
      row.status = btnText
      row.userId = user._id
      row.userName = user.name
      row.personResponsible = undefined
      row.personResponsibleName = undefined
      if (btnText === FORM_COMMIT_TEXT) {
        const date = new Date()
        row.process = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
      } else {
        row.process = latestProcess
      }
      if (FORM_PERSON_RESPONSIBLE_WHITELIST.includes(btnText)) {
        row.conca = `${user.name}${btnText}给${subRow.personResponsibleName}。`
      } else {
        row.conca = `${user.name}${btnText}了。`
      }

      // 判断是否 先新增主表 后新增记录和通知表
      if (subRow.id) {

        let firstOperationSucceeded = false;
        try {
          await insertOrUpdate(subRow);
          firstOperationSucceeded = true;
        } catch (error) {
          setProcessRecordsIsOpen(false)
          setProcessRecordsRow(undefined)
          setConfirmLoading(false)
          setIsModalVisible(false);
          message.warning(error.message)
        }
        if (!firstOperationSucceeded) return
        await ProcessRecordsService.addProcessRecords(row)
        const rsp = await ProcessRecordsService.getProcessRecords({
          businessId: subRow.id,
          pageNum: 1,
          perPage: 999,
          type: TableName,
        })
        const notificationScopeUserId = rsp.items
          .map(o => o.userId)
          .concat(row.person)
          .filter((v, i, self) => self.indexOf(v) === i) // 去重
          .filter(id => id !== user._id) // 过滤出不是自己的数据
          .join(',') || ''
        const notificationScopeUserName = rsp.items
          .map(o => o.userName)
          .concat((row.personName || '').split(','))
          .filter((v, i, self) => self.indexOf(v) === i) // 去重
          .filter(name => name !== user.name) // 过滤出不是自己的数据
          .join(',') || ''
        await processStore.newAssignmentNotice({
          businessId: subRow.id,
          businessCode: subRow.bsCode,
          cooperationUnit: subRow.cooperationUnit[0] || 'N/A',
          notificationScopeUserId,
          notificationScopeUserName,
          personResponsible: subRow.personResponsible,
          personResponsibleName: subRow.personResponsibleName,
          responsibilityProject: subRow.responsibilityProject as string,
          type: "资金出纳",
          wnDesc: `您参与办理的资金出纳（${subRow.bsCode}），${row.conca} 请知悉！`,
          wnStatus: "未读"
        })

      } else {

        let subRsp: ResponseEntity<BankStatementRow>
        try {
          subRsp = await insertOrUpdate(subRow)
        } catch (error) {
          setProcessRecordsIsOpen(false)
          setProcessRecordsRow(undefined)
          setConfirmLoading(false)
          setIsModalVisible(false);
          message.warning(error.message)
        }

        if (subRsp.data) {
          // 重新赋值再提交
          row.businessId = subRsp.data.id
          await ProcessRecordsService.addProcessRecords(row)
          const rsp = await ProcessRecordsService.getProcessRecords({
            businessId: subRsp.data.id,
            pageNum: 1,
            perPage: 999,
            type: TableName,
          })
          const notificationScopeUserId = rsp.items
            .map(o => o.userId)
            .concat(row.person)
            .filter((v, i, self) => self.indexOf(v) === i) // 去重
            .filter(id => id !== user._id) // 过滤出不是自己的数据
            .join(',') || ''
          const notificationScopeUserName = rsp.items
            .map(o => o.userName)
            .concat((row.personName || '').split(','))
            .filter((v, i, self) => self.indexOf(v) === i) // 去重
            .filter(name => name !== user.name) // 过滤出不是自己的数据
            .join(',') || ''
          await processStore.newAssignmentNotice({
            businessId: subRsp.data.id,
            businessCode: subRsp.data.bsCode,
            cooperationUnit: (subRsp.data.cooperationUnit as string).split(',')[0] || 'N/A',
            notificationScopeUserId,
            notificationScopeUserName,
            personResponsible: subRsp.data.personResponsible,
            personResponsibleName: subRsp.data.personResponsibleName,
            responsibilityProject: subRsp.data.responsibilityProject as string,
            type: "资金出纳",
            wnDesc: `您参与办理的资金出纳（${subRsp.data.bsCode}），${row.conca} 请知悉！`,
            wnStatus: "未读"
          })
        } else {
          message.warning('主表保存成功，办理记录表和任务通知表提交失败')
        }

      }
      setProcessRecordsIsOpen(false)
      setProcessRecordsRow(undefined)
    }).catch(logger.warn)
  }

  const handleSubmitBatch = () => {
    const row = {...processRecordsRow!}
    processRecordsBasicInfo.current!.validateFields().then(async (values) => {

      const rows = bankStatementStore.bankStatementDatasource.data?.items.filter(o => selectedRowKeys.includes(o.id))
      for (let i = 0; i < rows.length; i++) {

        Object.assign(row, values)

        const user = securityStore.getLoggedUser()
        const btnText = row.status

        // 当类型是撤回时 默认办理人员为提交人(自己)
        if (btnText === FORM_REVOCATION_TEXT) {
          row.personResponsible = user._id || submitterId
          row.personResponsibleName = user.name
        }

        // 主表
        const subRow = {...rows[i]}
        subRow.personResponsible = row.personResponsible
        subRow.personResponsibleName = row.personResponsibleName
        subRow.bsStatus = APPROVE_FORM_TRANS_STATE[btnText] || subRow.bsStatus
        // 主表在撤回、退回、变更时->更新局部数据
        if ([FORM_REVOCATION_TEXT, FORM_RETURN_TEXT, FORM_ALTERNATE_TEXT].includes(btnText)) {
          subRow.organizersUserId = subRow.personResponsible
          subRow.organizersUserName = subRow.personResponsibleName
        }
        // 主表在转交、提交时->更新局部数据
        if ([FORM_HANDOVER_TEXT, FORM_COMMIT_TEXT].includes(btnText)) {
          subRow.organizersUserId = subRow.personResponsible
          subRow.organizersUserName = subRow.personResponsibleName
        }
        // 主表在提交时->更新局部数据
        if (btnText === FORM_COMMIT_TEXT) {
          subRow.bsDateM = new Date().toLocaleDateString().replace(/\//g, '-')
        }
        // 主表在结办时->更新局部数据
        if (btnText === FORM_CLOSE_TEXT) {

        }

        // 办理记录表
        row.type = TableName
        row.businessId = subRow.id // 有可能会没值
        row.status = btnText
        row.userId = user._id
        row.userName = user.name
        row.personResponsible = undefined
        row.personResponsibleName = undefined
        if (btnText === FORM_COMMIT_TEXT) {
          const date = new Date()
          row.process = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
        } else {
          row.process = latestProcessBatch[i]
        }
        if (FORM_PERSON_RESPONSIBLE_WHITELIST.includes(btnText)) {
          row.conca = `${user.name}${btnText}给${subRow.personResponsibleName}。`
        } else {
          row.conca = `${user.name}${btnText}了。`
        }

        let firstOperationSucceeded = false;
        try {
          await insertOrUpdate(subRow);
          firstOperationSucceeded = true;
        } catch (error) {
          setProcessRecordsIsOpen(false)
          setProcessRecordsRow(undefined)
          setConfirmLoading(false)
          setIsModalVisible(false);
          message.warning(error.message)
        }
        if (!firstOperationSucceeded) return
        await ProcessRecordsService.addProcessRecords(row)
        const rsp = await ProcessRecordsService.getProcessRecords({
          businessId: subRow.id,
          pageNum: 1,
          perPage: 999,
          type: TableName,
        })
        const notificationScopeUserId = rsp.items
          .map(o => o.userId)
          .concat(row.person)
          .filter((v, i, self) => self.indexOf(v) === i) // 去重
          .filter(id => id !== user._id) // 过滤出不是自己的数据
          .join(',') || ''
        const notificationScopeUserName = rsp.items
          .map(o => o.userName)
          .concat((row.personName || '').split(','))
          .filter((v, i, self) => self.indexOf(v) === i) // 去重
          .filter(name => name !== user.name) // 过滤出不是自己的数据
          .join(',') || ''
        await processStore.newAssignmentNotice({
          businessId: subRow.id,
          businessCode: subRow.bsCode,
          cooperationUnit: subRow.cooperationUnit[0] || 'N/A',
          notificationScopeUserId,
          notificationScopeUserName,
          personResponsible: subRow.personResponsible,
          personResponsibleName: subRow.personResponsibleName,
          responsibilityProject: subRow.responsibilityProject as string,
          type: "资金出纳",
          wnDesc: `您参与办理的资金出纳（${subRow.bsCode}），${row.conca} 请知悉！`,
          wnStatus: "未读"
        })

      }

      setProcessRecordsIsOpen(false)
      setProcessRecordsRow(undefined)
    }).catch(logger.warn)
  }

  /**
   * 操作记录按钮
   */
  const handleProcessRecordsClick = async (record: BankStatementRow) => {
    const rsp = await ProcessRecordsService.getProcessRecords({
      businessId: record.id,
      pageNum: 1,
      perPage: 999,
      type: TableName
    })
    if (!rsp) {
      message.warning('查无数据')
      return
    }
    setProcessRecordsDataSource(rsp.items)
    setProcessRecordsOpen(true)
  }

  const handleCopyClick = (record: BankStatementRow) => {
    const user = securityStore.getLoggedUser()
    const initRow = {
      ...record,
      id: '',
      bsFile: '',
      bsStatus: READY,
      personResponsible: user._id,
      personResponsibleName: user.name,
      organizersUserId: user._id,
      organizersUserName: user.name,
      bsDateM: dayjs(),
    } as BankStatementRow
    setBankStatementRow(initRow)
    setIsView(false)
    setIsBatchUpdate(false)
    setIsModalVisible(true)
  }

  return (
    <div className={"institution"}>
      <HeaderActionCom
        size={props.isModalSelect ? 6 : undefined}
        locationPathname={props.locationPathname}
        updateCount={updateCount}
        collapsed={bankStatementStore.collapsed}
        setCollapsed={handleCollapsedChange}
        searchReq={searchReq}
        handleShowColumnReset={handleShowColumnReset}
        handleTableColumnsChange={handleTableColumnsChange}
        showColumn={showColumn}
        setConfirmLoading={setConfirmLoading}
        items={[
          {
            key: 'handleAdd',
            icon: <PlusOutlined/>,
            onClick: () => handleAdd()
          },
          {
            key: 'handleDeleteBatch',
            icon: <DeleteOutlined/>,
            onClick: () => handleDeleteBatch()
          },
          {
            key: 'handleEditBatch',
            icon: <EditOutlined/>,
            onClick: () => handleEditBatch()
          },
          {
            key: 'handleBatchRelatedFundApproval',
            icon: <EditOutlined/>,
            onClick: () => handleBatchRelatedFundApproval()
          },
          {
            key: 'handlePrintTable',
            icon: <PrinterOutlined/>,
            onClick: () => handlePrintTable()
          },
          {
            key: 'handleDownloadTemp',
            icon: <CloudDownloadOutlined/>,
            onClick: () => handleDownloadTemp()
          },
          {
            key: 'handleImport',
            node: <CustomImport
              url={'/v1/bankStatement/import'}
              callback={() => refreshData(bankStatementStore.bankStatementReq)}></CustomImport>,
          },
        ]}
        tableColumns={props.isModalSelect ? columns.filter(o => o.key !== 'action') : columns}
        refreshData={props.dataSource ? undefined : (req) => refreshData({...searchReq, ...req})}></HeaderActionCom>

      <Table
        className={'margin-top-xss'}
        rowKey={"id"}
        dataSource={props.dataSource || dataSource}
        columns={renderColumns}
        size={"middle"}
        loading={props.dataSource ? false : bankStatementStore.bankStatementDatasource.loading || confirmLoading}
        bordered
        scroll={{y: scrollHeight, scrollToFirstRowOnChange: true}}
        rowSelection={{
          selectedRowKeys,
          onChange: (selectedRowKeys) => {
            setSelectedRowKeys(selectedRowKeys)
          },
          columnWidth: 50,
        }}
        pagination={!props.dataSource && {
          total: total,
          position: ['bottomCenter'],
          pageSize: searchReq.perPage,
          current: searchReq.pageNum,
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: total => `共${total}条`,
          onChange: handlePageChange
        }}
        summary={() => (
          <Table.Summary fixed={"bottom"}>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} key={0} colSpan={1} className='text-align-center'>
                { selectedRowKeys.length }
              </Table.Summary.Cell>
              {
                (showColumn as string[]).map((key, index) =>
                  key.includes('-summation') ? <Table.Summary.Cell
                    index={index} key={index + 1} colSpan={1} className='text-align-right'>
                    合计：<TableMoneyText value={summaryTotalObj[key.slice(0, key.indexOf('-'))]}></TableMoneyText> 元
                  </Table.Summary.Cell> : <Table.Summary.Cell key={index + 1} index={index} colSpan={1}/>)
              }
            </Table.Summary.Row>
          </Table.Summary>
        )}/>

      { /*资金审批编号点击详情*/ }
      <FundApprovalModal
        dataSource={fundApprovalModalDataSource}
        open={FundApprovalModalOpen}
        setOpen={setFundApprovalModalOpen}
      ></FundApprovalModal>

      { /*批量审批*/ }
      <FundApprovalTableModal
        loading={FundApprovalTableModalLoading}
        open={FundApprovalTableModalOpen}
        handleOk={handleFundApprovalTableModalOk}
        onChange={v => setFundApprovalTableModalOpen(v)}
      ></FundApprovalTableModal>

      { /*操作记录表*/ }
      <CustomDraggableModal
        title={<div className="text-align-center">办理记录</div>}
        open={processRecordsOpen}
        width={LARGE_MODAL_WIDTH_OTHER}
        onCancel={() => setProcessRecordsOpen(false)}
        destroyOnClose
        footer={null}>
        <ProcessRecords
          isHiddenOptions
          dataSource={processRecordsDataSource}
          isModalSelect
          locationPathname={'/processRecords'}/>
      </CustomDraggableModal>

      { /*操作记录表单*/ }
      <CustomDraggableModal
        title={<div className="text-align-center">办理记录</div>}
        open={processRecordsIsOpen}
        width={LARGE_MODAL_WIDTH}
        destroyOnClose
        onCancel={() => {
          setProcessRecordsIsOpen(false)
          setConfirmLoading(false)
        }}
        onOk={isBatchUpdate ? handleSubmitBatch : handleSubmit}>
        <ProcessRecordsBasicInfo
          tableName={TableName}
          businessId={bankStatementRow?.id}
          submitterId={submitterId}
          userId={bankStatementRow?.personResponsible}
          status={bankStatementRow?.bsStatus}
          row={processRecordsRow}
          ref={processRecordsBasicInfo}/>
      </CustomDraggableModal>

      <CustomForm
        title={'资金出纳'}
        handleCancel={handleCancel}
        handleOk={handleOk}
        isModalVisible={isModalVisible}
        submitterId={submitterId}
        confirmLoading={confirmLoading}
        userId={bankStatementRow?.personResponsible}
        status={bankStatementRow?.bsStatus}
        isView={isView}
        extraDisabled={!bankStatementRow?.id}
        extraOnClick={() => handlePrint(bankStatementRow)}
        item={[
          {
            key: 'handleCopyClick',
            icon: <FieldTimeOutlined/>,
            disabled: !bankStatementRow?.id,
            onClick: () => handleCopyClick(bankStatementRow)
          },
          {
            key: 'handleFundApprovalClick',
            icon: <RetweetOutlined/>,
            hidden: bankStatementRow?.bsStatus !== CLOSE,
            onClick: () => handleFundApprovalClick(bankStatementRow)
          },
          {
            key: 'handleProcessRecordsClick',
            icon: <FieldTimeOutlined/>,
            disabled: !bankStatementRow?.id,
            onClick: () => handleProcessRecordsClick(bankStatementRow)
          },
        ]}>
        <BasicInfo row={{...bankStatementRow!}} ref={basicInfo} isBatchUpdate={isBatchUpdate} isView={isView}/>
      </CustomForm>

      { /*打印*/ }
      <WindowPrint
        isSummary
        isHiddenTime
        title={printTitle}
        contentList={contentList}
        isModalOpen={isModalOpen}
        isPrintTable={isPrintTable}
        tableColumns={columns}
        dataSource={printDataSource}
        narrowShowColumn={narrowShowColumn}
        handleCancel={() => {
          setIsModalOpen(false)
          setSelectedRowKeys([])
        }}/>

    </div>
  )
}
))
