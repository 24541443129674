import React, {useEffect, useState} from "react";
import {Col, DatePicker, Form, Input, InputNumber, Row} from "antd";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import {InvoiceRegisterRow} from "@services/dto/invoiceRegister";
import dayjs from "dayjs";
import {SelectDict} from "@components/SelectDict";
import {SelectPersonCharge} from "@components/SelectPersonCharge";
import {SelectProject} from "@components/SelectProject";
import {SelectCooperativeUnit} from "@components/SelectCooperativeUnit";
import {allStores} from "@/stores";
import {ProjectRow} from "@services/dto/project";
import {SelectAccountSales} from "@components/SelectAccountSales";
import {SelectFiling} from "@components/SelectFiling";
import {SelectTaxAccounting} from "@components/SelectTaxAccounting";
import {ProjectCollaborationRow} from "@services/dto/projectCollaboration";
import {SelectApplicationFormType} from "@components/SelectApplicationFormType";
import {safeCompute} from "@common/utils";
import {CostReimbursementRow} from "@services/dto/costReimbursement";
import {ContractSettlementRow} from "@services/dto/contractSettlement";
import {SelectProcessStatus} from "@components/SelectProcessStatus";
import {ProjectService} from "@services/ProjectService";

export interface PropsType {
  row: InvoiceRegisterRow
  isView?: boolean
  isBatchUpdate?: boolean
}

export const BasicInfo = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, ref) => {
  const [form] = Form.useForm()
  const {filingStore} = allStores
  const [personResponsible, setPersonResponsible] = useState<string>()
  const [responsibilityProject, setResponsibilityProject] = useState<string>()
  const [cooperationUnit, setCooperationUnit] = useState<string | string[]>()
  const [irContractNoId, setIrContractNoId] = useState<string[]>()

  useEffect(() => {
    form.resetFields()
    const invoiceRegisterRow = {...props.row}
    filingStore.setFilingFaCode(invoiceRegisterRow.irInvoiceCode2)
    if (invoiceRegisterRow.taxAccountings && invoiceRegisterRow.taxAccountings.length > 0) {
      if (invoiceRegisterRow.outputAccountingCode) {
        invoiceRegisterRow.outputAccountingId = invoiceRegisterRow.taxAccountings.map(item => item.id)
      } else {
        invoiceRegisterRow.inputAccountingId = invoiceRegisterRow.taxAccountings.map(item => item.id)
      }
    }
    if (invoiceRegisterRow.irContractNoObj?.length) {
      invoiceRegisterRow.irApplicationForm = invoiceRegisterRow.irContractNoObj[0].type;
      invoiceRegisterRow.irContractNoId = invoiceRegisterRow.irContractNoObj.map(o => o.contractSettlementId);
      setIrContractNoId(invoiceRegisterRow.irContractNoId);
    } else {
      invoiceRegisterRow.irApplicationForm = undefined;
      invoiceRegisterRow.irContractNoId = undefined;
      setIrContractNoId(undefined);
    }
    if (typeof invoiceRegisterRow.irDate === 'string' && invoiceRegisterRow.irDate !== '') {
      invoiceRegisterRow.irDate = dayjs(invoiceRegisterRow.irDate)
    }
    if (typeof invoiceRegisterRow.cooperationUnit === 'string') {
      invoiceRegisterRow.cooperationUnit = invoiceRegisterRow.cooperationUnit !== '' ? invoiceRegisterRow.cooperationUnit.split(',') : []
    }
    setResponsibilityProject(invoiceRegisterRow.responsibilityProject)
    setCooperationUnit(invoiceRegisterRow.cooperationUnit)
    setPersonResponsible(invoiceRegisterRow.personResponsibleName)
    form.setFieldsValue(invoiceRegisterRow);
  }, [props.row.id])

  /**
   * @desc 责任部门修改 code拿到并赋值
   * @param value
   * @param options
   */
  const handleProjectChange = (value: any, options: ProjectRow[]) => {
    setResponsibilityProject(value)
    const row = options.find(o => value === o.name)
    form.setFieldsValue({code: row?.code})
  }

  /**
   * irAmount
   * irTaxRate
   * 上面两个改变下面两个自动计算
   * const irTaxAmount = irAmount * irTaxRate
   * const irTaxRateSum = irTaxAmount + irAmount
   */
  const handleIrAmountIrTaxRateChange = () => {
    const {irAmount, irTaxRate} = form.getFieldsValue(['irAmount', 'irTaxRate'])
    const irTaxAmount = safeCompute(irAmount) * safeCompute(irTaxRate)
    const irTaxRateSum = irTaxAmount + safeCompute(irAmount)
    form.setFieldsValue({irTaxAmount, irTaxRateSum})
  }

  const handleSellerChange = (value: string, options: ProjectCollaborationRow[]) => {
    const option = options.find(item => item?.cuName === value)
    if (option) {
      if (option.cooperativeUnitCategory === '外部协作单位') {
        form.setFieldsValue({outputAccountingId: 'N/A'})
      }
    }
  }

  const handlePurchaserChange = (value: string, options: ProjectCollaborationRow[]) => {
    const option = options.find(item => item?.cuName === value)
    if (option) {
      if (option.cooperativeUnitCategory === '外部协作单位') {
        form.setFieldsValue({inputAccountingId: 'N/A'})
      }
    }
  }

  const handleIrApplicationFormTypeChange = () => {
    setIrContractNoId(undefined)
    form.resetFields(['irContractNoId'])
  }

  /**
   * 修改合同结算 带出合同审批
   * @param v
   * @param options
   */
  const handleTableValueChange = async (v: string[], options: ContractSettlementRow[] | CostReimbursementRow[]) => {
    if (options && options.length > 0) {
      const cooperationUnit = typeof options[0].cooperationUnit === 'string' ? options[0].cooperationUnit?.split(",") : options[0].cooperationUnit
      setResponsibilityProject(options[0].personResponsible)
      setCooperationUnit(cooperationUnit)
      let responsibilityProject: ProjectRow
      if (options[0].responsibilityProject) {
        [responsibilityProject] = await ProjectService.getBatchByName([options[0].responsibilityProject])
      }
      form.setFieldsValue({
        responsibilityProject: options[0].responsibilityProject,
        cooperationUnit: cooperationUnit,
        code: responsibilityProject?.code,
      });
    } else {
      setResponsibilityProject(undefined)
      setCooperationUnit([])
      form.setFieldsValue({
        responsibilityProject: undefined,
        cooperationUnit: undefined,
        code: undefined,
      });
    }
    setIrContractNoId(v)
    form.setFieldsValue({irContractNoId: v})
    if (v.length) {
      const irApplicationForm = form.getFieldValue('irApplicationForm')
      switch (irApplicationForm) {
      case '合同结算':
      case '费用报销':
        break
      default:
        break
      }
    }
  }

  return (
    <Form
      name="basic"
      ref={ref}
      form={form}
      labelCol={{span: 6}}
      wrapperCol={{span: 16}}
      initialValues={{cooperationUnit:['N/A']}}
      disabled={props.isView}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Form.Item hidden name="id">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="code">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="personResponsibleName">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="irContractNoId">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="organizersUserName">
          <Input/>
        </Form.Item>
        <Col span={24}>
          <Form.Item
            label="制单编号"
            name="irInvoiceCode2"
            rules={[{required: false, message: '请输入制单编号!'}]}>
            <Input placeholder="请输入制单编号" disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="开票日期"
            name="irDate"
            rules={[{required: false, message: '请输入开票日期!'}]}>
            <DatePicker placeholder={"请输入开票日期"} className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="发票代码-号码"
            name="irInvoiceCode"
            rules={[{required: false, message: '请输入发票代码-号码!'}]}>
            <Input placeholder="请输入发票代码-号码"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="发票类型"
            name="irType"
            rules={[{required: false, message: '请输入发票类型!'}]}>
            <SelectDict code={'Invoice_registration_type'} placeholder={'请输入发票类型'}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="开票内容"
            name="irContent"
            rules={[{required: false, message: '请输入开票内容!'}]}>
            <Input.TextArea placeholder="请输入开票内容" autoSize={{minRows: 3, maxRows: 9}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="销货清单"
            name="salesListId"
            rules={[{required: false, message: '请选择销货清单!'}]}>
            <SelectAccountSales
              allowClear
              isShowAction
              modalTitle={"销货清单"}
              personResponsible={personResponsible}
              responsibilityProject={responsibilityProject}
              cooperationUnit={cooperationUnit}
              placeholder={"请选择销货清单"}
              className={'width-100-percentage'}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="金额"
            name="irAmount"
            rules={[{required: false, message: '请输入金额!'}]}>
            <InputNumber
              addonAfter={'元'}
              min={0} onChange={handleIrAmountIrTaxRateChange} precision={2} placeholder="请输入金额"
              className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="税率"
            name="irTaxRate"
            rules={[{required: false, message: '请输入税率!'}]}>
            <InputNumber
              min={0} onChange={handleIrAmountIrTaxRateChange} precision={2} placeholder="请输入税率"
              className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="税额"
            name="irTaxAmount"
            rules={[{required: false, message: '请输入税额!'}]}>
            <InputNumber addonAfter={'元'} min={0} precision={2} placeholder="请输入税额" className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        < Col span={24}>
          <Form.Item
            label="价税合计"
            name="irTaxRateSum"
            rules={[{required: false, message: '请输入价税合计!'}]}>
            <InputNumber
              addonAfter={'元'}
              disabled min={0} precision={2} placeholder="请输入价税合计"
              className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="销售方"
            name="irSeller"
            rules={[{required: false, message: '请选择销售方!'}]}>
            <SelectCooperativeUnit
              allowClear
              isShowAction
              modalTitle={"销售方"}
              // cooperativeUnitCategory={"内部协作单位"}
              personResponsible={personResponsible}
              responsibilityProject={responsibilityProject}
              placeholder="请选择销售方"
              onChange={handleSellerChange}
              fieldNames={{value: 'cuName', label: 'cuName'}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="购买方"
            name="irPurchaser"
            rules={[{required: false, message: '请选择购买方!'}]}>
            <SelectCooperativeUnit
              allowClear
              isShowAction
              modalTitle={"购买方"}
              // cooperativeUnitCategory={"内部协作单位"}
              personResponsible={personResponsible}
              responsibilityProject={responsibilityProject}
              placeholder="请选择购买方"
              onChange={handlePurchaserChange}
              fieldNames={{value: 'cuName', label: 'cuName'}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="关联申请表单"
            name="irApplicationForm"
            rules={[{required: !props.isBatchUpdate, message: '请选择关联申请表单!'}]}>
            <SelectApplicationFormType
              className={'width-100-percentage'}
              allowClear
              isOption
              showKey={['费用支出', '合同结算']}
              isOnlyUnBindIr={['费用支出', '合同结算']}
              mode={'multiple'}
              placeholder={'请选择关联申请表单'}
              tableValue={irContractNoId}
              onChange={handleIrApplicationFormTypeChange}
              handleTableValueChange={handleTableValueChange}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="关联销项核算"
            name="outputAccountingId"
            rules={[{required: false, message: '请选择关联销项核算!'}]}>
            <SelectTaxAccounting mode={"multiple"} allowClear placeholder={"请选择关联销项核算"} disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="关联进项核算"
            name="inputAccountingId"
            rules={[{required: false, message: '请选择关联进项核算!'}]}>
            <SelectTaxAccounting mode={"multiple"} allowClear placeholder={"请选择关联进项核算"} disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="责任部门"
            name="responsibilityProject"
            rules={[{required: false, message: '请选择责任部门!'}]}>
            <SelectProject
              placeholder={"请选择责任部门"} disabled allowClear onChange={handleProjectChange}
              fieldNames={{value: 'name', label: 'name'}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="协作单位"
            name="cooperationUnit"
            rules={[{required: false, message: '请输入协作单位!'}]}>
            <SelectCooperativeUnit
              mode={'multiple'}
              allowClear
              disabled
              fieldNames={{value: 'cuName', label: 'cuName'}}/>
          </Form.Item>
        </Col>
        {
          props.row.id && <Col span={24}>
            <Form.Item
              label="存档批号"
              name="irFile"
              rules={[{required: false, message: '请选择存档批号!'}]}>
              <SelectFiling
                isHidePlus
                isHideClose
                responsibilityProject={responsibilityProject}
                cooperationUnit={cooperationUnit}
                placeholder={"请选择存档批号"}
                inputDisabled
                fieldNames={{value: "id", label: "faBatchNumber"}}
                allowClear/>
            </Form.Item>
          </Col>
        }
        <Col span={24}>
          <Form.Item
            label="办理人员"
            name="personResponsible"
            rules={[{required: false, message: '请选择办理人员!'}]}>
            <SelectPersonCharge
              disabled
              className={"width-100-percentage"}
              placeholder={"请选择办理人员"}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="主办人员"
            name="organizersUserId"
            rules={[{required: false, message: '请选择主办人员!'}]}>
            <SelectPersonCharge
              className={"width-100-percentage"}
              placeholder={"请选择主办人员"}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="办理进度"
            name="irStatus"
            rules={[{required: false, message: '请输入办理进度!'}]}>
            <SelectProcessStatus
              allowClear
              placeholder={"请选择办理进度"}
              disabled/>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}))
